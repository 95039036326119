import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorBoundaryPresentation from './ErrorBoundaryPresentation';
import { getWithExpiry, setWithExpiry } from "./Storage";


export default class ErrorBoundary extends Component {
    state = {
        error: null
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });

        //Send error to Sentry
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });

        //Write error to console
        console.error(error);
        console.error(errorInfo);

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error&& error.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 10000);
                window.location.reload();
            }
        }
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
                <ErrorBoundaryPresentation />
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}