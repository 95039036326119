import React, { useState, useContext } from 'react';
import CurrentRecipients from '../SendMessage/CurrentRecipients';
import { playAudio } from '../../Utils/AudioUtil'
import { SystemSettingContext } from '../../SystemSettingContext';
import Util from '../../Utils/Util';

export default ({ recipients, onRemoveRecipient, onSend, onCancel, onClearRecipients }) => {

    const [signText, setSignText] = useState('');
    const [audioText1, setAudioText1] = useState('');
    const [audioText2, setAudioText2] = useState('');
    const [loading, setLoading] = useState(false);
    const systemSettings = useContext(SystemSettingContext);

    if (Util.isNullOrUndefined(systemSettings)) return null;

    return (
        <div className="right-column">
            <div className="right-column-top">
                <CurrentRecipients recipients={recipients} onRemoveRecipient={onRemoveRecipient} onClearRecipients={onClearRecipients} />
                <div className="announcement-holder">
                    <h2>Internal Sign Announcement:</h2>
                    <textarea value={signText} onChange={(e) => setSignText(e.target.value)}></textarea>
                    {systemSettings.announcementLanguage1 && <React.Fragment>
                        <h2>
                            Audio Announcement{systemSettings.announcementLanguage2 && <span> - {systemSettings.announcementLanguage1.subStr(0, 2)}</span>}:
                            <button className="k-button" onClick={() => { setLoading(true); playAudio([{ text: audioText1, voice: systemSettings.voice1 }], systemSettings.speechSynthesizerRate, null, () => setLoading(false)) }} disabled={loading}><i className="k-icon k-i-arrow-e" />Test Audio</button>
                        </h2>
                        <textarea value={audioText1} onChange={(e) => setAudioText1(e.target.value)}></textarea>
                    </React.Fragment>}
                    {systemSettings.announcementLanguage2 && <React.Fragment>
                        <h2>
                            Audio Announcement{systemSettings.announcementLanguage1 && <span> - {systemSettings.announcementLanguage2.subStr(0, 2)}</span>}:
                            <button className="k-button" onClick={() => { setLoading(true); playAudio([{text: audioText2, voice: systemSettings.voice2}], systemSettings.speechSynthesizerRate, null, () => setLoading(false)) }} disabled={loading}><i className="k-icon k-i-arrow-e" />Test Audio</button>
                        </h2>
                        <textarea value={audioText2} onChange={(e) => setAudioText2(e.target.value)}></textarea>
                    </React.Fragment>}
                </div>
            </div>
            <div className="right-column-bottom">
                <button className="cancel-button" onClick={onCancel}>Cancel</button>
                <button className="send-button" onClick={() => onSend(recipients, signText, audioText1, audioText2)}>Send</button>
            </div>
        </div>
    )
}