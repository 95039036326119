import axios from 'axios';
import React, { Component, useState, useEffect, Fragment, useContext, useRef } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import SMIGrid from '../SMIGrid/SMIGrid';
import * as GridCustomTemplates from '../SMIGrid/GridCustomTemplates';
import * as GridCustomFilters from '../SMIGrid/GridCustomFilters';
import { GridColumn as Column, getSelectedState } from '@progress/kendo-react-grid';
import SMIActionColumn from '../SMIGrid/SMIActionColumn';
import { process } from '@progress/kendo-data-query';
import { getter } from "@progress/kendo-react-common";
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { SystemSettingContext } from '../../SystemSettingContext';
import { MapComponentUnconnected } from '../../pages/Shared/Map/MapComponent';
import { Dialog } from '@progress/kendo-react-dialogs';
import L from 'leaflet';
import { Marker } from 'react-leaflet';

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "isRead",
            operator: "eq",
            value: 0,
        },
    ],
};

export default () => {
    let systemSettings = useContext(SystemSettingContext);
    const gridRef = useRef();
    const excelExportRef = useRef();

    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [filters, setFilters] = useState(initialFilter);
    const [lastFilters, setLastFilters] = useState(initialFilter);
    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        if (gridRef.current)
            gridRef.current.setGridFilter(filters);
    }, [filters]);

    const onSingleCheckboxChange = (e, alertId) => {
        if (e.target.checked && !selectedAlerts.includes(alertId)) {
            let _selectedAlerts = selectedAlerts.slice(0);
            _selectedAlerts.push(alertId);
            setSelectedAlerts(_selectedAlerts);
        }
        else if (!e.target.checked && selectedAlerts.includes(alertId)) {
            let _selectedAlerts = selectedAlerts.slice(0);
            let index = _selectedAlerts.indexOf(alertId);
            _selectedAlerts.splice(index, 1);
            setSelectedAlerts(_selectedAlerts);
        }
    }

    const onAllCheckboxChange = e => {
        let _selectedAlerts = selectedAlerts.slice(0);
        let processedData = getProcessedData(gridRef);

        if (processedData && processedData.data && processedData.data.length > 0) {
            if (e.target.checked) {
                processedData.data.forEach(d => {
                    if (!_selectedAlerts.includes(d.ID) && !d.isRead)
                        _selectedAlerts.push(d.ID);
                });
                setSelectedAlerts(_selectedAlerts);
            }
            else {
                processedData.data.forEach(d => {
                    let index = _selectedAlerts.indexOf(d.ID);
                    _selectedAlerts.splice(index, 1);
                });
                setSelectedAlerts(_selectedAlerts);
            }
        }
    }

    const clearAlerts = e => {
        if (selectedAlerts && selectedAlerts.length > 0) {
            markAlertsAsRead(gridRef, selectedAlerts);           
            setSelectedAlerts([]);
        }
    }

    const showPosition = dataItem => {
        setShowMap(true);
        setSelectedAlert(dataItem);
    }

    const onCloseVehiclePosition = e => {
        setShowMap(false);
    }

    const checkBoxCell = event => {
        return <td> <input type="checkbox" checked={selectedAlerts.includes(event.dataItem.ID)} disabled={event.dataItem.isRead ? true : false} onChange={e => onSingleCheckboxChange(e, event.dataItem.ID)}></input> </td>;
    }

    const headerCheckboxCell = dataItem => {
        let isChecked = false;
        let isDisabled = false;
        let processedData = getProcessedData(gridRef);

        if (selectedAlerts && processedData && processedData.data && processedData.data.length > 0) {
            let filteredAlertIds = processedData.data.filter(d => !d.isRead).map(d => d.ID);
            if (filteredAlertIds && filteredAlertIds.length > 0 && selectedAlerts && selectedAlerts.length > 0) isChecked = filteredAlertIds.every(item => selectedAlerts.includes(item));
            if (!filteredAlertIds || filteredAlertIds.length < 1) isDisabled = true;
        }
        else isDisabled = true;

        return <label style={{ padding: '0px' }}> <input type="checkbox" disabled={isDisabled} checked={isChecked} onChange={onAllCheckboxChange}></input>All</label>;
    }

    const positionCell = event => {
        let _className = "k-grid-Position";
        if (!event.dataItem.Lat || !event.dataItem.Long || (event.dataItem.Lat == 0 && event.dataItem.Long == 0))
            _className += " disabled";
        return <td> <button className={_className} onClick={e => showPosition(event.dataItem)}> </button> </td>
    }

    const alertMessageCell = event => {
        return <td className={event.dataItem.isRead ? "read" : ""}>{event.dataItem.Alert} </td>
    }

    const onShowReadMessage = e => {
        if (e.target.checked) setFilters(null);
        else setFilters(initialFilter);
    }

    const onDataLoaded = data => {
        if (gridRef.current) gridRef.current.setGridFilter(filters);
    }

    const onExcelExport = e => {
        if (gridRef.current && excelExportRef.current) {
            try {                
                let result = getProcessedData(gridRef);
                let _excelData = gridRef.current.deepCopy(result.data);

                _excelData.forEach((d) => {
                    for (let field in gridRef.current.columnsByField) {
                        const template = gridRef.current.columnsByField[field].props.customTemplate;
                        if (template && typeof template === 'function') d[field] = template(d, field);
                    }
                });

                const excelColumns = gridRef.current.state.columns.filter(c => c.field.indexOf('_') !== 0 && c.show);
                excelExportRef.current.save(_excelData, excelColumns);
            } catch (e) {
                console.log(e);
            }
        }
    }

    const filterChange = e => {        
        if (e.filter !== lastFilters) {
            setLastFilters(e.filter);
            setSelectedAlerts([]);
        }
    }

    const toolbarButtons = (<Fragment>
        <label className="show-read"> <input type="checkbox" data-bind="checked: showReadMessage" onChange={onShowReadMessage} />Show Read Message</label>
        <button onClick={clearAlerts} className={"icon-trashbin btn-clear WebComponentsIcons" + (selectedAlerts && selectedAlerts.length > 0 ? "" : " disabled")}><span className="k-icon k-i-check-circle"></span>{`Mark Selected${selectedAlerts && selectedAlerts.length > 0 ? ' (' + selectedAlerts.length+')' : ''} as Read`}</button>
        <Button onClick={onExcelExport} className="k-export"><span className="k-icon k-i-custom"></span> Export to Excel</Button>
    </Fragment>
    );

    if (!systemSettings) return null;

    return (
        <div id="alertInbox">
            <SMIGrid crudUrl='/api/AlertsInbox'  ref={gridRef} onDataLoaded={onDataLoaded} editable={false} toolbarButtons={toolbarButtons} showColumnMenu={true}
                itemsPerPage={50} onDataStateChange ={filterChange} pageable searchable groupable>
                <Column cell={checkBoxCell} field="_checkbox" filterable={false} width={65} headerCell={headerCheckboxCell} hideColumnMenu={true} />
                <Column title="Alert Name" field="Subject" width={125} />
                <Column title="Alert Message" field="Alert" cell={alertMessageCell} />
                <Column title="Time" field="Time" width={150} filterCell={GridCustomFilters.Date()} customTemplate={GridCustomTemplates.DateTime(systemSettings.shortDateFormat, systemSettings.shortTimeFormat)} />
                <Column title="Vehicle" field="Vehicle" width={120} />
                <Column title="Driver" field="Driver" width={125} />
                <Column title="isRead" field="isRead" width={80} hidden customTemplate={GridCustomTemplates.YesNoBinary()} filterCell={GridCustomFilters.YesNoBinary()} />
                <Column title="Position" field="_Position" filterable={false} cell={positionCell} width={90} onClick={dataItem => showPosition(dataItem)} />                
            </SMIGrid>
            {showMap && <MapView onClose={onCloseVehiclePosition} selectedAlert={selectedAlert} />}
            <ExcelExport ref={excelExportRef} />
        </div>
    );
}

export const MapView = (props) => {
    const [vehicleMarker, setVehicleMarker] = useState(null);

    useEffect(() => {
        if (props.selectedAlert && props.selectedAlert.Lat && props.selectedAlert.Long) {
            var location = [props.selectedAlert.Lat, props.selectedAlert.Long];
            let isBunchingPin = props.selectedAlert.Subject.indexOf('Bunching') > -1;
            var pinClass = isBunchingPin ? 'inboxMapPushpin bunch' : 'inboxMapPushpin';
            var mid = 12;

            var _icon = L.divIcon({
                html: `<div class='${pinClass}'></div>`,
                iconSize: L.point(24, 24),
                iconAnchor: L.point(mid, mid),
                className: 'my-div-icon'
            });

            let pin = <Marker position={location} icon={_icon} />;
            setVehicleMarker(pin);
        }

    }, [props]);


    return (
        <div id="alertMap">
            <Dialog title="Alerts Map View" onClose={props.onClose} height={520} width={800}>
                <MapComponentUnconnected vehicleMarker={vehicleMarker} centerPosition={props.selectedAlert ? [props.selectedAlert.Lat, props.selectedAlert.Long] : null} />
            </Dialog>
        </div>
    );
}


function getProcessedData(gridRef) {
    if (!gridRef.current || !gridRef.current.state.data || gridRef.current.state.data.length < 1) return { data: [], total: 0 };

    let dataState = Object.assign({}, gridRef.current.state.dataState);
    dataState.skip = 0;
    dataState.take = null;

    const data = gridRef.current.state.data;
    const processedData = process(data, dataState);
    return processedData;
}

function markAlertsAsRead(gridRef, alertIds) {
    if (gridRef.current)
        gridRef.current.loadingOn();
    axios.delete(`/api/AlertsInbox`, { data: alertIds }).then(response => {
        if (gridRef.current) {
            gridRef.current.flashSavedMessage();
            gridRef.current.loadData();
        }   

    }).catch(err => {
        console.log('marked as read');
        if (gridRef.current)
            gridRef.current.flashErrorMessage();
    }).finally(() => {
        if (gridRef.current)
            gridRef.current.loadingOff();
    });
}
