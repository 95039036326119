import React from 'react';

import './CompatibilityTagline.css';

export default (props) => {
    const queryStr = `smiuser=${props.username}&keyid=${props.guid}&origin=${window.location.origin}`;
    return (
        <div className="login smi-login">
            <h1>SMI LOGIN</h1>
            <div className="login-container">
                <div className="login-form">
                    <iframe style={{width: '400px', height: '160px'}} className="smi-login-frame" title="SMI Login" src={"https://api.mapstrat.com/Authentication/Login/?" + queryStr}></iframe>
                    <button type="button" className="green" onClick={props.onCancel}>Cancel</button>
                </div>
            </div>
            
        </div>
    );
};

