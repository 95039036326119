import React, { Component, Fragment } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker, DateTimePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { NumericTextBox } from '@progress/kendo-react-inputs';


export function Dropdown(data, dataValueField, dataTextField, operator) {
    if (!operator) operator = 'eq';
    return (props) => {
        let dataValue = props.value;

        //If dataValueField was specified, then the dropdown data is an array of objects and we need to pass the matching object to kendo in order for it to be selected
        if (dataValueField && typeof dataValue !== 'undefined' && dataValue !== null) {
            const dataSearchResult = data.filter(i => i[dataValueField] === dataValue);
            if (dataSearchResult.length > 0) dataValue = dataSearchResult[0];
        }

        return (
            <div className="k-filtercell">
                <DropDownList
                    data={data}
                    onChange={(event) => {
                        let val = event.target.value;
                        if (typeof val === 'object') val = val[dataValueField];
                        if (!isNaN(val)) val = parseInt(val);
                        props.onChange({
                            value: val || val === 0 ? val : '',
                            operator: val || val === 0 ? operator : '',
                            syntheticEvent: event
                        });
                    }}
                    value={dataValue}
                    textField={dataTextField}
                    dataItemKey={dataValueField}
                />
                <button
                    className="k-button k-button-icon k-clear-button-visible"
                    title="Clear"
                    disabled={!props.value && props.value !== 0}
                    onClick={(event) => {
                        event.preventDefault();
                        props.onChange({
                            value: '',
                            operator: '',
                            syntheticEvent: event
                        });
                    }}
                >
                    <span className="k-icon k-i-filter-clear" />
                </button>
            </div>
        );
    }
}


export function YesNoBinary() {
    return Dropdown([{ val: 1, text: 'Yes' }, { val: 0, text: 'No' }], 'val', 'text', binaryFilter);
}

export function binaryFilter(current, value) {    
    if (!value && value !== 0) return true;
    if ((!value || value === '0') && (!current || current === '0')) return true;
    if ((value && value !== '0') && (current && current !== '0')) return true;
    return false;
}

//Create dropdown where use can select from the selectable options, then filter to show all items containing that value
export function CSV(data, dataValueField, dataTextField) {
    return SelectList(data, dataValueField, dataTextField, (cellValue, filterValue) => {
        for (let i = 0; i < filterValue.length; i++) {            
            const escapedValue = filterValue[i].toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regex = new RegExp(`(,|^)${escapedValue}(,|$)`, 'i');
            if (regex.test(cellValue)) return true;
        }
        return false;
    });
}

export function SelectList(data, dataValueField, dataTextField, operator) {
    if (!operator) operator = (cellValue, filterValue) => {        
        return filterValue.findIndex(x => x == cellValue) !== -1;
    };

    return class extends React.Component {

        state = {
            shown: false,
            selectedOptions: []
        }

        togglePopup = () => {
            this.setState({ shown: !this.state.shown });
        }

        checkboxChange = (event) => {
            const isChecked = event.target.checked;
            const value = isNaN(event.target.value) ? event.target.value : parseInt(event.target.value);
            const selectedIndex = this.state.selectedOptions.indexOf(value);
            const newSelectedOptions = this.state.selectedOptions.slice(0);
            if (isChecked && selectedIndex === -1) newSelectedOptions.push(value); // If a checkbox was checked, add its value to the array of selected items
            else if (!isChecked && selectedIndex !== -1) newSelectedOptions.splice(selectedIndex, 1); // If a checkbox was unchecked, remove it
            this.setState({ selectedOptions: newSelectedOptions });
            this.propagateFilter();
        }

        clearFilter = () => {
            this.setState({ selectedOptions: [] });
            this.propagateFilter();
        }

        propagateFilter = () => {
            setTimeout(() => {
                if (this.state.selectedOptions.length > 0) {
                    this.props.onChange({
                        value: this.state.selectedOptions,
                        operator: operator
                    });
                }
                else {
                    //Clear filter
                    this.props.onChange({
                        value: '',
                        operator: ''
                    });
                }
            });
        }

        render() {
            if (!data || data.length === 0) return null;

            const selectedOptionsText = [];
            data.forEach(d => {
                const value = dataValueField ? d[dataValueField] : d;
                const text = dataTextField ? d[dataTextField] : d;
                if (this.state.selectedOptions.findIndex(x => x == value) !== -1) selectedOptionsText.push(text);
            });

            const checkboxList = data.map((d, i) => {
                const value = dataValueField ? d[dataValueField] : d;
                const text = dataTextField ? d[dataTextField] : d;
                return <p key={i} style={{ margin: 0 }}><label><input type="checkbox" onChange={this.checkboxChange} value={value} checked={this.state.selectedOptions.findIndex(x=> x == value) !== -1} /> {text}</label></p>;
            });

            return (
                <div className="k-filtercell">
                    <button className="k-button k-widget" onClick={this.togglePopup} ref={(button) => { this.anchor = button; }} style={{ textAlign: 'left', justifyContent: 'left', overflow: 'hidden' }} >
                        <span style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedOptionsText.join(', ')}</span>
                    </button>
                    <Popup anchor={this.anchor} show={this.state.shown} popupClass={'popup-content'}>
                        <div style={{ padding: '10px' }}>
                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {checkboxList}
                            </div>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}>
                                <button className="k-button" disabled={this.state.selectedOptions.length === 0} onClick={this.clearFilter}>Clear</button>
                                <button className="k-button" onClick={this.togglePopup}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <button className="k-button k-button-icon k-clear-button-visible" title="Clear" disabled={this.state.selectedOptions.length === 0} onClick={this.clearFilter}>
                        <span className="k-icon k-i-filter-clear" />
                    </button>
                </div>
            );
        }
    }
}

export function Date(dateFormat) {
    return class DateFilter extends Component {

        onChange = (event) => {
            this.props.onChange({
                value: event.target.value,
                operator: dateIsInRange,
                syntheticEvent: event
            });
        }

        render() {
            const value = this.props.value || null;

            return (
                <div className="k-filtercell">
                    <DatePicker
                        value={value}
                        defaultValue={value}
                        format={dateFormat ? dateFormat : null}
                        formatPlaceholder={null}
                        onChange={this.onChange} />

                    <button
                        className="k-button k-button-icon k-clear-button-visible"
                        title="Clear"
                        disabled={!value}
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.onChange({
                                value: null,
                                operator: '',
                                syntheticEvent: event
                            });
                        }}>

                        <span className="k-icon k-i-filter-clear" />
                    </button>

                </div>
            );
        }
    }
}

export function Time(shortTimeFormat) {
    const timeFormat = shortTimeFormat.indexOf("t") !== -1 ? 't' : shortTimeFormat;

    return class TimeFilter extends Component {

        onChange = (event) => {
            this.props.onChange({
                value: event.target.value,
                operator: isTimeSame,
                syntheticEvent: event
            });
        }

        render() {
            const value = this.props.value || null;

            return (
                <div className="k-filtercell">
                    <TimePicker
                        value={value}
                        defaultValue={value}
                        format={timeFormat ? timeFormat : null}
                        formatPlaceholder={null}
                        onChange={this.onChange} />

                    <button
                        className="k-button k-button-icon k-clear-button-visible"
                        title="Clear"
                        disabled={!value}
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.onChange({
                                value: null,
                                operator: '',
                                syntheticEvent: event
                            });
                        }}>
                        <span className="k-icon k-i-filter-clear" />
                    </button>
                </div>
            );
        }
    }
}


export function dateIsInRange(current, value) {
    if (!value) return true;
    if (!current) return false;

    let currentDate = new window.Date(current);
    let selectedDate = new window.Date(value);

    return currentDate.getFullYear() === selectedDate.getFullYear() && currentDate.getMonth() === selectedDate.getMonth()
        && currentDate.getDate() === selectedDate.getDate();
}

function isTimeSame(current, value) {

    if (!value) return true;
    if (!current) return false;

    let currentDate = new window.Date(current);
    let selectedDate = new window.Date(value);

    return currentDate.getHours() === selectedDate.getHours() && currentDate.getMinutes() === selectedDate.getMinutes();    
}

export function DateTimeRange(shortDateFormat, shortTimeFormat) {
    const formatStr = shortDateFormat + ' ' + shortTimeFormat.replace('tt', 'a');
    return class DateTimeRangeFilterCell extends Component {

        static inRange(current, values) {
            return (values.min === null || current >= values.min) &&
                (values.max === null || current <= values.max);
        }

        clear = (event) => {
            event.preventDefault();
            this.props.onChange({ value: null, operator: '', syntheticEvent: event });
        }

        setMin = (event) => {
            console.log(event.target.value);
            this.props.onChange({
                value: { min: event.target.value, max: this.props.value ? this.props.value.max : null },
                operator: DateTimeRangeFilterCell.inRange,
                syntheticEvent: event
            });
        }

        setMax = (event) => {
            this.props.onChange({
                value: { min: this.props.value ? this.props.value.min : null, max: event.target.value },
                operator: DateTimeRangeFilterCell.inRange,
                syntheticEvent: event
            });
        }

        render() {
            let value = this.props.value;
            if (!value) value = null;
            return (
                <div>
                    <div style={{ margin: '0' }}>
                        <DateTimePicker
                            value={value && value.min}
                            format={formatStr}
                            formatPlaceholder={null}
                            onChange={this.setMin}
                        />
                        <span style={{ position: 'relative', left: '6px' }}>To</span>
                    </div>
                    <div style={{ margin: '0' }}>
                        <DateTimePicker
                            value={value && value.max}
                            format={formatStr}
                            formatPlaceholder={null}
                            onChange={this.setMax}
                        />
                        <button
                            className="k-button k-button-icon k-clear-button-visible"
                            title="Clear"
                            disabled={!value}
                            onClick={this.clear}
                        >
                            <span className="k-icon k-i-filter-clear" />
                        </button>
                    </div>
                </div>
            );
        }
    }
}

export function LabeledNumberFilter(label, width) {
    return class NumberFilter extends Component {

        onChange = event => {            
            let _operator = 'eq';
            if (!event.target.value) _operator = '';
            this.props.onChange({
                value: event.target.value,
                operator: _operator,
                syntheticEvent: event
            });
        }

        render() {
            return (
                <Fragment>
                    <NumericTextBox placeholder={'In ' + label} width={width} onChange={this.onChange} />
                    <button
                        className="k-button k-button-icon k-clear-button-visible"
                        title="Clear"
                        disabled={!this.props.value && this.props.value !== 0}
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.onChange({
                                value: '',
                                operator: '',
                                syntheticEvent: event
                            });
                        }}
                    >
                        <span className="k-icon k-i-filter-clear" />
                    </button>
                </Fragment>
                );
        };
    }
}