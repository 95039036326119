import React, { useCallback, useState, useContext } from 'react';
import MessageRecipients from '../SendMessage/MessageRecipients';
import axios from 'axios';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MSGRecipientType from '../SendMessage/MSGRecipientType';
import LoadingMask from '../LoadingMask/LoadingMask';
import AnnouncementComposition from './AnnouncementComposition';

import '../SendMessage/SendMessage.scss';
import './MakeAnnouncement.scss';

export default ({ onClose, preselectedRecipients }) => {


    const [selectedRecipients, setSelectedRecipients] = useState(preselectedRecipients || []);
    const [isLoading, setIsLoading] = useState(false);

    const recipientSelected = useCallback((event) => {
        const selectedRecipient = event.item;
        if (selectedRecipient.type === MSGRecipientType.Vehicle && selectedRecipient.cannotReceiveMessages) alert('Vehicle ' + selectedRecipient.text + ' cannot receive announcements as it does not have an OBU');
        else {
            setSelectedRecipients((currentSelectedRecipients) => {
                if (currentSelectedRecipients.indexOf(selectedRecipient) === -1) return currentSelectedRecipients.concat([selectedRecipient]);
                return currentSelectedRecipients;
            });
        }
    }, []);

    const removeRecipient = useCallback((recipientToRemove) => {
        setSelectedRecipients((currentSelectedRecipients) => currentSelectedRecipients.filter(csr => csr !== recipientToRemove));
    }, []);

    const makeAnnouncement = useCallback((recipients, signText, audioText1, audioText2) => {
        if (recipients && recipients.length > 0) {
            if (signText || audioText1 || audioText2) {
                const cleanRecipients = recipients.map(r => ({ type: r.type, id: r.id, parentId: r.parentId, text: r.text }));
                setIsLoading(true);
                axios.post('/api/MakeAnnouncement', { recipients: cleanRecipients, signText, audioText1, audioText2 }).then(() => {
                    //If announcement was sent successfully, close the make announcement dialog
                    onClose();
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                    alert('There was an error sending your announcement. Please try again. If you are still unable to send, please contact SMI support');
                })
            }
            else {
                alert('Please enter content for your announcement before sending');
            }
        }
        else {
            alert('Your message must have at least one recipient');
        }
    }, [onClose]);

    return (
        <ErrorBoundary>
            <div className="message-screen">
                <MessageRecipients onItemSelected={recipientSelected} />
                <AnnouncementComposition recipients={selectedRecipients} onClearRecipients={() => setSelectedRecipients([])} onRemoveRecipient={removeRecipient} onCancel={onClose} onSend={makeAnnouncement} />
                {isLoading && <LoadingMask />}
            </div>
        </ErrorBoundary>
    );
}