import { formatDate, toString } from '@telerik/kendo-intl';
import kendo from '@progress/kendo-ui';
import Util from './Util';
import * as moment from 'moment';

export function addMinutesToDate(date, minutes) {
    if (!isValidDate(date)) date = parseCSDateTime(date);
    let result = new Date(date);
    result.setMinutes(date.getMinutes() + minutes);
    return result;
}

export function addDaysToDate(date, days) {
    let result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}


export function addMonthsToDate(date, months) {
    let result = new Date(date);
    result.setMonth(date.getMonth() + months);
    return result;
}

export function addYearsToDate(date, years) {
    let result = new Date(date);
    result.setFullYear(date.getFullYear() + years);
    return result;
}

export function adjustDateTimeStringWithTimeZone(source) {
    let res = null;
    try {
        let d = new Date(source);
        let tzo = d.getTimezoneOffset() / 60;

        let s = new Date(source);
        s.setHours(s.getHours() - tzo);
        res = s.toISOString();
    }
    catch (err) {
        console.log("Invalid schedule time.")
    }
    finally {
        return res;
    }
}

export function convertDateStringsToDates(data) {
    if (!Array.isArray(data)) data = [data];
    data.forEach((d) => {
        for (let key in d) {
            if (isImpreciseISOStr(d[key])) d[key] = getDateFromISO(d[key]);
        }
    })
}

export function convertDatesToStrings(data) {
    if (!Array.isArray(data)) data = [data];
    data.forEach((d) => {
        for (let key in d) {
            if (d[key] instanceof Date) d[key] = dateToImpreciseISOStr(d[key]);
        }
    })
}

export function parseDateToUserString(time, date) {
    time = new Date(time.replace('T', ' '));
    date = new Date(date.replace('T', ' '));
    let stringDate = "";
    let hours = time.getHours();
    let minutes = time.getMinutes();
    if (time.getDate() !== date.getDate()) {
        stringDate = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " XM";
    } else if (hours === 12) {
        stringDate = 12 + ":" + (minutes < 10 ? "0" : "") + minutes + " PM";
    } else if (hours >= 12) {
        stringDate = (hours < 22 ? "0" : "") + (hours - 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " PM";
    } else if (hours === 0) {
        stringDate = 12 + ":" + (minutes < 10 ? "0" : "") + minutes + " AM";
    } else {
        stringDate = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " AM";
    }
    return stringDate;
}

export function parseDateToFormattedString(time, date, format) {
    if (format) {
        format = format.replace('tt', 'a');
    }
    let stringDate = formatDate(time, format)
    let timeDay = time.getDate()
    let baseDay = date.getDate()
    
    if (timeDay !== baseDay) {
        //don't worry about "HH" case padding 0, XM will always have full digits.
        if (format.indexOf("H") !== -1) {
            //replace the hours part (first part before ":")
            let hoursPart = stringDate.substring(0, stringDate.indexOf(":"));
            let hoursNum = parseInt(hoursPart)
            //add 24 to the hours part
            hoursNum = hoursNum + 24
            stringDate = hoursNum + stringDate.substring(stringDate.indexOf(":"), stringDate.length)
        }
        else if (format.indexOf("t") !== -1) {
            //format the XM part
            stringDate = (stringDate.indexOf("AM") !== -1) ? stringDate.replace("AM", "XM") : stringDate.replace("PM", "XM")
        }
    }

    return stringDate;
}

export function parseDateToFormattedShortString(time, timeFormat, dateFormat) {
    if (timeFormat) {
        timeFormat = timeFormat.replace('tt', 'a');
    }
    let stringTimeDate = formatDate(time, timeFormat)
    let stringDate = formatDate(time, dateFormat)
    return stringDate + ' ' + stringTimeDate;
}

export function shortFormatXM36hour(date, format, baseDate) {
    //this function expects only 1st day of a month otherwise it will provide wrong time
    //day = 1 for today, day = 2 for xm
    //'HH' or 'H' converts into 36 hours time, pad 0 if necessary
    //'tt' converts AM/PM into XM
    //custom convert if date is next day
    //kendo formatString format changed so we need to change the format 
    //before passing to formatString function
    //thats it.
    if (!date) return '';
    if (format.indexOf("t") !== -1)
        format = 't';
    let stringDate = formatDate(date, format);
    let isXM = false;
    if (baseDate) {
        try {
            if (Object.prototype.toString.call(baseDate) !== '[object Date]') {                
                baseDate = parseISODateWithNoTime(baseDate);                
            }
            
            if (baseDate.getDate() < date.getDate())
                isXM = true;
        } catch (e) {
            return stringDate;
        }
       
    } else if (!baseDate && date.getDate() > 1)
        isXM = true;

    if (isXM) {
        if (format.indexOf("H") !== -1) {
            //replace the hours part (first part before ":")
            let hoursPart = stringDate.substring(0, stringDate.indexOf(":"));
            let hoursNum = parseInt(hoursPart)
            //add 24 to the hours part
            hoursNum = hoursNum + 24
            stringDate = hoursNum + stringDate.substring(stringDate.indexOf(":"), stringDate.length)
        }
        else if (format.indexOf("t") !== -1) {
            //format the XM part
            stringDate = (stringDate.indexOf("AM") !== -1) ? stringDate.replace("AM", "XM") : stringDate.replace("PM", "XM")
        }
    }    
    return stringDate;
}

//Taken from https://stackoverflow.com/a/325964/2457005
export function dateRangesAreOverlapping(startDate1, endDate1, startDate2, endDate2) {
    return startDate1 <= endDate2 && endDate1 >= startDate2;
}

export function getTodayDate() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

export function isSameDate(date1, date2) {
    let d1 = new Date(date1), d2 = new Date(date2);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    return d1.getTime() === d2.getTime();
}

export function dateToImpreciseISOStr(date) {
    return dateToTimelessISO(date) + 'T' + dateToTimeOnlyISO(date);
}

export function getISODateWithNoTime(date) {
    let month = date.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let day = date.getDate();
    if (day < 10) day = '0' + day;
    return date.getFullYear() + '-' + month + '-' + day;
}

export function isImpreciseISOStr(str) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/.test(str);
}

export function getMinutesBetweenDates(date1, date2) {
    if (!date1 || !date2) return 0;
    let diff = date2.getTime() - date1.getTime();
    return (diff / 60000);
}

export function dateToTimelessISO(date) {
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    if (month.length < 2) month = '0' + month;
    let day = date.getDate().toString();
    if (day.length < 2) day = '0' + day;
    return year + '-' + month + '-' + day;
}

export function dateToTimeOnlyISO(date) {
    let hour = date.getHours().toString();
    if (hour.length < 2) hour = '0' + hour;
    let minute = date.getMinutes().toString();
    if (minute.length < 2) minute = '0' + minute;
    let second = date.getSeconds().toString();
    if (second.length < 2) second = '0' + second;
    return hour + ':' + minute + ':' + second;
}


export function getDateTimeWithSecondStringAsDisplayValue(dateTime) {
    if (!dateTime.getTime)
        throw "Not Date Type";

    return dateTime.getFullYear() + "/" + padTo2(dateTime.getMonth() + 1) + "/" + padTo2(dateTime.getDate())
        + " " + padTo2(dateTime.getHours()) + ":" + padTo2(dateTime.getMinutes()) + ":" + padTo2(dateTime.getSeconds());
}

export function getSecondOfDayFromBrowserTime(date, baseUTCDate) {
    let xmDays = date.getDate() - baseUTCDate;
    let secOfDay = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
    let result = secOfDay + xmDays * 24 * 3600;
    return result;
}

export function getSecondOfDayFromISO(date, baseUTCDate) {
    if (!date)
        return;

    if (!baseUTCDate)
        return;

    let segments = date.split('T');
    let xmDays = parseInt(segments[0].split('-')[2]) - baseUTCDate;
    let timeStrings = segments[1].split(":");
    let secOfDay = parseInt(timeStrings[0]) * 3600 + parseInt(timeStrings[1]) * 60 + parseInt(timeStrings[2].substr(0, 2));
    secOfDay += xmDays * 24 * 3600;
    return secOfDay;

}

export function padTo2(num) {
    return ("00" + num).slice(-2);
}

export function formatDateTimeAsSetting(dateStr, type, SystemSettings ) {
    let dateValue = parseCSDateTime(dateStr);
    if (type == 'datetime') {
        return kendo.toString(dateValue, SystemSettings.shortDateFormat + " " + SystemSettings.shortTimeFormat);
    }
    else if (type == 'date') {
        return kendo.toString(dateValue, SystemSettings.shortDateFormat);
    }
    else if (type == 'time') {
        return kendo.toString(dateValue, SystemSettings.shortTimeFormat);
    }
    else {
        return dateStr.toString();
    }
}

export function formatDateTime(date, format) {
    if (!Util.isNullOrUndefined(format)) {
        return kendo.toString(date, format);
    }    
    else {
        return date.toString();
    }
}

export function parseCSDateTime(csharpTime) {
    let safeBrowserformat = csharpTime.replace(/-/g, '/').replace('T', ' ');
    return new Date(safeBrowserformat);
}


/**
* fromISO assume C# serialize client time in pretented utc format before serialization. It will parse into browser time, to display correct client time.
* The result tick is no longer equal to the same moment.
* @param s string 
*/
export function getDateFromISO(s) {
    if (typeof s === 'object') return s;
    let day, tz,
        rx = /^(\d{4}-\d\d-\d\d([tT ][\d:.]*)?)([zZ]|([+-])(\d\d):(\d\d))?$/,
        p = rx.exec(s) || [];
    if (p[1]) {
        day = p[1].split(/\D/);
        for (let i = 0, L = day.length; i < L; i++) {
            day[i] = parseInt(day[i], 10) || 0;
        }
        day[1] -= 1;
        day = new Date(day[0], day[1], day[2], day[3], day[4], day[5], 0);
        if (!day.getDate()) return NaN;
        if (p[5]) {
            tz = (parseInt(p[5], 10) * 60);
            if (p[6]) tz += parseInt(p[6], 10);
            if (p[4] === '+') tz *= -1;
            if (tz) day.setUTCMinutes(day.getUTCMinutes() + tz);
        }
        return day;
    }
    return NaN;
};

export function getDateStringAsDisplayValue(datetime) {
    if (!datetime.getTime) throw "Not Date type";
    return datetime.getFullYear() + "/" + _padTo2(datetime.getMonth() + 1) + "/" + _padTo2(datetime.getDate());
};

export function getDateTimeStringAsDisplayValue(datetime) {
    if (!datetime.getTime) throw "Not Date type";
    return datetime.getFullYear() + "/" + _padTo2(datetime.getMonth() + 1) + "/" + _padTo2(datetime.getDate())
        + " " + _padTo2(datetime.getHours()) + ":" + _padTo2(datetime.getMinutes()) + ":" + _padTo2(datetime.getSeconds());
};

export function  _padTo2(num) {
    return ("00" + num).slice(-2);
};

export function flexibleTimeParse (str) {
    str = str.replace(/\s/g, '');
    if (!str) return null;

    //Convert three-digit military time to four-digit military time
    if (/^\d+$/.test(str) && str.length === 3) str = '0' + str;

    let digitsEnd = 0; //position where digit part of string ends
    let colons = 0; //semicolons count

    //find where to split time string positions and count semiclolons in digital part
    for (digitsEnd = 0; digitsEnd < str.length; digitsEnd++) {
        let chr = str.charAt(digitsEnd);
        if (isNaN(chr) && chr != ':') break;
        else if (chr == ':') colons++;
    }

    //split input to digit and characters part (e.g. "01:23 PM" to "01:23" and "PM"
    let digitPart = str.substring(0, digitsEnd);
    let charPart = str.substring(digitsEnd);

    if (colons > 1 && digitPart.substring(0, 1) == ':' && digitPart.charAt(digitPart.length - 1) == ':') {
        //i.e. 2 or 3 : ":01:23:" or ":0123:";
        digitPart = digitPart.substring(1, digitPart.length - 2);
        colons -= 2;
    }
    else if (colons == 2 && digitPart.substring(0, 1) == ':') {
        //more than one semicolon and one is on the first place
        digitPart = digitPart.substring(1, digitPart.length - 1);
        colons--;
    }
    else if (colons == 2 && digitPart.charAt(digitPart.length - 1) == ':')  //more than one semicolon and one is on the last place
    {
        digitPart = digitPart.substring(0, digitPart.length - 1);
        colons--;
    }
    else if (digitPart.charAt(digitPart.length - 1) == ':') {
        //one semicolon on the end - means minutes = 0, e.g  "10:" became "10:00"
        digitPart = digitPart + '00';
    }
    else if (digitPart.substring(0, 1) == ':') {
        //one semicolon on the beginning - means hours = 0, e.g  ":10" became "00:10"
        digitPart = '00' + digitPart;
    }

    if (colons == 0)    //if no semicolons then it inserted befor two digitson the end, e.g. "1003" became "10:03"
    {        
        if (digitPart.length < 2) digitPart = digitPart.padStart(2, "0");
        if (digitPart.length < 3) digitPart = digitPart.padEnd(4, "0");
        digitPart = digitPart.substring(0, 2) + ':' + digitPart.substring(digitPart.length - 2);
    }

    let timeArr = digitPart.split(':');
    let hours = parseInt(timeArr[0]);
    let minutes = parseInt(timeArr[1]);

    if (isNaN(hours) || isNaN(minutes)) return null;

    if (charPart.length > 0) {
        let firstChar = charPart.toLowerCase().substring(0, 1);
        if (firstChar == 'x' && hours != 12)
            hours += 24;
        else if (firstChar == 'x' && hours == 12)
            hours = 24;
        else if (firstChar == 'p' && hours != 12)
            hours += 12;
        else if (firstChar == 'a' && hours == 12)
            hours = 0;
    }

    return { hour: hours, minute: minutes };
}

export function stripTimeFromDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

export function parseISODateWithNoTime(str) {
    if (!str) return null;
    let arr = str.split('-');
    return new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]));
}

export function parseISODateWithTimeOnly(str) {
    if (!str) return null;
    let arr = str.split(':');
    return new Date(2000, 1, 1, parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]));
}

export function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export function isDate(str) {    
    if (!str) return false;
    str = str.toString();
    if (!isNaN(str)) return false;
    let momentDate = moment(str);    
    return momentDate.isValid();
}

/* Returns a string of the passed date at midnight, for passing dates to Web API
     */
export function getMidnightDateString(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month <= 9) month = '0' + month;
    let day = date.getDate();
    if (day <= 9) day = '0' + day;
    return year + '-' + month + '-' + day + 'T00:00:00';
}

export function convertToUTC(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);    
}

export function convertScheduleDate(schDate) {
    if (schDate) {
        var currentDate = new Date(schDate);
        var fullYear = currentDate.getFullYear().toString().substr(2, 2);
        var month = _padTo2((currentDate.getMonth() + 1), 2);
        var date = _padTo2(currentDate.getDate(), 2);
        return fullYear + '-' + month + '-' + date;
    } else
        return 'Onwards';
}


export function formatMinutes(minutes) {
    if (!minutes) return '0:00';
    var timeHours = Math.floor(minutes / 60);
    var timeMinutes = Math.round(minutes % 60);
    if (timeMinutes < 10) timeMinutes = '0' + timeMinutes;
    return timeHours + ':' + timeMinutes;
}