import './SegmentAlert.scss';
import React, { useState } from 'react';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import PresentationContainer from '../AlertComponent/PresentationContainer';
import { TreeView } from '@progress/kendo-react-treeview';
import Util from '../../../Utils/Util';

export default (props) => {    
    const [collapsedIds, setCollapsedIds] = useState([]);

    const expandChange = event => {
        event.item.expanded = !event.item.expanded;
        let segmentId = event.item.segmentID;
        let _collapsedIds = collapsedIds.slice(0);

        if (event.item.expanded && !_collapsedIds.includes(segmentId))
            _collapsedIds.push(segmentId);
        if (!event.item.expanded && _collapsedIds.includes(segmentId)) {
            let index = _collapsedIds.indexOf(segmentId);
            _collapsedIds.splice(index, 1);
        }
        setCollapsedIds(_collapsedIds);
    }

    const checkChange = event => {
        let segmentId = event.item.segmentID;
        let segmentIds = props.data.segmentIds.slice(0);

        event.item.checked = !event.item.checked;

        if (event.item.checked && !segmentIds.includes(segmentId))
            segmentIds.push(segmentId);
        if (!event.item.checked && segmentIds.includes(segmentId)) {
            let index = segmentIds.indexOf(segmentId);
            segmentIds.splice(index, 1);
        }

        updateSegments(segmentIds);
    }

    const updateSegments = segmentIds => {
        let targetObject = {
            target: {
                name: 'segmentIds',
                value: segmentIds
            }
        };
        props.actions.inputChange(targetObject);
    }

    const getTreeData = (segmentData) => {        
        segmentData.forEach(item => {
            item.expanded = false;
            item.checked = false;

            if (props.data.segmentIds &&
                props.data.segmentIds.includes(item.segmentID)) item.checked = true;

            if (collapsedIds && collapsedIds.includes(item.segmentID)) item.expanded = true;

            if (item.items && item.items.length > 0)
                getTreeData(item.items);
        });

        if (!segmentData.items || segmentData.items.length < 1) return segmentData;
    };

    const treeData = getTreeData(props.data.segments);

    return (
        <div id="segmentAlert">
            <div className="customEditLeft">
                <div className="TreebeardHeader">
                    Available
                    {props.subscriptionTypeId !== 14 && <span> Segments</span>}
                    {props.subscriptionTypeId === 14 && <span> Incidents</span>}
                </div>
                <div className="Treebeard">
                    <TreeView
                        data={props.data.segments}
                        textField="name"
                        checkboxes={true}
                        expandIcons={true}
                        onCheckChange={checkChange}
                        onExpandChange={expandChange} />
                </div>
            </div>
            <div className="customEditRight">
                <PresentationContainer {...props} RSS={<RSS {...props} />}>
                </PresentationContainer>
            </div>
        </div>
    );
}

const RSS = (props) => {
    return (
        <div className="form-control">
            {props.subscriptionTypeId !== 14 &&
                <>
                    <div className="k-edit-label">
                        <span className="rss"></span>
                        <label>RSS Feed:</label>
                    </div>

                    <div className="k-edit-field custom-edit-field">
                        {
                            props.subscriptionTypeId !== 13 &&
                            <div>
                                <label>
                                    Yes
                                    <input
                                        type="radio"
                                        checked={props.data.rss}
                                        datatype="boolean"
                                        onChange={props.actions.rssFeedOptionChange}
                                        name="rss" />
                                </label>
                                <label>
                                    No
                                    <input
                                        type="radio"
                                        name="rss"
                                        checked={!props.data.rss}
                                        datatype="boolean"
                                        onChange={props.actions.rssFeedOptionChange} />
                                </label>
                            </div>
                        }

                        {props.subscriptionTypeId === 13 && <span> Yes</span>}

                        {
                            props.subscriptionTypeId !== 0 &&
                            <div>
                                <br />
                                <input
                                    type="textbox"
                                    name="rssUrl"
                                    onChange={props.actions.inputChange}
                                    className="k-input k-textbox"
                                    value={props.data.rssUrl } />
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    );
}