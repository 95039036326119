import React from 'react';
import L from 'leaflet';
import { Polygon, Rectangle } from 'react-leaflet';
import Util from './Util';

const defaultVehicleIconBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wcRFQYXul2ragAAAZRJREFUSMftlc8rw2Ecx1/P98d+EWUXZTVrNQecFGscXGRYlIOWnF2UqPkTpJj4E5wc3FZIuUiSqd1IDlqKUnLRZu27Xw48a1iMbTfv03N5v97P836eT49IpgwAQpsHZ0exeCfAczLdyB/U1GBOAAz2uC7DC34vgJhdjgBE906ue6mhxgY6zoE+BYhSP0WFZ3LzBSCZMqy6pgKwOO0DYGqoC4BWe/nGHp4SAOwcXgCwsX0KQCabo8FqSgFoyZRhlYbQTD8Ac1N9FW1PBs8HvQCoigBgZesYyVU+myqFl1M574eA4HB31aV/ZhQDLCYNe7Ot6gB7sw2LSfsaoOtqzZ5OKUuhzvoP+FGibWS18F/Rd9JMupoBMDI5/SayiFl/GxLH6NqvQHf7SwCkM1ncExtIriKEyAsh8gBmXUtXu2PJkFzhGl835AlqWU3xBHW/A7ej5R7gKv7YXkuw5IpC4W0MnIFwNpcvqO8fRw7gdjekVQJzBsJZgFK/9H5XkfabJn6cA7/PEytd+32eiul+n+eLX65fAQUGc75Aq8MZAAAAAElFTkSuQmCC";
const VehicleBubbleSvgWithoutBearing = `
		<?xml version="1.0" encoding="UTF-8" standalone="no"?>
		<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
		   width="{{SIZE}}" height="{{SIZE}}" id="svg2">
		  <defs id="defs4">			
			<radialGradient cx="75.625" cy="25" r="25" fx="75.625" fy="25" id="radialGradient3831" 
			   xlink:href="#linearGradient3825" gradientUnits="userSpaceOnUse" gradientTransform="translate(-50.625,0)" />
		  </defs>
		  <g transform="scale({{SCALE}})"> 
			  <g id="g">
				<path d="M 25,0 C 11.192881,0 0,11.192881 0,25 0,38.807119 11.192881,50 25,50 38.807119,50 50,38.807119 50,25 50,11.192881 38.807119,0 25,0 z m 0,6 C 35.49341,6 44,14.50659 44,25 44,35.49341 35.49341,44 25,44 14.50659,44 6,35.49341 6,25 6,14.50659 14.50659,6 25,6 z" id="path3769" style="fill:url(#radialGradient3831);fill-opacity:1;fill-rule:nonzero;stroke:none" />				
				<path id="internalCircle" d="m 46.669046,29.14035 a 19.091883,19.622211 0 1 1 -38.183765,0 19.091883,19.622211 0 1 1 38.183765,0 z" 
				  transform="matrix(0.95554973,0,0,0.92972412,-1.3513513,-2.0924866)" 
				  fill="{{COLOR}}" style="fill-opacity:0.5;stroke:#000000;stroke-width:1;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
			  </g>
		  <image id="embedImg" display="block" xlink:href="{{ICONDATAURL}}" x="12" y="12" height="25px" width="25px"/>
		  </g> 
		</svg>
		`.trim();

const VehicleBubbleSvg = `
		<?xml version="1.0" encoding="UTF-8" standalone="no"?>
		<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
		   width="{{SIZE}}" height="{{SIZE}}" id="svg2">
		  <defs id="defs4">			
			<radialGradient cx="75.625" cy="25" r="25" fx="75.625" fy="25" id="radialGradient3831" 
			   xlink:href="#linearGradient3825" gradientUnits="userSpaceOnUse" gradientTransform="translate(-50.625,0)" />
		  </defs>
		  <g transform="scale({{SCALE}})"> 
			  <g id="g"  transform="rotate({{ROTATE}},25,25)">
				<path d="M 25,0 C 11.192881,0 0,11.192881 0,25 0,38.807119 11.192881,50 25,50 38.807119,50 50,38.807119 50,25 50,11.192881 38.807119,0 25,0 z m 0,6 C 35.49341,6 44,14.50659 44,25 44,35.49341 35.49341,44 25,44 14.50659,44 6,35.49341 6,25 6,14.50659 14.50659,6 25,6 z" id="path3769" style="fill:url(#radialGradient3831);fill-opacity:1;fill-rule:nonzero;stroke:none" />
				<path id="pointer" display="block" d="M 25,0.03125 19.65625,7.8125 C 21.34715,7.2865754 23.136039,7 25,7 c 1.863961,0 3.65285,0.2865754 5.34375,0.8125 L 25,0.03125 z" style="fill:#000000;fill-opacity:1;stroke:none" />
				<path id="internalCircle" d="m 46.669046,29.14035 a 19.091883,19.622211 0 1 1 -38.183765,0 19.091883,19.622211 0 1 1 38.183765,0 z" 
				  transform="matrix(0.95554973,0,0,0.92972412,-1.3513513,-2.0924866)" 
				  fill="{{COLOR}}" style="fill-opacity:0.5;stroke:#000000;stroke-width:1;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
			  </g>
		  <image id="embedImg" display="block" xlink:href="{{ICONDATAURL}}" x="12" y="12" height="25px" width="25px"/>
		  </g> 
		</svg>
		`.trim();

const MapHelper = {
    getStopSVG: function (color, seq, opacity) {
        if (!color) color = 'red';
        if (!opacity) opacity = 1;
        let stopSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" opacity="' + opacity + '">';
        stopSvg += '<circle cx="10" cy="10" r="9" stroke="#000" stroke-width="1" fill="' + color + '" />';
        if (seq) stopSvg += '<text text-anchor="middle" x="10" y="14" font-family="Segoe UI" stroke="none" fill="white" font-size="11">' + seq + '</text>';
        stopSvg += '</svg>';
        return stopSvg;
    },
    getIncidentSVG: function (color, icon) {
        if (!color) color = 'ffffff';
        color = MapHelper.lightenColor(color, 0.55);
        let incidentSvg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="29">';
        incidentSvg += '<circle cx="15" cy="15" r="13" stroke="#1b498a" stroke-width="2" fill="#' + color + '" />';
        incidentSvg += '<image xlink:href="' + icon + '" x="6" y="6" width="18px" height="18px" />'
        incidentSvg += '</svg>';
        return incidentSvg;
    },
    getVehicleIcon : (bearing, color, iconUrl, zoomLevel) => {
        let scale = 1;
        iconUrl = iconUrl || defaultVehicleIconBase64;
        var actualSize = scale * 50;
        let svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="41" viewBox="0 0 40 41">';
        svg += `<image xlink:href="${iconUrl}" x="11" y="12" width="18" height="18" />`;
        svg += '</svg>';

        var vehicleSvg = (bearing ? VehicleBubbleSvg : VehicleBubbleSvgWithoutBearing)
            .replace("{{ROTATE}}", bearing)
            .replace("{{COLOR}}", color)
            .replace("{{SCALE}}", scale + "")
            .replace("{{ICONDATAURL}}", iconUrl)
            .replace(/{{SIZE}}/g, actualSize + "");

        return L.divIcon({
            html: vehicleSvg,
            iconAnchor: [20, 20]
        });
    },

    //Alpha must be a value from 0 to 1
    lightenColor: function (colorHex, alpha) {
        let inverseAlpha = 1 - alpha;
        let r = parseInt(colorHex.substr(0, 2), 16);
        let g = parseInt(colorHex.substr(2, 2), 16);
        let b = parseInt(colorHex.substr(4, 2), 16);
        let newR = Math.floor((255 * alpha) + (inverseAlpha * r)).toString(16);
        let newG = Math.floor((255 * alpha) + (inverseAlpha * g)).toString(16);
        let newB = Math.floor((255 * alpha) + (inverseAlpha * b)).toString(16);
        return ('0' + newR).slice(-2) + ('0' + newG).slice(-2) + ('0' + newB).slice(-2);
    },
    getStopIcon : (color, icon) => {
        let svg = MapHelper.getIncidentSVG(color, icon);        
        return L.divIcon({
            html: svg,
            iconSize: [15, 15],
            iconAnchor: null
        });
    },
    getStopSeqIcon: (color, seq, opacity) => {
        let svg = MapHelper.getStopSVG(color, seq, opacity);
        return L.divIcon({
            html: svg,
            iconSize: [15, 15],
            iconAnchor: null
        });
    },
    //distance is in meters
    //Adapted from "destination point given distance and bearing from start point" function at http://www.movable-type.co.uk/scripts/latlong.html
    findPointAtDistanceAlongBearing: function (startLat, startLng, bearing, distance) {
        var radiusOfEarth = 6371100;
        var radAngularDistance = distance / radiusOfEarth;
        var radBearing = MapHelper.degreeToRadian(bearing);

        var radStartLat = MapHelper.degreeToRadian(startLat)
        var radStartLng = MapHelper.degreeToRadian(startLng);

        var sinStartLat = Math.sin(radStartLat), cosStartLat = Math.cos(radStartLat);
        var sinAngularDistance = Math.sin(radAngularDistance), cosAngularDistance = Math.cos(radAngularDistance);
        var sinBearing = Math.sin(radBearing), cosBearing = Math.cos(radBearing);

        var sinX = sinStartLat * cosAngularDistance + cosStartLat * sinAngularDistance * cosBearing;
        var x = Math.asin(sinX);
        var a = sinBearing * sinAngularDistance * cosStartLat;
        var b = cosAngularDistance - sinStartLat * sinX;
        var y = radStartLng + Math.atan2(a, b);

        return { x: MapHelper.radianToDegree(x), y: (MapHelper.radianToDegree(y) + 540) % 360 - 180 }; // normalise to −180..+180°

    },
    getRectanglesFromPolyline: (locations, color, distance)=> {
        //let locations = polyline.getLocations();
        let rectangles = [];
        for (let i = 1; i < locations.length; i++) {
            let x = locations[i - 1];
            let y = locations[i];
            let bearing = MapHelper.getBearingOfLatLngLine(x.lat, x.lng, y.lat, y.lng);
            let reverseBearing = (bearing + 180) % 360;
            let perpendicularBearing1 = (bearing + 90) % 360;
            let perpendicularBearing2 = (bearing + 270) % 360;
            if (perpendicularBearing2 < 0) perpendicularBearing2 = 360 + perpendicularBearing2;

            let startPoint = MapHelper.findPointAtDistanceAlongBearing(x.lat, x.lng, reverseBearing, distance);
            let endPoint = MapHelper.findPointAtDistanceAlongBearing(y.lat, y.lng, bearing, distance);

            let slope = (y.lng - x.lng) / (y.lat - x.lat);
            let m = -1 / slope;
            let corner1 = MapHelper.findPointAtDistanceAlongBearing(startPoint.x, startPoint.y, perpendicularBearing1, distance);
            let corner2 = MapHelper.findPointAtDistanceAlongBearing(endPoint.x, endPoint.y, perpendicularBearing1, distance);
            let corner3 = MapHelper.findPointAtDistanceAlongBearing(endPoint.x, endPoint.y, perpendicularBearing2, distance);
            let corner4 = MapHelper.findPointAtDistanceAlongBearing(startPoint.x, startPoint.y, perpendicularBearing2, distance);
            let _rectLocation = [{ lat: corner1.x, lng: corner1.y },
                { lat: corner2.x, lng: corner2.y },
                { lat: corner3.x, lng: corner3.y },
                { lat: corner4.x, lng: corner4.y }];

            rectangles.push(<Polygon key={Util.getNewDummyId()} positions={_rectLocation} color={color} weight={2} fill={true} stroke={false} opacity={0.5} />);            
        }
        return rectangles;
    },
    getBearingOfLatLngLine: (lat1, lng1, lat2, lng2) => {
        var radLat1 = MapHelper.degreeToRadian(lat1)
        var radLat2 = MapHelper.degreeToRadian(lat2);
        var radLngDiff = MapHelper.degreeToRadian(lng2 - lng1);

        var y = Math.sin(radLngDiff) * Math.cos(radLat2);
        var x = Math.cos(radLat1) * Math.sin(radLat2) - Math.sin(radLat1) * Math.cos(radLat2) * Math.cos(radLngDiff);
        var result = Math.abs(((Math.atan2(y, x) + (2 * Math.PI)) % (2 * Math.PI)) * 180 / Math.PI) + (1 / 7200);

        return result;
    },
    /* Converts angle from degrees to radian
     */
    degreeToRadian: function (angle) {
        return Math.PI * angle / 180.0;
    },

    /* Converts angle from radian to degrees
     */
    radianToDegree: function (angle) {
        return angle * (180.0 / Math.PI);
    },
    pointInPolygon: function (point, polygonPoints, xVal, yVal) {
        if (!xVal) xVal = 'x';
        if (!yVal) yVal = 'y';
        var j = polygonPoints.length - 1;
        var pointInPolygon = false;

        var x = point[xVal];
        var y = point[yVal];

        for (var i = 0; i < polygonPoints.length; i++) {
            if ((polygonPoints[i][yVal] < y && polygonPoints[j][yVal] >= y || polygonPoints[j][yVal] < y && polygonPoints[i][yVal] >= y) && (polygonPoints[i][xVal] <= x || polygonPoints[j][xVal] <= x)) {
                if (polygonPoints[i][xVal] + (y - polygonPoints[i][yVal]) / (polygonPoints[j][yVal] - polygonPoints[i][yVal]) * (polygonPoints[j][xVal] - polygonPoints[i][xVal]) < x) {
                    pointInPolygon = !pointInPolygon;
                }
            }
            j = i;
        }
        return pointInPolygon;
    }
};

export default MapHelper;