import React, { useState } from 'react';
import RecipientPresentation from './RecipientPresentation';
import CannedMessages from './CannedMessages';
import CurrentRecipients from './CurrentRecipients';

const maxMessageLength = 400;

export default ({ recipients, onRemoveRecipient, onSend, onCancel, onClearRecipients }) => {

    const [message, setMessage] = useState('');
    const [priority, setPriority] = useState(Priorities.Medium);
    const [requireYesNo, setRequireYesNo] = useState(false);

    return (
        <div className="right-column">
            <div className="right-column-top">
                <CurrentRecipients recipients={recipients} onRemoveRecipient={onRemoveRecipient} onClearRecipients={onClearRecipients} />
                <div className="select-priority">
                    <h2>Select Priority</h2>
                    <div className="priority-options">
                        <label className="priority-label low">Low
                            <input type="radio" checked={priority === Priorities.Low} name="priority" value={Priorities.Low} onChange={(e) => setPriority(e.target.value)} />
                            <span className="custom-radio"></span>
                        </label>
                        <label className="priority-label medium">Medium
                            <input type="radio" checked={priority === Priorities.Medium} name="priority" value={Priorities.Medium} onChange={(e) => setPriority(e.target.value)} />
                            <span className="custom-radio"></span>
                        </label>
                        <label className="priority-label high">High
                            <input type="radio" checked={priority === Priorities.High} name="priority" value={Priorities.High} onChange={(e) => setPriority(e.target.value)} />
                            <span className="custom-radio"></span>
                        </label>
                    </div>
                </div>
                <div className="message-textbox">
                    <textarea placeholder="Type or select your message" value={message} onChange={(e) => setMessage(e.target.value)} maxLength={maxMessageLength} />
                    <CannedMessages onItemSelected={(event) => setMessage(event.item.text)} />
                </div>
                <div className="message-info">
                    <span>Characters Remaining: {maxMessageLength - message.length}</span>
                    <label><input type="checkbox" checked={requireYesNo} onChange={(e) => setRequireYesNo(e.target.checked)} /> Require Yes/No Response</label>
                </div>
            </div>
            <div className="right-column-bottom">
                <button className="cancel-button" onClick={onCancel}>Cancel</button>
                <button className="send-button" onClick={() => onSend(recipients, message, priority, requireYesNo)}>Send</button>
            </div>
        </div>
    )
}

const Priorities = {
    Low: '3',
    Medium: '2',
    High: '1'
};