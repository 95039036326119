import './HardwareAssignment.scss';
import axios from 'axios';
import React, { Component } from 'react';
import sortHelper from '../../Utils/SortHelper';
import { Router, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MainPage from '../../components/MainPage/MainPage';
import SwitchCommunity from '../Mobile/SwitchCommunity';
import HardwareRegistration from '../Mobile/HardwareRegistrationPage';

export default class HardwareAssignment extends Component {

    state = {
        assemblyStatuses: [],
        hardwares: [],
        unassignedVehicleList: [],
        assignableVehicles: [],
        selectedHardware: -1,
        selectedVehicle: -1,
        canTestSignSound: false,
        sendingSignSoundCommand: false        
    };

    history = createBrowserHistory({ basename: this.baseUrl });

    componentDidMount() {
        

        //Load data from APIs
        axios.get('/api/Hardware').then(response => {

            let _hardwares = response.data;
            _hardwares.filter(h => !h.vehicleName).forEach(h => { h.vehicleName = 'Unassigned (' + h.hardwareId + ')' });
            //this.hardware = _hardwares.sort((a, b) => a.hardwareId - b.hardwareId);
            let formattedHardwares = _hardwares.filter(h => h.hardwareId > 0).map(h => ({ id: h.hardwareId, name: h.hardwareId + ' (' + (h.vehicleName ? h.vehicleName.split('(')[0].trim() : 'No Vehicle') + ')', vehicleId: h.vehicleID }));
            

            axios.get('/api/HardwareVehicle').then(hVehicleResponse => {
                let _hVehicle = hVehicleResponse.data.sort((a, b) => sortHelper.sortNaturalAlphaNumeric(a, b, 'vehicleName'));
                let formattedHVehicles = _hVehicle.map(h => ({ vehicleId: h.vehicleID, name: h.vehicleName }));

                this.setState({ unassignedVehicleList: formattedHVehicles, hardwares: formattedHardwares });
            }).catch(function (e) {
                if (e && e.response && e.response.status === 401) window.location = '/';
            });

        }).catch(function (e) {
                if (e && e.response && e.response.status === 401) window.location = '/';
            });



        axios.get("/api/HasOperation?operation=HardwareCommandSignSound").then(response => {
            if (response.data) this.setState({ canTestSignSound: true });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.unassignedVehicleList !== this.state.unassignedVehicleList || prevState.hardwares !== this.state.hardwares) {
            const assignableVehicles = this.state.hardwares.filter(v => !!v.vehicleId).concat(this.state.unassignedVehicleList).map(v => ({ id: v.vehicleId, name: v.name.indexOf('(') !== -1 ? v.name.split('(')[1].split(')')[0] : v.name })).sort((a, b) => sortHelper.sortNaturalAlphaNumeric(a, b, 'name'));
            this.setState({ assignableVehicles: assignableVehicles });
        }
    }

    hardwareSelected = (e) => {
        let selectedHardware = this.state.hardwares.find(h => h.id == e.target.value);
        this.setState({ selectedHardware: e.target.value, selectedVehicle: selectedHardware.vehicleId || -1 });
    }

    saveClicked() {
        if (this.state.isSaving) return;
        this.setState({ isSaving: true });
        axios.post('/api/HWAssign', { hardwareId: this.state.selectedHardware, vehicleId: this.state.selectedVehicle == -1 ? null : this.state.selectedVehicle }).then((r) => {
            alert('Hardware updated successfully');
            window.location.reload();
        }).catch(function (e) {
                this.setState({ isSaving: false });
                if (e && e.response && e.response.status === 401)
                    window.location = '/';
            });
    }

    testSignSound = () => {
        if (this.state.sendingSignSoundCommand) return;

        this.setState({ sendingSignSoundCommand: true });
        axios.post('/api/HSSendCommand', {
            commandParams: { repetitions: "5" },
            commandType: "testSignSound",
            hardwareIds: [this.state.selectedHardware]
        }).then(response => {
            if (response.data) alert('Test sign/sound command sent');
            else alert('Error sending test sign/sound command');
        })
            .catch(err => {
                alert('Error sending test sign/sound command');
            })
            .then(() => this.setState({ sendingSignSoundCommand: false }));
    }

    unassignedVehicleChnaged(e) {
        this.setState({ unassignedVehicle: e.target.value });
    }


    notesUpdated(e) {
        this.setState({ notes: e.target.value });
    }

    generateAssemblyOption(item) {
        if (!item.children || !item.children.length) return <option key={item.id} value={item.id}>{item.name}</option>
        else {
            let subOptions = item.children.map(i => this.generateAssemblyOption(i));
            return (
                <optgroup key={item.id} label={item.name}>
                    {subOptions}
                </optgroup>
            );
        }
    }

    selectedVehicleChanged = (e) => {
        this.setState({ selectedVehicle: e.target.value });
    }

    updatePage() {
        setTimeout(() => window.location.reload());
    }

    render() {

        if (window.location.pathname == "/Maintain/Hardware/Registration") {
            return <MainPage />
        }
        else if (window.location.pathname == "/Mobile/SwitchCommunity") {
            return <SwitchCommunity />
        }
        else if (window.location.pathname == '/Mobile/HardwareRegistration')
            return <HardwareRegistration />

        const hardwareOptions = this.state.hardwares.map(v => <option key={v.id} value={v.id}>{v.name}</option>);

        const vehicleOptions = this.state.assignableVehicles.map(v => <option key={v.id} value={v.id}>{v.name}</option>);

        return (
            <div id="mobileHardwareAssignment">
                <div id="router">
                    <Router history={this.history}>
                        <Link onClick={this.updatePage} to="/Maintain/Hardware/Registration">Dispatch Portal</Link>
                        <div className="spacer" /><label> |</label> <div className="spacer" />
                        <Link onClick={this.updatePage} to="/Mobile/HardwareRegistration">Hardware Registration</Link>
                        <div className="spacer" /><label style={{ display: this.state.hasCommunities ? 'inline' : 'none' }}> | </label><div className="spacer" />
                        <Link onClick={this.updatePage} to="/Mobile/SwitchCommunity" style={{ display: this.state.hasCommunities ? 'inline' : 'none' }} >Chnage Community</Link>
                    </Router>
                </div>

                <h1>Hardware Assignment</h1>
                <fieldset>
                    <legend>Hardware</legend>
                    <select value={this.state.selectedHardware} onChange={(event) => this.hardwareSelected(event)}>
                        <option value="-1">Select Hardware</option>
                        {hardwareOptions}
                    </select>
                    {this.state.selectedHardware > 0 && this.state.canTestSignSound && <button disabled={this.state.sendingSignSoundCommand} onClick={this.testSignSound} style={{ marginTop: '15px', padding: '10px' }}>Test Sign and Sound</button>}
                </fieldset>
                <div className="hardware-area" style={{ display: this.state.selectedHardware > 0 ? 'block' : 'none' }}>
                    <div>
                        <fieldset>
                            <legend>Vehicle</legend>
                            <select value={this.state.selectedVehicle} onChange={(event) => this.selectedVehicleChanged(event)}>
                                <option value="-1">Unassigned</option>
                                {vehicleOptions}
                            </select>
                        </fieldset>
                    </div>
                    <div className="save-area">
                        <a className={'go-button' + (this.state.isSaving ? ' clicked' : '')} onClick={(event) => this.saveClicked(event)}> Save</a>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}