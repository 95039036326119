import React, { Component } from 'react';
import './Login.scss';

export default class LoginPresentation extends Component {

    state = {
        username: localStorage.getItem('username') || '',
        password: '',
        rememberUsername: false
    }

    handleInputChange = (event) => this.setState({ [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.username, this.state.password, this.state.rememberUsername);
    }

    render() {        
        return (
            <div className="login">
                <h1>WELCOME</h1>
                <form className="login-form" onSubmit={this.onSubmit}>
                    <div className="login-container">
                        <div className="username-row">
                            <label htmlFor="username">Username:</label>
                            <input tabIndex={1} type="text" id="username" name="username" autoFocus value={this.state.userName} onChange={this.handleInputChange} />
                        </div>
                        <div className="remember-username-row">
                            <label><input tabIndex={3} type="checkbox" name="rememberUsername" checked={this.state.rememberUsername} onChange={this.handleInputChange} />Remember My Username</label>
                        </div>
                        <div className="password-row" style={{ visibility: this.state.username.indexOf('@mapstrat') !== -1 ? 'hidden' : 'visible'}}>
                            <label htmlFor="userName">Password:</label>
                            <input tabIndex={2} type="password" id="password" name="password" value={this.state.password} onChange={this.handleInputChange} />
                        </div>
                        <p>
                            <button tabIndex={4} className="mimic-link" type="button" onClick={this.props.onForgotPassword}>Forgot Username / Password?</button>                            
                        </p>
                        <p>
                            <label className="login-error" style={{ visibility: (this.props.errorMessage && this.props.errorMessage.length > 0 ? 'none' : '') }}> {this.props.errorMessage} </label>
                        </p>
                    </div>
                    <button tabIndex={5} type="submit" className="green" disabled={this.props.inProgress? "disabled":""}>Login</button>
                </form>
            </div>
        );
    }
}