import 'react-app-polyfill/ie11';
import './OfflineFunctinality';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import store from './reducers/Index';


//Initialize JavaScript error tracking with Sentry unless we're running on localhost
if (window.location.host.indexOf('localhost') !== 0) {
    Sentry.init({
        dsn: 'https://d19cbee58793409f8628635f766908e5@sentry.io/1476573'
    });
}

const rootElement = document.getElementById('root');

unregister();

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
rootElement);
////Commented out the service worker because it prevents to get the current directory
//// For an example,if you enter nanaimo.dev.mapstrat.com/current, it redirects to login 
//// page instead of showing /current directory files
//registerServiceWorker();
