import $ from 'jquery';
import React, { useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';

export default ({
    routes,
    vehicleOptionChange,
    inputChange,
    alertSelectedRoutes,
    alertData }) => {

    const [selectedRoutes, setSelectedRoutes] = useState(alertSelectedRoutes || []);
    const [allRoutes, setAllRoutes] = useState(alertSelectedRoutes ? (alertSelectedRoutes.length === routes.length) : false);

    const updateAllRoutesCheckbox = e => {
        let _selectedRoutes = selectedRoutes.slice(0);
        let _selectedRoute = parseInt(e.target.value);
        if (e.target.checked) {
            if (_selectedRoutes.includes(_selectedRoute)) return;
            _selectedRoutes.push(_selectedRoute);
            setSelectedRoutes(_selectedRoutes);
        }
        else {

            if (!_selectedRoutes.includes(_selectedRoute)) return;

            let index = _selectedRoutes.findIndex(r => r == e.target.value);
            _selectedRoutes.splice(index, 1);
            setSelectedRoutes(_selectedRoutes);
        }

        setAllRoutes(_selectedRoutes.length === routes.length);
        let eventObject = {
            target: {
                name: 'selectedRoutes',
                value: _selectedRoutes
            }
        };
        inputChange(eventObject);
    }

    const allRoutesClicked = e => {
        let _selectedRoutes = [];
        setAllRoutes(e.target.checked);
        if (!e.target.checked) return setSelectedRoutes([]);
        let _allSelectedRoutes = routes.map(r => r.routeNumber);
        setSelectedRoutes(_allSelectedRoutes);       
    }
    return (
        <div id="thresholdConfig">
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Threshold:</label>
                </div>
                <div className="k-edit-field alert-thresholds">
                    <p>
                        <label>
                            Alert Start:
                               <NumericTextBox
                                min={0}
                                className="numeric-textbox"
                                value={alertData.startThreshold}
                                onChange={inputChange}
                                style={{ width: '150px', marginLeft: '2px', marginRight:'2px' }}
                                name="startThreshold" />
                             minutes
                         </label>
                    </p>
                    <p>
                        <label>
                            Alert Clear:
                           <NumericTextBox
                                min={0}
                                onChange={inputChange}
                                className="numeric-textbox"
                                value={alertData.clearThreshold}
                                style={{ width: '150px', marginLeft: '2px', marginRight: '2px' }}
                                name="clearThreshold" />
                            minutes
                        </label>
                    </p>
                </div>
            </div>
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Routes:</label>
                </div>
                <div className="k-edit-field">
                    <ul className="routes">
                        {routes
                            && routes.length > 0
                            && routes.map(r =>
                                <li key={r.routeNumber}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="route-checkbox"
                                            value={r.routeNumber}
                                            checked={selectedRoutes.includes(r.routeNumber)}
                                            onChange={updateAllRoutesCheckbox} />
                                        {r.routeName}
                                    </label>
                                </li>)
                        }
                    </ul>
                    <label>
                        <input
                            type="checkbox"
                            id="allRoutesCheckbox"
                            checked={allRoutes}
                            onChange={allRoutesClicked} />
                                All Routes
                     </label>
                </div>
            </div>
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Vehicles:</label>
                </div>
                <div className="k-edit-field">
                    <label>
                        Any
                        <input
                            type="radio"
                            name="anyAllVehicles"
                            value="Any"
                            checked={alertData ? alertData.anyAllVehicles === 'Any' : false}
                            onChange={inputChange} />
                    </label>
                    <label>
                        All
                        <input
                            type="radio"
                            name="anyAllVehicles"
                            value="All"
                            checked={alertData ? alertData.anyAllVehicles === 'All' : false}
                            onChange={inputChange} />
                    </label>
                </div>
            </div>
        </div>);
}