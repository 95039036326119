import React, { useState, useRef, useEffect } from 'react';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import SMIGrid from '../SMIGrid/SMIGrid';
import EditColumn from '../SMIGrid/SMIActionColumn';
import { Enum } from '../SMIGrid/GridCustomTemplates';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import AddAlertImage from '../../images/add-alert.png';

const activeFilter = {
    logic: "and",
    filters: [
        {
            field: "isActive",
            operator: "eq",
            value: 'Active',
        },
    ],
};

const inActiveFilter = {
    logic: "and",
    filters: [
        {
            field: "isActive",
            operator: "eq",
            value: 'InActive',
        },
    ],
};
export default ({ data,
    onSubscriptionChange,
    defaultSubscriptionType,
    onEdit,
    isReload,
    dataLoaded}) => {
    const gridRef = useRef();
    useEffect(() => {
        if (gridRef.current)
            gridRef.current.setGridFilter(activeFilter);
    }, []);

    useEffect(() => {
        if (gridRef.current && isReload)
            gridRef.current.loadData();
    }, [isReload]);

    const alertStatusChange = e => {
        console.log(e);
        let filters = null;
        if (e.value === 'Active')
            filters = activeFilter;
        else if (e.value === 'InActive')
            filters = inActiveFilter;

        if (gridRef.current)
            gridRef.current.setGridFilter(filters);
    }

    const valueRender = (element, value) => {
        if (!value) {
            return element;
        }
        const children = [
            <span key={1} >
                <img key={1} src={AddAlertImage} />
            </span>,
            <span key={3}>&nbsp; {element.props.children}</span>,
        ];
        return React.cloneElement(element, { ...element.props }, children);
    };

    

    return (
        <div id="myAlert">
            <div className="top-section">
                <RadioGroup
                    className="tab-options"
                    defaultValue={data.alertStatus[0].value}
                    data={data.alertStatus}
                    layout={"horizontal"}
                    onChange={alertStatusChange} />
                <DropDownList
                    className="alert-type-dropdown"
                    defaultValue={defaultSubscriptionType}
                    data={data.subscriptionTypes}
                    value={data.selectedSubscriptionType}
                    valueRender={valueRender}
                    dataItemKey="subscriptionTypeID"
                    textField="subscriptionTypeName"
                    onChange={onSubscriptionChange} />
            </div>
            <div id="grid-section">
                <SMIGrid
                    ref={gridRef}
                    crudUrl={'/api/MyAlerts'}
                    filterable={false}
                    editable={false}
                    onDataLoaded={dataLoaded}
                >
                    <Column
                        title="Alert"
                        field="subscriptionName"
                    />
                    <Column
                        width={250}
                        title="Alert Type"
                        field="subscriptionType"
                        customTemplate={Enum(data.subscriptionTypes, 'subscriptionTypeID', 'subscriptionTypeName')}
                    />
                    <Column
                        width={150}
                        title="Status"
                        field="isActive"
                    />
                    <EditColumn width={80} className="k-grid-edit" onClick={onEdit } title=" " />
                </SMIGrid>
            </div>
        </div>
    );
};