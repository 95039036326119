import React, {useState, useEffect} from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../reducers/Map';

//#region vehicleIcon

const defaultVehicleIconBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wcRFQYXul2ragAAAZRJREFUSMftlc8rw2Ecx1/P98d+EWUXZTVrNQecFGscXGRYlIOWnF2UqPkTpJj4E5wc3FZIuUiSqd1IDlqKUnLRZu27Xw48a1iMbTfv03N5v97P836eT49IpgwAQpsHZ0exeCfAczLdyB/U1GBOAAz2uC7DC34vgJhdjgBE906ue6mhxgY6zoE+BYhSP0WFZ3LzBSCZMqy6pgKwOO0DYGqoC4BWe/nGHp4SAOwcXgCwsX0KQCabo8FqSgFoyZRhlYbQTD8Ac1N9FW1PBs8HvQCoigBgZesYyVU+myqFl1M574eA4HB31aV/ZhQDLCYNe7Ot6gB7sw2LSfsaoOtqzZ5OKUuhzvoP+FGibWS18F/Rd9JMupoBMDI5/SayiFl/GxLH6NqvQHf7SwCkM1ncExtIriKEyAsh8gBmXUtXu2PJkFzhGl835AlqWU3xBHW/A7ej5R7gKv7YXkuw5IpC4W0MnIFwNpcvqO8fRw7gdjekVQJzBsJZgFK/9H5XkfabJn6cA7/PEytd+32eiul+n+eLX65fAQUGc75Aq8MZAAAAAElFTkSuQmCC";
const VehicleBubbleSvgWithoutBearing = `
		<?xml version="1.0" encoding="UTF-8" standalone="no"?>
		<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
		   width="{{SIZE}}" height="{{SIZE}}" id="svg2">
		  <defs id="defs4">			
			<radialGradient cx="75.625" cy="25" r="25" fx="75.625" fy="25" id="radialGradient3831" 
			   xlink:href="#linearGradient3825" gradientUnits="userSpaceOnUse" gradientTransform="translate(-50.625,0)" />
		  </defs>
		  <g transform="scale({{SCALE}})"> 
			  <g id="g">
				<path d="M 25,0 C 11.192881,0 0,11.192881 0,25 0,38.807119 11.192881,50 25,50 38.807119,50 50,38.807119 50,25 50,11.192881 38.807119,0 25,0 z m 0,6 C 35.49341,6 44,14.50659 44,25 44,35.49341 35.49341,44 25,44 14.50659,44 6,35.49341 6,25 6,14.50659 14.50659,6 25,6 z" id="path3769" style="fill:url(#radialGradient3831);fill-opacity:1;fill-rule:nonzero;stroke:none" />				
				<path id="internalCircle" d="m 46.669046,29.14035 a 19.091883,19.622211 0 1 1 -38.183765,0 19.091883,19.622211 0 1 1 38.183765,0 z" 
				  transform="matrix(0.95554973,0,0,0.92972412,-1.3513513,-2.0924866)" 
				  fill="{{COLOR}}" style="fill-opacity:0.5;stroke:#000000;stroke-width:1;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
			  </g>
		  <image id="embedImg" display="block" xlink:href="{{ICONDATAURL}}" x="12" y="12" height="25px" width="25px"/>
		  </g> 
		</svg>
		`.trim();

const VehicleBubbleSvg = `
		<?xml version="1.0" encoding="UTF-8" standalone="no"?>
		<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
		   width="{{SIZE}}" height="{{SIZE}}" id="svg2">
		  <defs id="defs4">			
			<radialGradient cx="75.625" cy="25" r="25" fx="75.625" fy="25" id="radialGradient3831" 
			   xlink:href="#linearGradient3825" gradientUnits="userSpaceOnUse" gradientTransform="translate(-50.625,0)" />
		  </defs>
		  <g transform="scale({{SCALE}})"> 
			  <g id="g"  transform="rotate({{ROTATE}},25,25)">
				<path d="M 25,0 C 11.192881,0 0,11.192881 0,25 0,38.807119 11.192881,50 25,50 38.807119,50 50,38.807119 50,25 50,11.192881 38.807119,0 25,0 z m 0,6 C 35.49341,6 44,14.50659 44,25 44,35.49341 35.49341,44 25,44 14.50659,44 6,35.49341 6,25 6,14.50659 14.50659,6 25,6 z" id="path3769" style="fill:url(#radialGradient3831);fill-opacity:1;fill-rule:nonzero;stroke:none" />
				<path id="pointer" display="block" d="M 25,0.03125 19.65625,7.8125 C 21.34715,7.2865754 23.136039,7 25,7 c 1.863961,0 3.65285,0.2865754 5.34375,0.8125 L 25,0.03125 z" style="fill:#000000;fill-opacity:1;stroke:none" />
				<path id="internalCircle" d="m 46.669046,29.14035 a 19.091883,19.622211 0 1 1 -38.183765,0 19.091883,19.622211 0 1 1 38.183765,0 z" 
				  transform="matrix(0.95554973,0,0,0.92972412,-1.3513513,-2.0924866)" 
				  fill="{{COLOR}}" style="fill-opacity:0.5;stroke:#000000;stroke-width:1;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
			  </g>
		  <image id="embedImg" display="block" xlink:href="{{ICONDATAURL}}" x="12" y="12" height="25px" width="25px"/>
		  </g> 
		</svg>
		`.trim();
//#endregion

const vehicleRef = React.createRef();

const areEqual = (prevProps, nextProps) => {    
    if (vehicleRef.current && nextProps.vehiclePosition) {
        vehicleRef.current.leafletElement.setLatLng([nextProps.vehiclePosition.Lat, nextProps.vehiclePosition.Lng]);
        return true;
    }    
    return false;
}

export const VehicleMarkerUnconnected = React.memo((props) => {
    const { dispatch } = props;
    const mapActions = bindActionCreators(actions, dispatch);
    const vehiclePosition = props.vehiclePosition;
    const [vMarker, setMarker] = useState(null);    

    const getVehicleIcon = (bearing, color, iconUrl, zoomLevel) => {
        let scale = 1;
        iconUrl = iconUrl || defaultVehicleIconBase64;
        var actualSize = scale * 50;
        let svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="41" viewBox="0 0 40 41">';
        svg += `<image xlink:href="${iconUrl}" x="11" y="12" width="18" height="18" />`;
        svg += '</svg>';

        var vehicleSvg = (bearing ? VehicleBubbleSvg : VehicleBubbleSvgWithoutBearing)
            .replace("{{ROTATE}}", bearing)
            .replace("{{COLOR}}", color)
            .replace("{{SCALE}}", scale + "")
            .replace("{{ICONDATAURL}}", iconUrl)
            .replace(/{{SIZE}}/g, actualSize + "");

        return L.divIcon({
            html: vehicleSvg,
            iconAnchor: [20, 20]
        });
    }
        
    if (!vehiclePosition || vehicleRef.current) return null;    
    return (
        <Marker position={[vehiclePosition.Lat, vehiclePosition.Lng]}
            zIndexOffset={1000} icon={getVehicleIcon(vehiclePosition.Br, "FFFFFF")} ref={vehicleRef}>
            {props.showVehiclePopup && props.vehiclePopUpContent && (<Popup>
                {props.vehiclePopUpContent}
            </Popup>)}
        </Marker>
    );
}, areEqual);

export default connect(state => {
    return {
        vehiclePosition: state.mapReducer ? state.mapReducer.VehiclePosition : null,
        vehiclePopUpContent: null,
        showVehiclePopup: true
    }
})(VehicleMarkerUnconnected);