import React, { useMemo } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import SMIGridSearch from './SMIGridSearch';
import LoadingPanel from './LoadingPanel';
import SMIColumnMenu from './SMIColumnMenu';
import { getGuid } from '../../Utils/Util';

export default (props) => {
    const identifier = useMemo(() => getGuid(), []);

    const gridProps = Object.assign({}, props.gridProps);
    gridProps.data = props.data;
    gridProps.onDataStateChange = props.onDataStateChange;    
    if (gridProps.editable !== false) {
        gridProps.onItemChange = props.onItemChange;
        gridProps.editField = "inEdit";
        gridProps.cellRender = props.renderers.cellRender;
        gridProps.rowRender = gridProps.rowRender ? gridProps.rowRender : props.renderers.rowRender;
    }
    else {
        gridProps.cellRender = props.renderers.cellRender;
        gridProps.rowRender = gridProps.rowRender ? gridProps.rowRender : props.renderers.rowRender;
    }

    if (gridProps.groupable) {
        gridProps.expandField = '_expanded';
        gridProps.onExpandChange = props.onExpandChange;
    }

    const addLabel = 'Add New ' + (gridProps.dataName || '');

    //Filter out hidden columns
    let children = props.children.filter((c) => {
        if (!c) return false;
        if (c.props.field) {
            const columnMenuEntry = props.columns.filter((col) => col.field === c.props.field);
            return columnMenuEntry.length === 0 || columnMenuEntry[0].show;
        }
        return true;
    });

    if (gridProps.showColumnMenu) {
        children = children.map((c, i) => {
            //Don't add column menu to columns with fields starting with "_" as these are action columns (e.g. the delete button)
            if (c.props.field && c.props.field.indexOf('_') !== 0 && !c.props.hideColumnMenu) {
                const copyProps = Object.assign({}, c.props);
                let width;
                if (copyProps.width) {
                    width = copyProps.width;
                    delete copyProps.width;
                }
                //If at least one column in the grid has a width prop (e.g. the delete button column) then all columns must have a width prop, even if it's just width={undefined} or columns can appear squished when they're hidden and then shown using the column menu
                return <Column {...copyProps} key={i} width={width} columnMenu={(columnMenuProps) => <SMIColumnMenu {...columnMenuProps} columns={props.columns} gridRef={props.gridRef} onColumnsSubmit={props.onColumnsSubmit} />} />
            }
            return c;
        });
    }
    
    return (
        <div onKeyDown={props.onKeyDown} id={identifier} style={{ height:'100%' }}>
            <div className="grid-toolbar silverlight-button-zone">
                {gridProps.editable && <Button onClick={props.onAdd} className="k-add-new"><span className="k-icon k-i-plus"></span> {addLabel}</Button>}
                {gridProps.editable && <Button onClick={gridProps.addSaveEvent ? props.onSave : undefined} className="k-save" disabled={props.saving}><span className="k-icon k-i-check"></span> Save</Button>}
                {gridProps.editable && <Button onClick={props.onCancelChanges} className="k-cancel"><span className="k-icon k-i-cancel"></span> Cancel Changes</Button>}
                {gridProps.allowExcelExport && <Button onClick={props.onExcelExport} className="k-export"><span className="k-icon k-i-custom"></span> Export to Excel</Button>}
                {gridProps.toolbarButtons}
                {props.showSavedMessage && <span className="inline-success" style={{ display: 'inline' }}>Saved</span>}
                {props.showErrorMessage && <span className="inline-error" style={{ display: 'inline' }}>{props.errorMessage}</span>}
                {gridProps.searchable && <SMIGridSearch onSubmit={props.onSearch} />}
            </div>
            <Grid {...gridProps} {...props.dataState} onRowClick={ props.onRowClick} skip={gridProps.skip || props.dataState.skip} ref={props.gridRef}>
                {children}
            </Grid>
            {props.isLoading && <LoadingPanel identifier={identifier} />}
            <ExcelExport ref={props.excelExportRef} />
        </div>
    )
}