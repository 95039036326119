import React from 'react';

import './RecipientPresentation.scss';
import MSGRecipientType from './MSGRecipientType';

export default ({ recipient, onRemoveRecipient }) => (
    <span className={"recipient-presentation recipient-" + recipient.type.toLowerCase()}>
        {recipient.text} {hasChildrenCount(recipient) ? `(${countChildren(recipient)})` : ''}
        <button onClick={() => onRemoveRecipient(recipient)}>
            <svg width="16" height="16" viewBox="0 0 496 496" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle cx="248" cy="248" r="248" />
                <path fill="#ffffff" stroke="#ffffff" strokeWidth="10" d="M277.042,248.082l72.528-84.196c7.91-9.182,6.876-23.041-2.31-30.951
	                c-9.172-7.904-23.032-6.876-30.947,2.306l-68.236,79.212l-68.229-79.212c-7.91-9.188-21.771-10.216-30.954-2.306
	                c-9.186,7.91-10.214,21.77-2.304,30.951l72.522,84.196l-72.522,84.192c-7.91,9.182-6.882,23.041,2.304,30.951
	                c4.143,3.569,9.241,5.318,14.316,5.318c6.161,0,12.294-2.586,16.638-7.624l68.229-79.212l68.236,79.212
	                c4.338,5.041,10.47,7.624,16.637,7.624c5.069,0,10.168-1.749,14.311-5.318c9.186-7.91,10.22-21.77,2.31-30.951L277.042,248.082z" />
            </svg>
        </button>
    </span>
);

function hasChildrenCount(recipient) {
    return recipient.type === MSGRecipientType.VehicleClass || recipient.id <= 0;
}

function countChildren(recipient) {
    let childrenCount = 0;
    if (!recipient.items || recipient.items.length === 0) return childrenCount;
    recipient.items.forEach((r) => {
        if (r.type === 'VehicleClass') childrenCount += countChildren(r);
        else childrenCount++;
    })
    return childrenCount;
}