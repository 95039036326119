import './UploadIncidentAttachment.scss';

import * as $ from 'jquery';
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import query from 'query-string';

export default () => {
    const [state, setValue] = useState({
        IsValidLink: false,
        IncidentId: -1,
        IncidentType: '',
        key: '',
        isLoaded: false,
        files: null,
        isFileUploading: false
    });

    useEffect(() => {
        var params = query.parse(window.location.search);        
        if (params) {
            axios.get(`/api/UploadLinkValidate?key=${params.key}&incidentId=${params.incidentId}`).then(res => {                
                setValue({ ...state, IsValidLink: res.data.isValidLink, isLoaded:true, key: params.key, IncidentId: params.incidentId, IncidentType: res.data.incidentType });
            }).catch(err => {
                console.log(err.message);
                setValue({ ...state, isLoaded: true });
            });
        }
    }, []);

    const openFileSelector = (e) => {
        e.preventDefault();
        $('#fileNameField').blur();
        if (!state.isFileUploading) $('#fileUpload').click();        
    }

    const onChange = e => {        
        var file = e.target.files[0];
        if (!file) return;
        setValue({ ...state, files: e.target.files });
        $('#fileNameField').val(file.name);
        $('#uploadButton').show();
    }

    const uploadFiles = (e) => {
        e.preventDefault();
        var file = state.files[0];
        if (!file) return;
        setValue({ ...state, isFileUploading: true });        
        var formdata = new FormData();
        formdata.append('file', file);
        $('#uploadButton').addClass('clicked');
        $('#uploadButton').show();

        axios.post(`/api/IncidentAttachment?incidentId=${state.IncidentId}&key=${state.key}`, formdata).then(res => {            
            setValue({ ...state, isFileUploading: false });
            $('#uploadButton').removeClass('clicked').hide();
            $('#fileNameField').val('');
            $('#uploadSuccess').show().delay(2400).fadeOut(800);
        }).catch(err => {
            console.log(err.message);
            setValue({ ...state, isFileUploading: false });
            $('#uploadButton').removeClass('clicked').hide();
        });
    }
    return (
        <div className="content">
            {state.IsValidLink && (
                <Fragment>
                    <h2 className="center">Upload Incident Attachment</h2>
                    <p className="center">Incident {state.IncidentId}: {state.IncidentType}</p>
                    <div className="center">
                        <input type="text" id="fileNameField" placeholder="Press To Upload File" onFocus={openFileSelector} />
                        <input type="file" id="fileUpload" name="fileUpload" onChange={(e) => onChange(e)} style={{ display: "none" }} />
                        <iframe id="uploadTarget" name="uploadTarget" src="about:blank" style={{ display: "none" }}></iframe>
                    </div>
                    <a id="uploadButton" style={{ display: 'none' }} onClick={uploadFiles}>Upload</a>
                    <div id="uploadSuccess" className="center">
                        <span id="successMessage">File Uploaded</span>
                    </div>
                </Fragment>
            )}
            {!state.IsValidLink && state.isLoaded && (
                < h3 className="center" id="expiredMessage">Sorry, your attachment upload link has expired</h3>)
            }
        </div>
    );
}