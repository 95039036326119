import './MessageInbox.scss';

import $ from 'jquery';
import axios from 'axios';
import React, { useState, useContext, useEffect, Fragment, useRef } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

import SMIGrid from '../SMIGrid/SMIGrid';
import { GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import * as GridCustomTemplates from '../SMIGrid/GridCustomTemplates';
import * as GridCustomFilters from '../SMIGrid/GridCustomFilters';
import SMIActionColumn from '../SMIGrid/SMIActionColumn';
import PageWrapper from '../PageWrapper/PageWrapper';
import GridDetails from './DetailComponent';
import { setInterval, clearInterval, setTimeout } from 'timers';
import { getDateFromISO, formatDateTimeAsSetting, convertToUTC } from '../../Utils/DateUtil';
import SendMessage from '../SendMessage/SendMessage';
import MSGRecipientType from '../SendMessage/MSGRecipientType';
import Util from '../../Utils/Util';
import { SystemSettingContext } from '../../SystemSettingContext';
import { formatDate } from '@telerik/kendo-intl';
import * as moment from 'moment';


export let allVehicles;
export default ({ onClose, onSendMessage }) => {
    const gridRef = useRef();
    const systemSettings = useContext(SystemSettingContext);
    const [currentRenderTime, SetRenderTime] = useState(null);
    const [unReadMessage, SetUnReadMessage] = useState([]);
    const [interval, SetIntervalObj] = useState(null);
    const lastReadTime = useRef(null);
    const [vehicles, SetVehicles] = useState(null);
    const [expanded, SetExpanded] = useState([]);    
    const [state, SetValue] = useState({
        showSendMessage: false,
        replyTo: null,
        preselectedRecipients: [],
    });


    useEffect(() => {
        let _interval = setInterval(() => {
            if (gridRef.current) {
                axios.get('/api/MILastUpdate').then(response => {
                    let lastUpdate = response.data;
                    lastUpdate = getDateFromISO(lastUpdate);
                    if (lastUpdate > lastReadTime.current)
                        gridRef.current.loadData();

                }).catch(err => console.log('Error loading last update'));

                SetIntervalObj(_interval);
            }
        }, 30000);

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [gridRef.current]);

    useEffect(() => {
        axios.get('/api/MSGVehicle').then(response => {
            SetVehicles(response.data);
            allVehicles = response.data;
        }).catch(err => console.log('Error loading vehicles'));

        setTimeout(() => {
            $('#searchButton').click(e => {
                e.preventDefault();
                let searchStr = $('#searchQuery').val();
                textSearch(searchStr);
                //GridHelper.bindNoDataInGrid('#grid', 'No search results found');
                return false;
            });

            $('#searchQuery').bind('keypress', function (e) {
                //Click button on enter key press
                if (e.keyCode == 13) {
                    $('#searchButton').click();
                }
            });
        }, 500);

    }, [])

    const textSearch = (searchString) => {

        searchString = searchString.toLowerCase();
        var $rows = $(".smi-grid").find('tbody tr');
        
        if (Util.isNullOrWhitespace(searchString)) {
            $rows.show();
        }
        else {
            $rows.each(function () {
                var hasMatch = false;
                $(this).find('td').each(function () {
                    var cellText = $(this).text();
                    cellText = cellText.toLowerCase();
                    if (cellText.indexOf(searchString) !== -1) {
                        hasMatch = true;
                        return false;
                    }
                });
                if (hasMatch) $(this).show();
                else $(this).hide();
            });
        }
    }
    const sendMessage = (e) => {
        e.preventDefault();
        SetValue({ ...state, showSendMessage: true });
    }

    const expandChange = event => {
        event.dataItem.expanded = event.value;
        SetRenderTime(new Date());

        if (event.value) {
            if (expanded.indexOf(event.dataItem.id) === -1) {
                let _expanded = expanded.slice(0);
                _expanded.push(event.dataItem.id);
                SetExpanded(_expanded);
            }
        } else {
            if (expanded.indexOf(event.dataItem.id) === -1) {
                let _expanded = expanded.slice(0);
                _expanded.splice(expanded.indexOf(event.dataItem.id), 1);
                SetExpanded(_expanded);
            }
        }

        if (!event.value || (event.dataItem.children && event.dataItem.children.length < 1)) {
            return;
        }

        let dataItem = event.dataItem;
        if (dataItem && dataItem.children && dataItem.children.length > 0) {
            let unreadMessages = [];
            for (var i = 0; i < dataItem.children.length; i++) {
                if (dataItem.children[i].isUnread) {
                    dataItem.children[i].isUnread = false;
                    unreadMessages.push(dataItem.children[i].id);
                }
            }
            markRead(unreadMessages);
        }

        
    }

    const convertDateStringToDate = dateString => {
        dateString = dateString.replace(/-/g, '/');  // replaces all occurances of "-" with "/"
        var dateObject = new Date(dateString);
        return dateObject;
    }

    const onDataLoaded = data => {        
        if (data && data.length > 0) {
            let unreadMessage = [];            
            for (let i = 0; i < data.length; i++) {
                if (data[i].isUnread) {
                    data[i].isUnread = false;
                    if (unreadMessage.indexOf(data[i].id) === -1)
                        unreadMessage.push(data[i].id)
                }
                
                data[i].strTime = convertDateStringToDate(data[i].strTime);
                
                if (data[i].children && data[i].children.length > 0) {
                    for (let j = 0; j < data[i].children.length; j++) {
                        data[i].children[j].time = formatDateTimeAsSetting(data[i].children[j].strTime, "datetime", systemSettings);
                    }
                }

                if (expanded && expanded.length > 0) {
                    if (expanded.indexOf(data[i].id) !== -1)
                        data[i].expanded = true;
                }
            }

            if (unreadMessage && unreadMessage.length > 0) {
                markRead(unreadMessage);
            }
        }        
        let now = new Date();
        var isoDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);        
        lastReadTime.current = isoDate;
    }

    const replyHandler = (e, props) => {
        let dataItem = props.dataItem;
        let vehicleName = "";
        if (vehicles) {
            let vehicle = vehicles.find(v => v.id === dataItem.vehicleId);
            vehicleName = vehicle ? vehicle.name : '';
        }
        let vehicleIds = [{ id: dataItem.vehicleId, type: MSGRecipientType.Vehicle, text: vehicleName }];
        SetValue({ ...state, showSendMessage: true, replyTo: dataItem.id, preselectedRecipients: vehicleIds });
    }

    const replyCell = props => {
        return (<td><button className="k-grid-reply" onClick={e => replyHandler(e, props)}></button></td>);
    }

    const dataRender = (trElement, props) => {        
        const isNoChildren = !props.dataItem.children || props.dataItem.children.length === 0;
        let className = " ";
        if (isNoChildren) className += " no-replies";
        if (props.dataItem.markUnread) className += " is-unread";
        const trProps = { className: className };        
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const markRead = (ids) => {
        if (ids && ids.length > 0) {
            var messageDeleteIds = ids.map(id => ({ Id: id }));
            axios.post('/api/MessageInbox', messageDeleteIds).then(response => {
                console.log('read data');
            }).catch(err => console.log('error deleting data'));
        }
    }

    const onSendMessageClose = e => {
        SetValue({ ...state, showSendMessage: false });
    }

    const controls = [<div id="searchArea" key={0}>
        <input type="text" id="searchQuery" style={{ width: "822.8px" }} />
        <a href="" id="searchButton"></a>
    </div>,
    <div id="newMessageArea" key={1}>
        <a className="k-button k-button-icontext k-grid-send-message" href="" onClick={sendMessage}>
            <span className="k-icon k-send"></span>Send Message</a>
    </div>];

    if (Util.isNullOrUndefined(systemSettings)) return null;

    return (
        <div id="messageInbox">
            <Dialog title="Inbox" width="1200px" height="800px" onClose={onClose} style={{ zIndex: 100 }}>
                <Fragment>
                    <SMIGrid ref={gridRef} id="grid" crudUrl="/api/MessageInbox" editable={false}
                        toolbarButtons={controls} rowRender={dataRender}
                        detail={GridDetails} expandField="expanded" onExpandChange={expandChange}
                        onDataLoaded={onDataLoaded}>
                        <Column field="recipient" title="Recipient" width={150} />
                        <Column field="sentBy" title="Sent By" width={150} />
                        <Column field="strTime" width={230} customTemplate={GridCustomTemplates.DateTime(systemSettings.shortDateFormat, systemSettings.shortTimeFormat)}
                            title="Time" filterCell={GridCustomFilters.DateTimeRange(systemSettings.shortDateFormat, systemSettings.shortTimeFormat)} />
                        <Column field="message" title="Message" />
                        <Column field="yesNo" title="Yes/No" filterable={false} width={85} />
                        <Column className="k-grid-login" title="Reply" filterable={false} width={75} cell={replyCell} />
                    </SMIGrid>
                    {state.showSendMessage && <div className="send-message">
                        <Dialog title="Send Message" width={1120} height={600} style={{ zIndex: 200 }} onClose={onSendMessageClose}>
                            <SendMessage preselectedRecipients={state.preselectedRecipients} replyTo={state.replyTo} onClose={onSendMessageClose} />
                        </Dialog>
                    </div>
                    }
                </Fragment>
            </Dialog>
        </div>
    );
}

