import React from 'react';
import { getSelectedScheduleLabel, selectedScheduleIsInPast } from '../../Utils/ScheduleUtil';

import viewIcon from '../../images/view.png';
import maintainIcon from '../../images/maintain.png';
import systemIcon from '../../images/system.png';
import reportIcon from '../../images/reports.png';

import './PageWrapper.scss';



export default ({ className, pageIcon, title, isSchedulePage, pastIsReadOnly, children }) => {
    if (!className) className = '';
    let scheduleLabelElement = null;
    let pastScheduleLabel = null;
    if (isSchedulePage) {
        scheduleLabelElement = <span className="current-schedule">{getSelectedScheduleLabel()}</span>        
        if (pastIsReadOnly !== false && selectedScheduleIsInPast()) {
            pastScheduleLabel = <span className="readonly-message">Read-Only Past Schedule</span>
            className = (className || '') + ' read-only-page';
        }
    }

    if (!pageIcon) {
        const path = window.location.pathname.toLowerCase();
        if (path.indexOf('/reports/') === 0) pageIcon = reportIcon;
        else if (path.indexOf('/view/') === 0) pageIcon = viewIcon;
        else if (path.indexOf('/system/') === 0) pageIcon = systemIcon;
        else pageIcon = maintainIcon;
    }

    return (
        <div className={"page-wrapper " + (className || '')}>
            <div className="title-holder">
                <h1 className="title"><img src={pageIcon} alt="" /><span>{title}</span> {scheduleLabelElement} {pastScheduleLabel}</h1>
                <div className="content-holder">
                    {children}
                </div>
            </div>
        </div>
    );
}

