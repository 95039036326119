import React, { Component } from 'react';
import axios from 'axios';

import { sortNaturalAlphaNumeric } from "../../../../GridHelper";

import { DropDownList } from '@progress/kendo-react-dropdowns';
import $ from 'jquery';
import './ScheduleEdit.scss';
import { convertScheduleDate } from '../../../../Utils/DateUtil';

export default class ScheduleEdit extends Component {
    storedSchedule = localStorage.getItem('smiPortalSchedule');

    state = {
        scheduleEditList: [],
        selectedSchedule: {}
    }

    constructor(props) {
        super(props);

        this.setCurrentSchedule = this.setCurrentSchedule.bind(this);
        this.selectActiveSchedule = this.selectActiveSchedule.bind(this);
        this.setScheduleOnDialog = this.setScheduleOnDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.findActiveScheduleIndex = this.findActiveScheduleIndex.bind(this);
    }


    itemRender = (li, itemProps) => {
        const startEndDates = convertScheduleDate(itemProps.dataItem.startDate) + " " + "to" + " " + convertScheduleDate(itemProps.dataItem.endDate);
        const itemChildren = <span>{itemProps.dataItem.name}   <span  className="dropdownListItem"> <br /> {startEndDates} </span> </span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    componentDidMount() {

        axios.get('/api/SchApiCru').then((response) => {
            let scheduleEditData = [];

            if (response.data.length === 0) {
                let scheduleObj = {
                    scheduleID: 0,
                    name: "No schedules available",
                    startDate: new Date(),
                    endDate: new Date(),
                    externalID: '',
                    recordCreated: new Date()
                };
                scheduleEditData.unshift(scheduleObj);
            } else {
                response.data.sort((a, b) => this.date_sort_desc(a.startDate, b.startDate));
                scheduleEditData = response.data;
            }

            this.setState({ scheduleEditList: scheduleEditData });

            if (this.storedSchedule) {
                this.setState({
                    selectedSchedule: JSON.parse(this.storedSchedule)
                });
            } else {
                var index = this.findActiveScheduleIndex();
                var data = scheduleEditData[index];
                this.setState({
                    selectedSchedule: data
                });
            }
        });
    }

    setCurrentSchedule = () => {
        
        var currentScheduleItem = this.state.selectedSchedule;
        this.setScheduleOnDialog(currentScheduleItem);
        window.location.reload();
    }

    date_sort_desc(date1, date2) {
        // This is a comparison function that will result in dates being sorted in
        // DESCENDING order.
        if (new Date(date1) > new Date(date2)) return -1;
        if (new Date(date1) < new Date(date2)) return 1;
        return 0;
    }

    handleChange = (event) => {
        this.setState({
            selectedSchedule: event.target.value
        });
    }

    findActiveScheduleIndex = () => {
        var data = this.state.scheduleEditList;
        var tempDate = null;

        var s = new Date();
        s.setHours(0, 0, 0, 0);
        tempDate = new Date(s.toISOString());

        var index = -1;
        
        for (let j = 0; j < data.length; j++) {
            if (tempDate >= new Date(data[j].startDate)) {
                if (data[j].endDate && tempDate > new Date(data[j].endDate)) {
                    continue;
                }
                index = j;
                break;
            }
        }
        
        return index;
    }


    selectActiveSchedule() {
        var item = null;

        var scheduleList = this.state.scheduleEditList;

        var index = this.findActiveScheduleIndex();
        
        item = scheduleList[index];

        this.setState({ selectedSchedule: item },() => {
            this.setCurrentSchedule();
        });
        
    }

    setScheduleOnDialog(scheduleItem) {
        
        if (scheduleItem) {
            var d = new Date(scheduleItem.startDate);

            var tzo = d.getTimezoneOffset() / 60;
            var s = new Date(scheduleItem.startDate);
            s.setHours(s.getHours() - tzo);
            scheduleItem.startDate = s.toISOString();

            if (scheduleItem.endDate != null) {
                d = new Date(scheduleItem.endDate);
                tzo = d.getTimezoneOffset() / 60;
                var e = new Date(scheduleItem.endDate);
                e.setHours(e.getHours() - tzo);
                if (e) {
                    scheduleItem.endDate = e.toISOString();
                }
            }

            localStorage.setItem('smiPortalSchedule', JSON.stringify(scheduleItem));
        }
    }


    render() {

        return (
            <div id="ss-content">
                <div id="ss-main" className="divContent">
                    <div>
                        <DropDownList data={this.state.scheduleEditList} textField="name" dataItemKey="scheduleID" value={this.state.selectedSchedule} itemRender={this.itemRender} onChange={this.handleChange} style={{ backgroundColor : 'white'}} />
                        <hr />
                        <div id="ss-options">
                            <input type="button" className="k-button sd-button" id="ss-selectActive" value="Use Today's Schedule" onClick={() => { this.selectActiveSchedule() }} /><br />
                        </div>
                    </div>
                </div>

                <div id="ss-nav" className="divContent">
                    <input type="button" className="k-button nav-button" id="ss-navOk" value="OK" onClick={() => { this.setCurrentSchedule()}} />
                    <input type="button" className="k-button nav-button" id="ss-navCancel" value="Cancel" onClick={()=> this.props.onCancel()} />
                </div>
            </div>

        )

    }

}
