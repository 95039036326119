import React, { useEffect, useState, useMemo } from 'react';
import useForceUpdate from 'use-force-update';
import axios from 'axios';
import { TreeView } from '@progress/kendo-react-treeview';
import MSGRecipientType from './MSGRecipientType';
import { sortNaturalAlphaNumeric } from '../../GridHelper';
import LoadinPanel from '../LoadingMask/LoadingMask';
import LoadingMask from '../LoadingMask/LoadingMask';

export default ({ onItemSelected, includeDispatchers }) => {

    const [recipientList, setRecipientList] = useState([]);
    const [isLoading, SetLoadingState] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        SetLoadingState(true);
        axios.get('/api/MSGRecipients').then(response => {
            response.data.sort((a, b) => sortNaturalAlphaNumeric(a.text, b.text));
            if (includeDispatchers) setRecipientList(response.data);
            else setRecipientList(response.data.filter(v => v.type !== MSGRecipientType.Dispatcher));
        }).finally(() => { SetLoadingState(false); });
    }, [includeDispatchers]);

    const treeData = useMemo(() => getHierarchicalData(recipientList), [recipientList]);        
    return (
        <div className="left-column">
            <div className="recipient-heading">
                <h2>Select Recipients:</h2>
                <div className="search-area">                    
                    <input className="k-textbox" placeholder="Search Recipients" value={searchStr} onChange={(e) => setSearchStr(e.target.value)} />
                    <button className="clear-btn" onClick={() => setSearchStr('')} />
                </div>
            </div>
            <div className="message-recipients">
                {searchStr === '' && <TreeView data={treeData} expandIcons={true} item={(props) => [<i className={props.item.type.toLowerCase()} key='0'></i>, props.item.text]} onItemClick={onItemSelected} onExpandChange={(event) => { event.item.expanded = !event.item.expanded; forceUpdate(); }} />}
                {searchStr !== '' && <ul>{recipientList.filter(r => r.text.toLowerCase().indexOf(searchStr) !== -1).map((r, i) => <li key={i} onClick={() => onItemSelected({ item: r })}>{r.text}</li>)}</ul>}
            </div>
            {isLoading && <LoadingMask />}
        </div>
    );
}

function filterData(data) {    
    var r = data.filter(function (o) {        
        if (o.items) o.items = filterData(o.items);
        if (o.type === MSGRecipientType.Vehicle)
            return o;
        return o.items && o.items.length > 0;            
    });
    return r;
}

function getHierarchicalData(recipientList) {
    const hierarchicalData = [];
    let vehicleClassHierarchicalData = [];

    //Organize vehicles and vehicle classes into parent-child relationships
    const vehicleClasses = recipientList.filter(v => v.type === MSGRecipientType.VehicleClass);
    const vehicles = recipientList.filter(v => v.type === MSGRecipientType.Vehicle);
    const hierarchicalSort = vc => {
        if (!vc.parentId) vehicleClassHierarchicalData.push(vc);
        else {
            const parentClass = vehicleClasses.filter(pc => pc.id === vc.parentId)[0];
            if (!parentClass.items) parentClass.items = [vc];
            else parentClass.items.push(vc);
        }
    };
    vehicleClasses.forEach(hierarchicalSort);
    vehicles.forEach(hierarchicalSort);

    var filteredVehicleClass = filterData(vehicleClassHierarchicalData);    
    if (filteredVehicleClass && filteredVehicleClass.length > 0) {
        for (let i = 0; i < filteredVehicleClass.length; i++)
            hierarchicalData.push(filteredVehicleClass[i]);        
    }
    

    //Add routes and dispatchers to recipient list, if any
    const routes = recipientList.filter(v => v.type === MSGRecipientType.Route);
    if (routes.length > 0) hierarchicalData.push({ id: -1, type: MSGRecipientType.AllRoutes, text: 'All Routes', items: routes });
    const dispatchers = recipientList.filter(v => v.type === MSGRecipientType.Dispatcher);
    if (dispatchers.length > 0) hierarchicalData.push({ id: -1, type: MSGRecipientType.AllDispatchers, text: 'All Dispatchers', items: dispatchers });

    return hierarchicalData;
}