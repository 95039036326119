import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react';

export default ({
    zoneTypes,
    inputChange,
    driverLoggedIn,
    zoneTriggerType,
    vehicleInService,
    alertData}) => {
    return (<div>
        <div className="form-control">
            <div className="k-edit-label">
                <label>Zone Type:</label>
            </div>
            <div className="k-edit-field">
                <DropDownList
                    name="zoneType"
                    data={zoneTypes}
                    value={alertData.zoneType}
                    onChange={inputChange}
                    className="normal-alert-dropdown"
                    dataItemKey="zoneTypeID"
                    textField="zoneTypeName" />
            </div>
        </div>
        <div className="form-control">
            <div className="k-edit-label">
                <label>Trigger Type:</label>
            </div>
            <div className="k-edit-field">
                <label>In <input
                    type="radio"
                    value="1"
                    onChange={inputChange}
                    checked={zoneTriggerType == 1}
                    name="zoneTriggerType" />
                </label>
                <label>
                    Out
                      <input
                        type="radio"
                        value="2"
                        checked={zoneTriggerType == 2}
                        onChange={inputChange}
                        name="zoneTriggerType" />
                </label>
                <label>
                    Both
                       <input
                        type="radio"
                        value="3"
                        onChange={inputChange}
                        checked={zoneTriggerType == 3}
                        name="zoneTriggerType" />
                </label>
            </div>
        </div>
        <div className="form-control">
            <div className="k-edit-label">
                <label>Logged In:</label>
            </div>
            <div className="k-edit-field">
                <label>
                    Yes
                       <input
                        type="radio"
                        value="1"
                        onChange={inputChange}
                        checked={driverLoggedIn == 1}
                        name="driverLoggedIn" />
                </label>
                <label>
                    No <input
                        type="radio"
                        value="2"
                        onChange={inputChange}
                        checked={driverLoggedIn == 2}
                        name="driverLoggedIn" />
                </label>
                <label>
                    Both
                       <input
                        type="radio"
                        value="3"
                        onChange={inputChange}
                        name="driverLoggedIn"
                        checked={driverLoggedIn == 3} />
                </label>
            </div>
        </div>
        <div className="form-control">
            <div className="k-edit-label">
                <label>In Service:</label>
            </div>
            <div className="k-edit-field">
                <label>
                    Yes <input
                        type="radio"
                        value="1"
                        checked={vehicleInService == 1}
                        onChange={inputChange}
                        name="vehicleInService" />
                </label>
                <label>
                    No
                       <input
                        type="radio"
                        value="2"
                        checked={vehicleInService == 2}
                        onChange={inputChange}
                        name="vehicleInService" />
                </label>
                <label>
                    Both
                       <input
                        type="radio" value="3"
                        onChange={inputChange}
                        checked={vehicleInService == 3}
                        name="vehicleInService" />
                </label>
            </div>
        </div>
    </div>);
}