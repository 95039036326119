const ReduxActions = {
    GetRoutes: 'GetRoutes',
    GetVehicles: 'GetVehicles',
    SelectRoute: 'SelectRoute',
    SetEvents: 'SetEvents',
    ClearEvents: 'ClearEvents',
    SelectVehicle: 'SelectVehicle',
    SelectDate: 'SelectDate',
    SetTripInfos: 'SetTripInfos',
    SetVehicleHistory: 'SetVehicleHistory',        
    SelectTripInfo: 'SelectTripInfo',
    SetSelectedLogFix: 'SetSelectedLogFix',
    SetSelectedLogType: 'SetSelectedLogType',
    SetSelectedLogFile: 'SetSelectedLogFile',
    SetTimeRange: 'SetTimeRange',
    SetTripStopData: 'SetTripStopData',
    SetSelectedRoute: 'SetSelectedRoute',
    SetRoutePatterns: 'SetRoutePatterns',
    SetSelectedPattern: 'SetSelectedPattern',
    SetPatternStops: 'SetPatternStops',
    SetStopReached: 'SetStopReached',
    SetEventTrigerDistance: 'SetEventTrigerDistance',
    SetZoomToRouteStart: 'SetZoomToRouteStart',
    SetShowPattern: 'SetShowPattern',
    SetCircleMarkerPoints: 'SetCircleMarkerPoints',
    SetFilterItems: 'SetFilterItems',
    SetHighlightGreenItems: 'SetHighlightGreenItems',
    SetHighlightCyanItems: 'SetHighlightCyanItems',
    ClearMarkers: 'ClearMarkers',
    SetVehicleLogFix: 'SetVehicleLogFix',
    SetIsLoading:'SetIsLoading'
};

export default ReduxActions;