import React, { Component } from 'react';
import LoginPresentation from './LoginPresentation';
import LoginHeaderPresentation from './LoginHeaderPresentation';
import ForgotPasswordPresentation from './ForgotPasswordPresentation';
import CompatibilityTaglinePresentation from './CompatibilityTaglinePresentation';
import SMILoginPresentation from './SMILoginPresentation';
import axios from 'axios';
import queryString from 'query-string';
import Util, { getGuid } from '../../Utils/Util';

export default class Login extends Component {    

    state = {
        isSmiLogin: false,
        isForgotPassword: false,
        smiUsername: '',
        smiGuid: '',
        errorMessage: '',
        customLogoUrl: null,
        inProgress: false
    }

    counter = 0;

    componentWillMount() {
        axios.get("/api/InitialSettings").then(response => {
            var settings = response.data;
            this.setState({ customLogoUrl: settings.customLogoUrl });
        });
    }
    componentDidMount() {        
        const parsed = queryString.parse(window.location.search);

        if (parsed && parsed.ticket && parsed.username && this.counter === 0 && this.state.inProgress === false) {
            this.counter = 1;
            this.setState({ inProgress: true, errorMessage: '' });
            axios.post('/api/SMILogin/LoginUserWithTicket', { loginTicket: parsed.ticket, userName: parsed.username }).then(response => {
                if (response.data.result == 1) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    this.setState({ isSmiLogin: true });
                    this.props.onLoginSuccess();
                    this.counter = 0;
                }
                else {
                    this.setState({ errorMessage: response.data.responseMessage });
                    this.counter = 0;
                }

            }).catch((err) => {
                this.setState({ errorMessage: err.response.data.responseMessage, inProgress: false });
                console.log(err.response);
                this.counter = 0;
            })
        }

        window.addEventListener('message', this.onSmiLoginMessage);
    }

    onLoginSubmit = (username, password, rememberUsername) => {
        if (rememberUsername) localStorage.setItem('username', username);
        else localStorage.removeItem('username');
        this.setState({ inProgress: true, errorMessage: '' });
        const mapstratPos = username.indexOf('@mapstrat');
        if (mapstratPos !== -1) {
            //Load dialog for mapstrat user login
            axios.get('/api/SMILogin').then(response => {
                const guid = getGuid();
                const xmlPublicKey = response.data;
                var authenInfo = { id: guid, pubkey: xmlPublicKey };
                axios.post('https://api.mapstrat.com/api/ClientPubKey', authenInfo);
                this.setState({ isSmiLogin: true, inProgress: false, smiUsername: username.substr(0, mapstratPos + 9), smiGuid: guid });
            }).catch((err) => {
                let _errorMessage = 'Login Failed';
                if (err && err.response && err.response.data && err.response.data.responseMessage)
                    _errorMessage = err.response.data.responseMessage;

                this.setState({ errorMessage: _errorMessage, isSmiLogin: false, inProgress: false });
                console.log(err.response);                
            });
        }
        else {
            //axios call to login API
            axios.post('/api/SMILogin', { userName: username, password: password }).then((response) => {                
                if (response.data.result === 1) { // result 1 means login success
                    localStorage.setItem('user', JSON.stringify(response.data));
                    this.setState({ isSmiLogin: true });                    
                    this.props.onLoginSuccess();
                }
                else if (response.data.result === 6) //result 6 means much change password
                {
                    this.props.onChangePassword();
                }
                else {
                    this.setState({ errorMessage: response.data.responseMessage });
                }
                this.setState({ inProgress: false });
            }).catch((err) => {               
                let _errorMessage = 'Login Failed';
                if (err && err.response && err.response.data && err.response.data.responseMessage)
                    _errorMessage = err.response.data.responseMessage;

                this.setState({ errorMessage: _errorMessage, inProgress: false });
                console.log(err.response);
            });
        }
    }

    onSmiLoginMessage = (e) => {
        const data = e && e.data;
        if (data && data.message === 'SMILOGIN') {            
            axios.post('/api/SMILogin/SMILogin', { userName: this.state.smiUsername, SmiPass: data.passToken }).then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data));
                this.setState({ isSmiLogin: true });                
                this.props.onLoginSuccess();
            }).catch((err) => {
                let _errorMessage = 'Login Failed';
                if (err && err.response && err.response.data && err.response.data.responseMessage)
                    _errorMessage = err.response.data.responseMessage;

                this.setState({ errorMessage: _errorMessage, isSmiLogin: false, inProgress: false });
                console.log(err.response);
            });
        }
    }

    onForgotPassword = () => {
        this.setState({ isForgotPassword: true });
    }

    onForgotPasswordSubmit = (email) => {
        //axios call to forgot password API goes here
        this.setState({ isForgotPassword: false });
    }

    onForgotPasswordCancel = () => {
        this.setState({ isForgotPassword: false });
    }

    onSmiLoginCancel = () => {
        this.setState({ isSmiLogin: false })
    }
    
    render() {        

        if (Util.isNullOrUndefined(this.state.customLogoUrl)) return null;
        return (
            <React.Fragment>
                <LoginHeaderPresentation customLogoUrl={this.state.customLogoUrl} />                
                {this.state.isForgotPassword && <ForgotPasswordPresentation onSubmit={this.onForgotPasswordSubmit} onCancel={this.onForgotPasswordCancel} />}
                {this.state.isSmiLogin && <SMILoginPresentation onCancel={this.onSmiLoginCancel} username={this.state.smiUsername} guid={this.state.smiGuid} errorMessage={this.state.errorMessage} />}
                {!this.state.isSmiLogin && !this.state.isForgotPassword && <LoginPresentation onSubmit={this.onLoginSubmit} onForgotPassword={this.onForgotPassword} errorMessage={this.state.errorMessage} inProgress={this.state.inProgress} />}
                <CompatibilityTaglinePresentation />                
            </React.Fragment>
        );
    }
}