import React, { Component } from 'react';
import axios from 'axios';

export default class ReportEngine extends Component {

    state = {
        reportURL: '',
        isError: false
    }

    componentDidMount() {
        axios.get('/api/ReportEngine').then((response) => {
            if (response.data) {
                this.setState({ isError: false, reportURL: response.data });
            }
            else {
                this.setState({ isError: true, reportURL: '' });
            }

        }).catch(err => console.log('Report engine internal server error'));
    }

    render() {
        console.log(this.state.reportURL);
        const ReportErrorMessage = () => (
            <div id="reportEngineErrorMessage" style={{ textalign: 'center', width: '100%', height: '100%', marginLeft: '700px', marginTop: '100px' }}>
                <img src={require('../../images/smi_logo.png')} alt="" style={{ marginLeft: '120px', width: '200px' }} /><br />
                <p style={{ textalign: 'center', fontSize : 'large', fontWeight : '500' }}>Unable to load report engine. Please contact Strategic Mapping for assistance.</p>
            </div>
        )

        return (

            <div id="iframeHolder" style={{ position: 'absolute', top: 0, left: 0, overflow: 'hidden', width: '100%', zIndex: 2, height:'100%' }}>
                {!this.state.isError && <iframe src={this.state.reportURL} style={{ width: '100%', height: '100%' }}/>}
                {this.state.isError && <ReportErrorMessage style={{ width: '100%', height: '100%' }} />}
            </div>            
        )
    }
}