import Util from './Util';

const SortHelper = {
    /* Natural Sort Algorithm for column sorting. For example, rows with alphanumeric strings will show as follows:
 *  1     instead of    1        (Right side is incorrect)
 *  2       --->        2
 *  3       --->        20
 *  20      --->        3
 *  abc6      --->      abc50
 *  abc50     --->      abc6 
 */
    sortNaturalAlphaNumeric: function (a, b, field) {
        if (Util.isNullOrUndefined(a) && !Util.isNullOrUndefined(b)) return -1;
        if (Util.isNullOrUndefined(b) && !Util.isNullOrUndefined(a)) return 1;
        if (Util.isNullOrUndefined(a) && Util.isNullOrUndefined(b)) return 0;
        var ax = (field ? a[field] : a)
        var bx = (field ? b[field] : b)
        //check that its value in its field is null
        if (Util.isNullOrUndefined(ax) && !Util.isNullOrUndefined(bx)) return -1;
        if (Util.isNullOrUndefined(bx) && !Util.isNullOrUndefined(ax)) return 1;
        if (Util.isNullOrUndefined(ax) && Util.isNullOrUndefined(bx)) return 0;
        ax = ax.toLowerCase();
        bx = bx.toLowerCase();
        //Make case-insensitive
        var t = 1;
        var f = t * -1;

        for (var i = 0; i < Math.max(ax.length, bx.length); i++) {
            if (ax[i] === undefined) return f;
            else if (bx[i] === undefined) return t;
            else {
                var as = ax.substr(i), bs = bx.substr(i);
                var an = parseInt(as), bn = parseInt(bs);
                if (!isNaN(an) && !isNaN(bn)) {
                    if (an === bn) {
                        i = i + an.toString().length - 1;
                        continue;
                    }
                    return an > bn ? t : f;
                } else {
                    if (ax[i] === bx[i])
                        continue;
                    return ax[i] > bx[i] ? t : f;
                }
            }
        }
        return 0;
    },

    sortArrayNaturalAlphaNumeric: function (arr, field) {
        return arr.sort(function (a, b) {
            return SortHelper.sortNaturalAlphaNumeric(a, b, field);
        });
    }
}

export default SortHelper;