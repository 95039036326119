import './SMIActionColumn.scss';

const SMIActionColumn = () => { };
SMIActionColumn.displayName = "KendoReactGridColumn";
SMIActionColumn.defaultProps = {
    title:"Action Column",
    field: "_actionColumn",
    filterable: false,
    groupable: false    
}

export default SMIActionColumn;