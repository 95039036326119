import './NormalAlert.scss';

import React, { useRef, useEffect, useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import ZoneTypePresentation from './ZoneTypePresentation';
import ThresholdTypePresentation from './ThresholdTypePresentation';
import HardwareTypePresentation from './HardwareTypePresentation';
import PresentationContainer from '../AlertComponent/PresentationContainer';

export default (props) => {
    const alertRef = useRef();
    return (
        <div className="customEditor" ref={alertRef} >
            <PresentationContainer {...props}>
                {props.subscriptionTypeId === 1 &&
                    <div className="form-control">
                        <div className="k-edit-label">
                            <label>Alert Type:</label>
                        </div>
                        <div className="k-edit-field">
                            <DropDownList
                                width={250}
                            data={props.alertConfigData.alertLogTypes}
                                defaultItem={{ alertLogTypeID: -1, alertLogTypeName: 'Select alert type' }}
                            value={props.data.selectedAlertType}
                                className="normal-alert-dropdown"
                                name="selectedLogType"
                                dataItemKey="alertLogTypeID"
                                textField="alertLogTypeName"
                                onChange={props.actions.onAlertTypeChange}
                                popupSettings={{ width: 'auto', appendTo: alertRef.current }} />
                        </div>
                    </div>
                }
                {props.subscriptionTypeId === 5 &&
                    <ZoneTypePresentation
                    driverLoggedIn={props.data.driverLoggedIn}
                        inputChange={props.actions.inputChange}
                    vehicleInService={props.data.vehicleInService}
                    zoneTriggerType={props.data.zoneTriggerType}
                    zoneTypes={props.alertConfigData.zoneTypes}
                    alertData={props.data}
                        key={1} />
                }
                {props.subscriptionTypeId === 16 &&
                    <HardwareTypePresentation {...props} key={1} />
                }
                {
                    props.data.selectedAlertType &&
                    (props.data.selectedAlertType.alertLogTypeID == 39 ||
                        props.data.selectedAlertType.alertLogTypeID == 38
                    )
                    &&
                    <ThresholdTypePresentation
                        routes={props.alertConfigData.routes}
                        alertData={props.data}
                        inputChange={props.actions.inputChange}
                        alertSelectedRoutes={props.data.selectedRoutes}
                        vehicleOptionChange={props.actions.vehicleOptionChange}
                        key={1} />
                }
            </PresentationContainer>
        </div>        
    );
}