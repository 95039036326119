import React, { useState, Suspense } from 'react';
import companyLogo from '../../images/smi_logo.png';
import smiLogo from '../../images/smi_logo_2.png';
import PortalRoutes from '../Routes/PortalRoutes';
import NavMenuPresentation from './NavMenuPresentation';
import HomepageMenu from './HomepageMenu/HomepageMenu';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ReportEngine from '../../pages/Reports/ReportEngine';
import { Route } from 'react-router-dom';
import Util from '../../Utils/Util';
import NotificationMenu from './NotificationMenu';

//import './MainPage.scss';


export default (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div className="main-page">
            <div className={'portal-header' + (isCollapsed ? ' collapse' : '')}>
                <div id="logo">
                    <img alt='company_logo' src={companyLogo} />
                    <img alt='Vehicle Tracking and Management System' src={smiLogo} />
                </div>

                <NavMenuPresentation menuTree={props.menuTree} onSelectSchedule={props.onSelectSchedule}
                    onSupportMenuClick={props.onSupportMenuClick} onCommunityClick={props.onCommunityClick} />
    
                <button className='expander' onClick={() => setIsCollapsed(!isCollapsed)} />

                <NotificationMenu subModules={props.subModules} onOpenOutbox={props.onOpenOutbox} onOpenInbox={props.onOpenInbox}
                    onOpenMyAlerts={props.onOpenMyAlerts} onOpenMessageInbox={props.onOpenMessageInbox}
                    onOpenSendMessage={props.onOpenSendMessage} onChangePassword={props.onChangePassword}
                    menuTree={props.menuTree}/>                
                
                <span id="greeting">Welcome { props.userFullName }</span>
            </div>
            
            <div className="page-content background-gradient">
                <ErrorBoundary>
                    <Suspense fallback="Loading...">
                        <PortalRoutes />
                        <Route path="/Reports/ReportEngine" component={ReportEngine} />
                    </Suspense>
                </ErrorBoundary>
            </div>
        </div>
    );
    
}