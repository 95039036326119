export const  MapActionTypes = {
    SELECT_MULTIPLE_STOPS: 'MAP/SELECT_MULTIPLE_STOPS',    
    SELECT_ROUTE: 'MAP/SELECT_ROUTE',
    SELECT_STOP: 'MAP/SELECT_STOP',      
    ADD_MARKER_GROUP: 'MAP/ADD_MARKER_GROUP',
    REMOVE_MARKER_GROUP: 'MAP/REMOVE_MARKER_GROUP',
    ADD_TRIP_DATA: 'MAP/ADD_TRIP_DATA',
    VEHICLE_POSITION: 'MAP/VEHICLE_POSITION',
    VEHICLE_FIXTAKEN:'MAP/VEHICLE_FIXTAKEN',
    VEHICLE_AUDIT_LOGS: 'MAP/VEHICLE_AUDIT_LOGS',
    CLUSTER_MARKERS: 'MAP/CLUSTER_MARKERS',
    VEHICLE_MARKER: 'MAP/VEHICLE_MARKER',
    HISTORY_ROUTE_LINES: 'MAP/HISTORY_ROUTE_LINES',
    STOP_MARKERS: 'MAP/STOP_MARKERS',
    ROUTE_LINES: 'MAP/ROUTE_LINES',
    CIRCLE_MARKERS: 'MAP/CIRCLE_MARKERS',
    MAP_ZOOM_POSITION:'MAP/MAP_ZOOM_POSITION',
    BOUND_POSITION: 'MAP/BOUND_POSITION',
    POLYGONS: 'MAP/POLYGONS',
    DRAWABLE_ROUTES: 'MAP/DRAWABLE_ROUTES',
    CLEAR_MAP: 'MAP/CLEAR_MAP',
    SET_MAP_REF:'MAP/REF'
}

export const initialState = {
    SelectedStop: null,
    Route: null,
    MarkerGroups: [],
    TripPoints: [],
    VehiclePosition: null,
    VehicleFixTaken: null,
    VehicleAuditLogs: [],
    ClusterMarkers: null,
    VehicleMarker: null,
    HistoryRouteLines: null,
    StopMarkers: null,
    RouteLines: null,
    CircleMarkers: null,
    MapZoomPosition: null,
    BoundPosition: [],
    Polygons: null,
    Stops: null,
    MapRef: null
}

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case MapActionTypes.SELECT_MULTIPLE_STOPS:
            return { ...state, Stops: action.stops };
        case MapActionTypes.SELECT_ROUTE:            
            return { ...state, Route: action.route };
        case MapActionTypes.SELECT_STOP:
            return { ...state, SelectedStop: action.stop };
        case MapActionTypes.ADD_MARKER_GROUP:            
            return { ...state, MarkerGroups: state.MarkerGroups.push({ Name: action.name, Markers: action.markers, ShouldZoom: action.shouldZoom }) };
        case MapActionTypes.REMOVE_MARKER_GROUP:
            return { ...state, MarkerGroups: state.MarkerGroups.filter(function (g) { return g.Name !== action.name }) };
        case MapActionTypes.ADD_TRIP_DATA:
            return { ...state, TripPoints: action.tripData };
        case MapActionTypes.VEHICLE_POSITION:
            return { ...state, VehiclePosition: action.vehiclePosition };
        case MapActionTypes.VEHICLE_FIXTAKEN:
            return { ...state, VehicleFixTaken: action.vehicleFixTaken };
        case MapActionTypes.VEHICLE_AUDIT_LOGS:
            return { ...state, VehicleAuditLogs: action.vehicleAuditLogs };
        case MapActionTypes.CLUSTER_MARKERS:
            return { ...state, ClusterMarkers: action.clusterMarkers };
        case MapActionTypes.VEHICLE_MARKER:
            return { ...state, VehicleMarker: action.vehicleMarker };
        case MapActionTypes.HISTORY_ROUTE_LINES:
            return { ...state, HistoryRouteLines: action.historyRouteLines };
        case MapActionTypes.STOP_MARKERS:
            return { ...state, StopMarkers: action.stopMarkers };
        case MapActionTypes.ROUTE_LINES:
            return { ...state, RouteLines: action.routeLines };
        case MapActionTypes.CIRCLE_MARKERS:
            return { ...state, CircleMarkers: action.circleMarkers };
        case MapActionTypes.MAP_ZOOM_POSITION:
            return { ...state, MapZoomPosition: action.zoomPosition };
        case MapActionTypes.BOUND_POSITION:
            return { ...state, BoundPosition: action.boundPosition };
        case MapActionTypes.POLYGONS:            
            return { ...state, Polygons: action.polygons };
        case MapActionTypes.DRAWABLE_ROUTES:
            return { ...state, DrawableRoutes: action.drawableRoutes };
        case MapActionTypes.SET_MAP_REF:
            return { ...state, MapRef: action.mapRef};
        case MapActionTypes.CLEAR_MAP:
            return { ...initialState, MapRef: state.MapRef};
        default:
            return state
    }
}

//Action Creators
export const actions = {
    setMultipleStops: (stops) => ({ type: MapActionTypes.SELECT_MULTIPLE_STOPS, stops }),
    setRoute: (route) => ({ type: MapActionTypes.SELECT_ROUTE, route }),
    setSeletedStop: (stop) => ({ type: MapActionTypes.SELECT_STOP, stop }),
    addMarkerGroup: (name, markers, shouldZoom) => ({ type: MapActionTypes.ADD_MARKER_GROUP, name, markers, shouldZoom }),
    removeMarkerGroup: (name) => ({ type: MapActionTypes.REMOVE_MARKER_GROUP, name}),
    addTripData: (tripData) => ({ type: MapActionTypes.tripData, tripData }),
    setVehiclePosition: (vehiclePosition) => ({ type: MapActionTypes.VEHICLE_POSITION, vehiclePosition }),
    setVehicleFixTaken: (vehicleFixTaken) => ({ type: MapActionTypes.VEHICLE_FIXTAKEN, vehicleFixTaken }),
    setVehicleAuditLogs: (vehicleAuditLogs) => ({ type: MapActionTypes.VEHICLE_AUDIT_LOGS, vehicleAuditLogs }),
    setClusterMarkers: (clusterMarkers) => ({ type: MapActionTypes.CLUSTER_MARKERS, clusterMarkers }),
    setVehicleMarker: (vehicleMarker) => ({ type: MapActionTypes.VEHICLE_MARKER, vehicleMarker }),
    setHistoryRouteLines: (historyRouteLines) => ({ type: MapActionTypes.HISTORY_ROUTE_LINES, historyRouteLines }),
    setStopMarkers: (stopMarkers) => ({ type: MapActionTypes.STOP_MARKERS, stopMarkers }),
    setRouteLines: (routeLines) => ({ type: MapActionTypes.ROUTE_LINES, routeLines }),
    setCircleMarkers: (circleMarkers) => ({ type: MapActionTypes.CIRCLE_MARKERS, circleMarkers }),
    setMapZoomPosition: (zoomPosition) => ({ type: MapActionTypes.MAP_ZOOM_POSITION, zoomPosition }),
    setBoundPosition: (boundPosition) => ({ type: MapActionTypes.BOUND_POSITION, boundPosition }),
    setPolygons: (polygons) => ({ type: MapActionTypes.POLYGONS, polygons }),
    setDrawableRoutes: (drawableRoutes) => ({ type: MapActionTypes.DRAWABLE_ROUTES, drawableRoutes }),
    setMapRef: (mapRef) => ({ type: MapActionTypes.SET_MAP_REF, mapRef }),
    clearMap: () => ({ type: MapActionTypes.CLEAR_MAP})
}