import './MobileMainPage.css';
import React, { useState, Suspense } from 'react';
import axios from 'axios';
import { Router, BrowserRouter, Link } from 'react-router-dom';
import MobileRoutes from '../MobileRoutes/MobileRoutes';
import { createBrowserHistory } from 'history';
import HardwareRegistrationPage from '../../pages/Mobile/HardwareRegistrationPage';
import SwitchCommunity from '../../pages/Mobile/SwitchCommunity';
import MainPage from '../MainPage/MainPage';

export default class MobileMainPage extends React.Component {

    state = {
        baseUrl: '',
        isCommunity: false,
        canHardwareRegister: false,
        checkingPermission: false
    }

    componentDidMount() {
        this.changeViewportForMobile();
        this.setState({ checkingPermission: true });
        axios.get("/api/HasOperation?operation=HardwareRegistration").then(response => {
            if (response.data) this.setState({ canHardwareRegister: true, checkingPermission: false });
            else this.setState({ canHardwareRegister: false, checkingPermission: false });
        }).catch(err => this.setState({ checkingPermission: false }));

        axios.get('/api/Communities').then(result => {
            if (result.data && result.data.length > 0) {
                this.setState({ baseUrl: '/Mobile/SwitchCommunity', isCommunity: true });

            }
            else {
                this.setState({ baseUrl: '/Mobile/HardwareRegistration', isCommunity: false });
            }
        })
    }

    changeViewportForMobile = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {

            var ww = window.screen.width;
            var mw = 1200; // min width of site
            var ratio = ww / mw; //calculate ratio
            var viewport_meta_tag = document.getElementById('viewport');
            if (ww < mw) { //smaller than minimum size
                viewport_meta_tag.setAttribute('content', `width=device-width, initial-scale=1`);
            }

        }
    }

    RenderPage(props) {
        const isCommunityPage = props.isCommunityPage;

        var uri = window.location.toString();

        if (uri && uri.indexOf("?") > 0) {
            var cleanUri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, cleanUri);
        }

        if (isCommunityPage) {
            return <SwitchCommunity />
        }
        else if (props.canHardwareRegister) {
            return <HardwareRegistrationPage />
        }
        else if (props.checkingPermission) {
            return <div className="inline-message">
                Wait... Checking your permission
            </div>
        }
        else if (!props.canHardwareRegister && !props.checkingPermission)
            return <MainPage />
    }



    history = createBrowserHistory({ basename: this.state.baseUrl })

    render() {

        let communityCheck = this.state.isCommunity;


        return (
            <this.RenderPage isCommunityPage={communityCheck}
                canHardwareRegister={this.state.canHardwareRegister}
                checkingPermission={this.state.checkingPermission} />
        );
    }
}