import React, { useState } from 'react';
import SubmenuPresentation from './SubmenuPresentation';

//forceUpdate hook taken from https://stackoverflow.com/a/53837442/2457005
function useForceUpdate() {
    const [value, set] = useState(true);
    return () => set(!value);
}

export default (props) => {
    const forceUpdate = useForceUpdate();
    return (
        <ul className="nav-menu">
            {props.menuTree && props.menuTree.subMenu.map((item, i) => (
                <li key={i} className={item.title.toLowerCase()}>
                    {item.title}
                    <SubmenuPresentation parentPath={item.title.replace(' ', '')} items={item.subMenu}
                        onItemSelected={forceUpdate} onSelectSchedule={props.onSelectSchedule}
                        onSupportMenuClick={props.onSupportMenuClick} onCommunityClick={props.onCommunityClick} />
                </li>
            ))}
        </ul>
    );
}