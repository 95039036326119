import React from 'react';
import L from 'leaflet';
import { Polyline, Marker, Popup } from 'react-leaflet';

function onMarkerMouseOver(e) {
    e.target.openPopup();
}

export default ({ interStopPoints, stops, routeColor, onPathClick }) => {
    const polylines = getPolyLines(interStopPoints, stops, routeColor, onPathClick)
    const stopIcons = stops.map(s => {
        let stopSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">';
        stopSvg += '<circle cx="10" cy="10" r="9" stroke="#000" stroke-width="1" fill="red" />';
        stopSvg += '<text text-anchor="middle" x="10" y="14" font-family="Segoe UI" stroke="none" fill="white" font-size="11">' + s.seq + '</text>';
        stopSvg += '</svg>';

        const icon = L.divIcon({
            html: stopSvg,
            iconAnchor: [10, 10],
            className:'my-div-icon'
        });

        return (
            <Marker key={'s' + s.seq} position={[s.lat, s.lng]} icon={icon} onMouseOver={onMarkerMouseOver}>
                <Popup>
                    {s.seq}. {s.stopName} ({s.stopCode})
                </Popup>
            </Marker>
            );
    })
    return (
        <React.Fragment>
            {polylines}
            {stopIcons}
        </React.Fragment>
    );
}

function getPolyLines(pathPoints, stops, color, clickHandler) {
    if (!color) color = '#000000';
    if (!color.startsWith('#')) color = '#' + color;
    let stopIndex = 1;
    let posArr = [];
    const polylineArr = [];
    for (let point of pathPoints) {
        posArr.push([point.lat, point.lng]);
        if (point.lat === stops[stopIndex].lat && point.lng === stops[stopIndex].lng) {
            const startStop = stops[stopIndex - 1];
            const endStop = stops[stopIndex];
            polylineArr.push(<Polyline key={'p' + stopIndex} positions={posArr} stroke={2} color={color} onClick={() => clickHandler && clickHandler(startStop, endStop)} />);
            posArr = [[point.lat, point.lng]];
            stopIndex++;
            if (stopIndex >= stops.length) break;
        }
    }
    return polylineArr;
}