import React from 'react';
import axios from 'axios';
import '../../styles/MobileClient/SwitchCommunity.scss';
import { Router, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MainPage from '../../components/MainPage/MainPage';
import HardwareRegistrationPage from '../Mobile/HardwareRegistrationPage';

export default class SwitchCommunity extends React.Component {
    state = {
        communityList: [],
        loading: false
    };

    loggedInUser = '';

    componentDidMount() {
        //Load sitemap and get list of communities from it

        var currentUser = JSON.parse(localStorage.getItem('user'));
        this.loggedInUser = currentUser.username;
        console.log(this.loggedInUser);

        axios.get('/api/Menu').then(result => {
            const sitemap = result.data.menuTree.subMenu;
            const communityItem = sitemap.find(n => n.title == "COMMUNITY");
            if (communityItem) {
                this.setState({ communityList: communityItem.subMenu });
            }
        })
            .catch(function (e) {
                if (e && e.response && e.response.status === 401)
                    window.location = window.location.origin;
            });
    }

    headers= {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
    }


    goToCommunity(url) {
        this.setState({ loading: true });
        const parsedUrl = new URL(url);
        axios.get('/api/SwitchCommunity?serverName=' + encodeURIComponent(parsedUrl.hostname), { headers: this.headers }).then(response => {
            const ticket = response.data;
            if (ticket && /[A-Fa-f0-9]{64}/.test(ticket)) {
                const username = this.loggedInUser;
                window.location.href = `${url}?ticket=${encodeURIComponent(ticket)}&username=${encodeURIComponent(username)}&IsMobileLogin=True`;
            }
            else {
                alert("Unable to authenticate community. Please check configuration.");
            }
        }).catch(err => {
            alert("Unable to authenticate community. Please check configuration.");
            console.log(err);
        })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    baseUrl = "/"
    history = createBrowserHistory({ basename: this.baseUrl });

    updatePage() {
        setTimeout(() => window.location.reload());
    }

    render() {

        if (window.location.pathname == "/Maintain/Hardware/Registration") {
            return <MainPage />
        }
        else if (window.location.pathname == "/Mobile/HardwareRegistrationPage") {
            return <HardwareRegistrationPage />
        }

        let communities = this.state.communityList.map((c, i) => <p className="community" key={i} onClick={() => this.goToCommunity(c.url)}>{c.title}</p>);

        return (
            <div className="switch-community-page" id="mobileCommunity">
                <Router history={this.history}>
                    <Link onClick={this.updatePage} to="/Maintain/Hardware/Registration">Dispatch Portal</Link>
                    <div className="spacer" /><label> |</label> <div className="spacer" />
                    <Link onClick={this.updatePage} to="/Mobile/HardwareRegistration">Hardware Registration</Link>
                    <div className="spacer" /><label> | </label><div className="spacer" />
                    <Link onClick={this.updatePage} to="/Mobile/HardwareAssignment">Hardware Assign</Link>
                </Router>

                <div className="loader-cover" style={{ display: this.state.loading ? "block" : "none" }}></div>
                <div className="loader" style={{ display: this.state.loading ? "block" : "none" }}><img src={require('../../images/loader.gif')} /></div>
                <h1>Select Community</h1>
                {communities}
                <br />
                <Router history={this.history}>
                    <Link onClick={this.updatePage} to="/Maintain/Hardware/Registration">Go to Dispatch Portal</Link>
                    <br />
                    <br />
                    <Link onClick={this.updatePage} to="/Mobile/HardwareRegistrationPage">Go to Hardware Registration</Link>
                </Router>
            </div>
        );
    }


}
