import './MyAlerts.scss';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

import Presentation from './MyAlertPresentation';
import Util from '../../Utils/Util';
import AlertDetailsContainer from './AlertDetailsContainer';

//export default () => (
//	<iframe src="/HtmlClient/MyAlerts.html" style={{ width: '100%', height: '100%' }}></iframe>
//);

const alertStatus = [
    {
        label: 'Active',
        value: 'Active',
    },
    {
        label: 'Inactive',
        value: 'InActive',
    },
    {
        label: 'All',
        value: '-1',
    },
];

const defaultSubscriptionType = { subscriptionTypeID: 0, subscriptionTypeName: 'Add New Alert' };

export default () => {
    const [reloadGrid, setGridReload] = useState(false);
    const [state, setState] = useState({
        alertStatus: alertStatus,
        editingAlert: null,
        zoneTypes: [],
        alertLogTypes: [],
        routes: [],
        hardwareTypes: [],
        smsSettings: [],
        segments: [],
        incidentTypes: [],
        subscriptionTypes: [],
        subscriptionTypeId: -1,
        selectedSubscriptionType: defaultSubscriptionType,
        showAlert: false,
        showIncidentAlert: false,
        showSegmentAlert: false,
        reloadGrid: false
    });

    useEffect(() => {
        let zonePromise = axios.get('/api/MyAlertZones');
        let alertLogTypesPromise = axios.get('/api/MyAlertLogTypes');
        let routePromise = axios.get('/api/MyAlertRoutes');
        let hardwarePromise = axios.get('/api/MyAlertHardwareTypes');
        let settingsPromise = axios.get('/api/SMSSettings');
        let incidentTypesPromise = axios.get('/api/MyAlertIncidentTypes');
        let subTypesPromise = axios.get('/api/MyAlertSubTypes');
        let segmentPromise = axios.get('/api/MyAlertSegments');

        axios.all([zonePromise, alertLogTypesPromise,
            routePromise, hardwarePromise, settingsPromise,
            incidentTypesPromise, subTypesPromise, segmentPromise]).then(axios.spread((...response) => {
                let _subscriptionTypes = response[6].data
                _subscriptionTypes.unshift(defaultSubscriptionType);
                let segments = response[7].data;

                setState(_state => ({
                    ..._state,
                    zoneTypes: response[0].data,
                    alertLogTypes: response[1].data,
                    routes: response[2].data,
                    hardwareTypes: response[3].data,
                    smsSettings: response[4].data,
                    incidentTypes: response[5].data,
                    subscriptionTypes: _subscriptionTypes,
                    segments: segments,
                }));
            })).catch(err => console.log(err));
    }, []);

    const getTemplateToShow = subscriptionTypeID => {
        if (subscriptionTypeID === 12
            || subscriptionTypeID === 13) return 'showSegmentAlert';
        if (subscriptionTypeID === 14) return 'showIncidentAlert';
        else return 'showAlert';
    }
    const subscriptionChanged = e => {
        console.log(e);
        let subscriptionType = e.value;

        setState({
            ...state,
            selectedSubscriptionType: subscriptionType,
            subscriptionTypeId: subscriptionType.subscriptionTypeID,
            [getTemplateToShow(subscriptionType.subscriptionTypeID)]: true
        })
    }

    const getSubscriptionType = () => {
        let subscriptionType = state.selectedSubscriptionType;
        if (state.editingAlert)
            subscriptionType = state.subscriptionTypes.find(s => s.subscriptionTypeID === state.editingAlert.subscriptionType);

        return subscriptionType;
    }

    const subscriptionModalClose = (e, isReload = false) => {
        let sybscriptionType = getSubscriptionType();
        setState(_state => ({
            ..._state,
            [getTemplateToShow(sybscriptionType.subscriptionTypeID)]: false,
            selectedSubscriptionType: defaultSubscriptionType,
            editingAlert: null,
            reloadGrid: isReload
        }));
    }

    const onEdit = (e, dataItem) => {
        setState({
            ...state,
            editingAlert: dataItem,
            [getTemplateToShow(dataItem.subscriptionType)]: true
        });
    }

    const dataLoaded = data => {
        setState({ ...state, reloadGrid : false});
    }

    return (
        <>
            <Presentation
                data={state}
                onSubscriptionChange={subscriptionChanged}
                defaultSubscriptionType={defaultSubscriptionType}
                isReload={state.reloadGrid}
                dataLoaded={dataLoaded}
                onEdit={onEdit} />
            {(state.showAlert || state.showIncidentAlert || state.showSegmentAlert) &&
                <AlertDetailsContainer
                    alertConfigData={state}
                    getSubscriptionType={getSubscriptionType}
                    subscriptionTypeId={state.selectedSubscriptionType.subscriptionTypeID || (state.editingAlert ? state.editingAlert.subscriptionType : 0)}
                    onClose={subscriptionModalClose} />
            }
        </>
    );
};