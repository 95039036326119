

//Action Types
export const types = {
    ADD_COMMENT: 'COMMENT/ADD_COMMENT',
    GET_WEATHER: 'COMMENT/GET_WEATHER'
}

export const initialState = {
    comments: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_COMMENT:
            {                
                return { ...state, comments: [...state.comments,action.comment] };
            }
        default:
            return state;
    }
}

export const actionCreators = {
    addComment: (comment) => ({ type: types.ADD_COMMENT, comment })

}