import React from 'react';
import RecipientPresentation from './RecipientPresentation';

export default ({ onClearRecipients, onRemoveRecipient, recipients }) => (
    <React.Fragment>
        <div className="recipient-header silverlight-button-zone">
            <h2>Recipients</h2>
            <button className="k-button" onClick={onClearRecipients}><i className="k-icon k-cancel" />Clear All</button>
        </div>
        <div className="current-recipients">
            {recipients.map((r, i) => <RecipientPresentation key={i} recipient={r} onRemoveRecipient={onRemoveRecipient} />)}
        </div>
    </React.Fragment>
)