import { useEffect } from 'react';
import { getDateFromISO, getTodayDate } from "./DateUtil";

export function getSelectedSchedule() {
    const selectedScheduleJSON = window.localStorage.getItem('smiPortalSchedule');
    if (!selectedScheduleJSON) return null;
    return JSON.parse(selectedScheduleJSON);
}

export function getSelectedScheduleStartDateStr() {
    const schedule = getSelectedSchedule();
    return schedule ? schedule.startDate.split('T')[0] : null;
}

export function getSelectedScheduleLabel() {
    const schedule = getSelectedSchedule();
    if (!schedule) return 'No Schedule Selected';
    return schedule.name + ' (' + getDateFromISO(schedule.startDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) + (schedule.endDate ? ' to ' + getDateFromISO(schedule.endDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ' Onwards') + ')'
}

export function selectedScheduleIsInPast() {
    const schedule = getSelectedSchedule();
    if (!schedule || !schedule.endDate) return false;    
    return getDateFromISO(schedule.endDate) < getTodayDate();
}

export function selectedScheduleIsInFuture() {
    const schedule = getSelectedSchedule();
    if (!schedule || !schedule.startDate) return false;    
    return getDateFromISO(schedule.startDate) > new Date();
}

export function selectedScheduleIsCurrentSchedule() {
    return getSelectedSchedule() && !selectedScheduleIsInFuture() && !selectedScheduleIsInPast();
}

export function useOnSelectedScheduleChange(callback) {
    useEffect(() => {
        window.addEventListener('scheduleChange', callback);
        return () => window.removeEventListener('scheduleChange', callback);
    });
}