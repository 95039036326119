import React, { Component } from 'react';
import axios from 'axios';


export default class ForgotPasswordPresentation extends Component {

    state = {
        email: '',
        isSuccess: false
    }

    handleInputChange = (event) => this.setState({ [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })

    onSubmit = (e) => {
        e.preventDefault();
        
        //this.props.onSubmit(this.state.email);
        axios.post('/api/ResetPassword', { emailAddress: this.state.email.toString() }).then((response => {
            this.setState({ isSuccess: true });
        }),
            (Error) => {
                this.setState({ isSuccess: false });
            });
    };

    render() {

        const SuccessMessage = () => (<h2><p className="response info">Check your email for your new temporary password.</p></h2>);


        return (
            <div className="login forgot-password">
                <h1>FORGOT YOUR PASSWORD?</h1>
                <form className="login-form forgot-password-form" onSubmit={this.onSubmit}>
                    <div className="login-container">
                        <p>Enter your email below and we'll send you a link to reset your password</p>
                        <div className="email-row">
                            <label htmlFor="email">Email:</label>
                            <input type="text" id="email" name="email" value={this.state.email} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    {this.state.isSuccess && <SuccessMessage />}
                    <button type="submit" className="green">Submit</button><br />
                    <button type="button" className="green" onClick={this.props.onCancel}>Cancel</button>
                    
                </form>
            </div>
        );
    }
}

