import './IncidentAlert.scss';

import React, { useState } from 'react';
import { TreeView } from '@progress/kendo-react-treeview';
import PresentationContainer from '../AlertComponent/PresentationContainer';
import Util from '../../../Utils/Util';

export default (props) => {
    const [collapsedIds, setCollapsedIds] = useState([]);

    const expandChange = event => {
        event.item.expanded = !event.item.expanded;
        let segmentId = event.item.incidentTypeID;
        let _collapsedIds = collapsedIds.slice(0);

        if (event.item.expanded && !_collapsedIds.includes(segmentId))
            _collapsedIds.push(segmentId);
        if (!event.item.expanded && _collapsedIds.includes(segmentId)) {
            let index = _collapsedIds.indexOf(segmentId);
            _collapsedIds.splice(index, 1);
        }
        setCollapsedIds(_collapsedIds);
    }

    const checkChange = event => {
        let segmentId = event.item.incidentTypeID;
        let segmentIds = props.data.segmentIds.slice(0);

        event.item.checked = !event.item.checked;

        if (event.item.checked && !segmentIds.includes(segmentId))
            segmentIds.push(segmentId);
        if (!event.item.checked && segmentIds.includes(segmentId)) {
            let index = segmentIds.indexOf(segmentId);
            segmentIds.splice(index, 1);
        }
        updateSegments(segmentIds);
    }

    const updateSegments = segmentIds => {
        let targetObject = {
            target: {
                name: 'segmentIds',
                value: segmentIds
            }
        };
        props.actions.inputChange(targetObject);
    }
    
    const getTreeData = () => {
        let incidentTypes = props.alertConfigData.incidentTypes.slice(0);
        incidentTypes.forEach(t => {
            t.expanded = false;
            t.checked = false;

            if (props.data.segmentIds &&
                props.data.segmentIds.includes(t.incidentTypeID)) t.checked = true;

            if (collapsedIds && collapsedIds.includes(t.incidentTypeID)) t.expanded = true;
        });
        return Util.flatDataToHierarchical(incidentTypes, 'incidentTypeID', 'parentIncidentTypeID');
    };

    const treeData = getTreeData();
    
    return (<div id="incidentAlert">
        <div className="customEditLeft">
            <div className="TreebeardHeader">Available Incidents</div>
            <div className="Treebeard">
                <div id="beard1"></div>
                <TreeView
                    data={treeData}
                    textField="name"
                    checkboxes={true}
                    expandIcons={true}
                    checkField="checked"                    
                    onCheckChange={checkChange}
                    onExpandChange={expandChange} />
            </div>
        </div>
        <div className="customEditRight">
            <PresentationContainer {...props} />
        </div>
    </div>);
}