import React, { Component } from 'react';
import axios from 'axios';
import { Router, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Dialog } from '@progress/kendo-react-dialogs';
import MainPagePresentation from './MainPagePresentation';
import ChangePassword from '../../pages/Settings/ChangePassword/ChangePassword';
import ScheduleEdit from "../../pages/Maintain/Scheduling/ScheduleEdit/ScheduleEdit";
import Emergencies from './Emergencies/Emergencies';
import SupportVideo from '../Support/Video';
import SendMessage from '../SendMessage/SendMessage';
import MessageInbox from '../MessageInbox/MessageInbox';
import MyAlerts from '../MyAlerts/MyAlerts';
import MakeAnnouncement from '../MakeAnnouncement/MakeAnnouncement';
import MSGRecipientType from '../SendMessage/MSGRecipientType';
import AlertInbox from '../AlertInbox/AlertsInbox';
import AlertOutbox from '../AlertInbox/AlertOutbox';

import '@progress/kendo-theme-default/dist/all.css';
import '../../styles/kendo-styles.scss';
import './MainPage.scss';
import Util from '../../Utils/Util';
import { kendoAlert } from '../../Utils/DialogUtil';

export default class MainPage extends Component {

    state = {
        menuTree: null,
        subModules: [],
        messageCount: null,
        notificationCount: null,
        showChangePassword: false,
        showSelectSchedule: false,
        showSendMessage: false,
        showMessageInbox: false,
        showMakeAnnouncement: false,
        showMyAlerts: false,
        selectedHomepage: '',
        supportItem: null,
        preselectedRecipients: null,
        showAlertInbox: false,
        showAlertOutbox: false
    }

    user = JSON.parse(localStorage.getItem('user'));
    baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    history = createBrowserHistory({ basename: this.baseUrl });

    componentDidMount() {
         this.changeViewportForMobile();

        axios.get('/api/Menu').then(result => {
            this.setState(result.data); //Sets menuTree and subModules

            let userHomePage;

            axios.get('/api/HomePage').then(response => {
                if (response && response.data) {
                    userHomePage = response.data;
                }
            }).finally(e => {
                if (userHomePage) {
                    if (!userHomePage || Util.isNullOrUndefined(userHomePage) || Util.isNullOrWhitespace(userHomePage) || userHomePage.search('.aspx') > -1)
                        userHomePage = "/View/Vehicles";

                    //If the user came from login page, the pathname will be '/' and we should redirect them to their homepage
                    if (!window.location.pathname || window.location.pathname === this.baseUrl)
                        this.history.push(userHomePage);
                }
                else {
                    if (!window.location.pathname || (window.location.pathname === this.baseUrl) || window.location.pathname.toLocaleLowerCase().search('login.aspx') > -1)
                        if (result.data) {
                            let menuTree = result.data.menuTree;
                            let menu = '';
                            while (true) {
                                if (!menuTree.subMenu)
                                    break;
                                menu += '/' + menuTree.subMenu[0].title.replace(" ", "");
                                menuTree = menuTree.subMenu[0];
                            }
                            if (menu.length > 0)
                                this.history.push(menu);
                        }
                        else
                            this.history.push('/View/Vehicles');
                }
            });

        }).catch(err => {
            //clear localstorage and return to login page
            localStorage.clear();
            window.location = "/";
        });

        axios.get('/api/SchApiCru').then((response) => {
            if (response.data.length === 0) {
                localStorage.setItem('smiPortalSchedule', '');
            } else {
                response.data.sort((a, b) => this.date_sort_desc(a.startDate, b.startDate));
                this.checkStoredSchedule(response.data);                
            }
        });
    }

    checkStoredSchedule = (scheduleList) => {
        var item = null;
        let schedule = null;

        if (localStorage.getItem('smiPortalSchedule'))
            schedule = JSON.parse(localStorage.getItem('smiPortalSchedule'));

        if (scheduleList && scheduleList.length > 0) {
            let validSchedule = false;
            if (schedule) {
                let s = scheduleList.find(s => s.scheduleID == schedule.scheduleID && s.name == schedule.name);
                if (s)
                    validSchedule = true;
            }

            if (!schedule || !validSchedule) {
                var index = this.findActiveScheduleIndex(scheduleList);
                item = scheduleList[index];
                localStorage.setItem('smiPortalSchedule', JSON.stringify(item));
            }
        }
    }

    findActiveScheduleIndex = (data) => {
        var tempDate = null;
        var s = new Date();
        var index = -1;

        s.setHours(0, 0, 0, 0);
        tempDate = new Date(s.toISOString());

        for (let j = 0; j < data.length; j++) {
            if (tempDate >= new Date(data[j].startDate)) {
                if (data[j].endDate && tempDate > new Date(data[j].endDate)) {
                    continue;
                }
                index = j;
                break;
            }
        }
        return index;
    }

    changeViewportForMobile = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {

            var ww = window.screen.width;
            var mw = 1200; // min width of site
            var ratio = ww / mw; //calculate ratio
            var viewport_meta_tag = document.getElementById('viewport');
            if (ww < mw) { //smaller than minimum size
                viewport_meta_tag.setAttribute('content', `width=1400`);
            }
            
        }
    }
    date_sort_desc(date1, date2) {
        // This is a comparison function that will result in dates being sorted in
        // DESCENDING order.
        if (new Date(date1) > new Date(date2)) return -1;
        if (new Date(date1) < new Date(date2)) return 1;
        return 0;
    }


    onSupportMenuClick = (item) => {
        if (!item) return;
        if (!item.isPopup) {
            window.open(item.url, "_blank");
            return;
        }
        this.setState({ supportItem: item });
    }

    onCommunityClick = (portalMenu) => {

        var parser = document.createElement('a');
        parser.href = portalMenu.url;
        axios.get('/api/SwitchCommunity?serverName=' + encodeURIComponent(parser.hostname)).then(function (response) {
            var ticket = response.data;
            if (ticket && /[A-Fa-f0-9]{64}/.test(ticket)) {
                var username = window.SystemSettings.loggedInUser.split('@')[0];
                window.location.href = portalMenu.url + '/login?ticket=' + encodeURIComponent(ticket) + '&username=' + encodeURIComponent(username);
            }
            else {
                kendoAlert("Unable to authenticate community. Please check configuration.");
            }
        }, function (err) {
            kendoAlert("Unable to authenticate community. Please check configuration.");
            console.log(err);
        });
    }

    toggleAlertInbox = () => this.setState({ showAlertInbox: !this.state.showAlertInbox });

    toggleAlertOutbox = () => this.setState({ showAlertOutbox: !this.state.showAlertOutbox });

    toggleChangePassword = () => this.setState({ showChangePassword: !this.state.showChangePassword });
    toggleSelectSchedule = () => this.setState({ showSelectSchedule: !this.state.showSelectSchedule });
    toggleSendMessage = (emergencyVehicle) => {
        if (!this.state.showSendMessage && emergencyVehicle) this.setState({ showSendMessage: true, preselectedRecipients: [{ id: emergencyVehicle.id, type: MSGRecipientType.Vehicle, text: emergencyVehicle.name }] });
        else this.setState({ showSendMessage: !this.state.showSendMessage, preselectedRecipients: [] });
    }
    toggleMakeAnnouncement = () => this.setState({ showSendMessage: !this.state.showSendMessage });
    toggleMessageInbox = () => {
        this.setState({ showMessageInbox: !this.state.showMessageInbox });
    }
    toggleMyAlerts = () => {
        this.setState({ showMyAlerts: !this.state.showMyAlerts });
    }

    onCancel = () => {
        this.setState({
            showSelectSchedule: false
        });
    }

    onSupportVideoDialogClose = () => {
        this.setState({ supportItem: null });
    }

    render() {

        let systemSettings = window.SystemSettings || window.parent.SystemSettings;

        if (Util.isNullOrUndefined(systemSettings)) return null;

        return (
            <BrowserRouter>
                <Router history={this.history}>
                    <MainPagePresentation menuTree={this.state.menuTree} subModules={this.state.subModules} onChangePassword={this.toggleChangePassword}
                        onSelectSchedule={this.toggleSelectSchedule} selectedHomepage={this.user.homepage}
                        onSupportMenuClick={this.onSupportMenuClick} onOpenSendMessage={this.toggleSendMessage}
                        onOpenInbox={this.toggleAlertInbox} onOpenOutbox={this.toggleAlertOutbox} onCommunityClick={this.onCommunityClick}
                        notificationCount={this.state.notificationCount} messageCount={this.state.messageCount}
                        onOpenMessageInbox={this.toggleMessageInbox} onOpenMyAlerts={this.toggleMyAlerts}
                        userFullName={this.user && this.user.username ? this.user.username.toLowerCase() : ""} />
                    {this.state.showChangePassword && <Dialog title={"Change Password"} width={703} height={290} onClose={this.toggleChangePassword}><ChangePassword showModal={this.state.showChangePassword} onClose={() => this.setState({ showChangePassword: false })} /></Dialog>}
                    {this.state.showSelectSchedule && <div id="editScheduleDialog"> <Dialog title={"Select Editing Schedule"} width={225} onClose={this.toggleSelectSchedule} style={{ zIndex: 100 }} ><ScheduleEdit onCancel={this.onCancel} /></Dialog> </div>}
                    {this.state.showSendMessage && <div className="send-message"> <Dialog title="Send Message" width={1200} height={600} onClose={this.toggleSendMessage} style={{ zIndex: 200 }}> <SendMessage preselectedRecipients={this.state.preselectedRecipients} onClose={this.toggleSendMessage} /> </Dialog> </div>}
                    {this.state.showMyAlerts && <div className="send-message"> <Dialog title="My Alerts" width={1200} height={693} onClose={this.toggleMyAlerts} style={{ zIndex: 200 }}> <MyAlerts onClose={this.toggleMyAlerts} /> </Dialog> </div>}
                    {this.state.showMessageInbox && <MessageInbox onClose={this.toggleMessageInbox} onSendMessage={this.toggleSendMessage} />}
                    {this.state.showMakeAnnouncement && <Dialog title={"Make Announcement"} width={1200} height={600} onClose={this.toggleMakeAnnouncement} style={{ zIndex: 100 }} ><MakeAnnouncement onClose={this.toggleMakeAnnouncement} /></Dialog>}
                    {this.state.supportItem && this.state.supportItem.isPopup && <Dialog title={this.state.supportItem.title} width={800} height={600} onClose={this.onSupportVideoDialogClose}><SupportVideo url={this.state.supportItem.url} /> </Dialog>}
                    {this.state.showAlertInbox && <div className="alert-inbox"> <Dialog title={"Alert Inbox"} width={1400} height={650} onClose={this.toggleAlertInbox}><AlertInbox showInboxModal={this.state.showAlertInbox} onClose={() => this.setState({ showAlertInbox: false })} /></Dialog> </div>}
                    {this.state.showAlertOutbox && <div className="alert-outbox"> <Dialog title={"Alert Outbox"} width={1200} height={600} onClose={this.toggleAlertOutbox}><AlertOutbox showOutboxModal={this.state.showAlertOutbox} onClose={() => this.setState({ showAlertOutbox: false })} /></Dialog> </div>}
                    <Emergencies onSendMessage={this.toggleSendMessage} />
                </Router>
            </BrowserRouter>
        );
    }
}