import $ from 'jquery';
import React, { useCallback, useState } from 'react';
import MessageRecipients from './MessageRecipients';
import MessageComposition from './MessageComposition';
import axios from 'axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MSGRecipientType from './MSGRecipientType';
import LoadingMask from '../LoadingMask/LoadingMask';
import { kendoAlert } from '../../Utils/DialogUtil';

import './SendMessage.scss';

//Preselected recipients should be passed with the following fields: { id, type, text }. The "type" field should be a type specified in the MSGRecipientType enum.
export default ({ onClose, replyTo, preselectedRecipients }) => {

    const [selectedRecipients, setSelectedRecipients] = useState(preselectedRecipients.filter(s => s.id) || []);
    const [isLoading, setIsLoading] = useState(false);

    const recipientSelected = useCallback((event) => {
        const selectedRecipient = event.item;
        if (selectedRecipient.type === MSGRecipientType.Vehicle && selectedRecipient.cannotReceiveMessages) alert('Vehicle ' + selectedRecipient.text + ' cannot receive messages as it does not have an OBU/MDT');
        else {
            setSelectedRecipients((currentSelectedRecipients) => {
                if (currentSelectedRecipients.indexOf(selectedRecipient) === -1) return currentSelectedRecipients.concat([selectedRecipient]);
                return currentSelectedRecipients;
            });
        }
    }, []);

    const removeRecipient = useCallback((recipientToRemove) => {
        setSelectedRecipients((currentSelectedRecipients) => currentSelectedRecipients.filter(csr => csr !== recipientToRemove));
    }, []);

    const isValid = (recipients, message) => {
        let valid = true;
        let alertMessage = "";

        if (!recipients || recipients.length < 1) {
            $(".current-recipients").addClass("error");
            alertMessage = 'Your message must have at least one recipient';
            valid = false;
        }
        else if (recipients && recipients.length > 0)
            $(".current-recipients").removeClass("error");

        if (!message || message.length < 1) {
            $(".message-textbox").find("textarea").addClass("error");
            alertMessage += (alertMessage.length > 0 ? (<br />) : null) + 'Your message cannot be blank';
            valid = false;
        }
        else if (message && message.length > 0)
            $(".message-textbox textarea").removeClass("error");

        //if (!valid)
        //    kendoAlert(alertMessage);

        return valid;
    }

    const sendMessage = useCallback((recipients, message, priority, requireYesNo) => {
        if (isValid(recipients, message)) {
            const cleanRecipients = recipients.map(r => ({ type: r.type, id: r.id, parentId: r.parentId, text: r.text }));
            setIsLoading(true);
            axios.post('/api/SendMessage', { replyTo: replyTo ? replyTo : 0, recipients: cleanRecipients, message, priority, requireYesNo }).then(() => {
                //If message was sent successfully, close the send message dialog
                onClose();
            })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                    kendoAlert('There was an error sending your message. Please try again. If you are still unable to send, please contact SMI support');
                });
        }
    }, [onClose, replyTo]);

    return (
        <ErrorBoundary>
            <div className="message-screen">
                <MessageRecipients includeDispatchers onItemSelected={recipientSelected} />
                <MessageComposition recipients={selectedRecipients} onClearRecipients={() => setSelectedRecipients([])} onRemoveRecipient={removeRecipient} onCancel={onClose} onSend={sendMessage} />
                {isLoading && <LoadingMask />}
            </div>
        </ErrorBoundary>
    );
}