import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Submenu.scss';



const SubmenuPresentation = (props) => {
    const [shouldRender, setShouldRender] = useState(true);
    const forceMenuHide = () => { setShouldRender(false); setTimeout(() => setShouldRender(true)) };
    if (!shouldRender) return null;
    return (
        <ul className="submenu">
            {props.items.map((item, i) => {
                const itemPath = props.parentPath + "/" + item.title.replace(/\s+/g, '');
                const currentPath = window.location.pathname.substr(1);

                // The "Select Editing Schedule" and Support Menu items launch popup windows or other special behaviour rather than taking the user to a new page
                const isSelectSchedule = item.title === 'Select Editing Schedule';
                const isSupportMenu = itemPath.startsWith('System/Support/') && (!item.subMenu || item.subMenu.length === 0);
                const isCommunityMenu = itemPath.startsWith('COMMUNITY/') && (!item.subMenu || item.subMenu.length === 0);
                return (
                    <li key={i} className={(item.subMenu && item.subMenu.length ? 'has-side-menu' : '') + (currentPath === itemPath ? ' selected' : '')} data-menu-id={item.title}>
                        {!isSupportMenu && !isSelectSchedule && !isCommunityMenu && <Link to={'/' + itemPath} onClick={() => { props.onItemSelected(); forceMenuHide(); }}>{item.title}</Link>}
                        {isSelectSchedule && <button className="mimic-link" onClick={() => { forceMenuHide(); props.onSelectSchedule(); }}>{item.title}</button>}
                        {isSupportMenu && <button className="mimic-link" onClick={() => { forceMenuHide(); props.onSupportMenuClick(item); }}>{item.title}</button>}                        
                        {isCommunityMenu && <a href="#" onClick={(e) => { e.preventDefault(); forceMenuHide(); props.onCommunityClick(item); }}>{item.title}</a>}
                        {item.subMenu && item.subMenu.length && <SubmenuPresentation parentPath={itemPath} items={item.subMenu} onItemSelected={props.onItemSelected} onSelectSchedule={props.onSelectSchedule} onSupportMenuClick={props.onSupportMenuClick} />}
                    </li>
                );
            })}
        </ul>
    );
}

export default SubmenuPresentation;