import React from 'react';
import { Consumer } from './PasswordContext';
import alertImage from '../../../images/alert_info.png';

export default () => (
    <Consumer>
        {context => (
            <div className={(context.showModal ? "wrapper-default-height" : "forced-reset")} >
                <div id="wrapper" >
                    <div id="contentWrapper">
                        <div id="contentColumn">
                            <div id="contentColumnContent">
                                <p id="resetMessage">Please update your password</p>
                                <div id="changePasswordForm">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><label htmlFor="oldPassword">Old Password:</label></td>
                                                <td><input type="password" className={context.oldPasswordError ? "error" : ""} value={context.oldPassword} name="oldPassword" id="oldPassword" onChange={context.handleInput} /></td>
                                            </tr>
                                            <tr>
                                                <td><label htmlFor="newPassword">New Password:</label></td>
                                                <td><input type="password" className={context.newPasswordError ? "error" : ""} value={context.newPassword} name="newPassword" id="newPassword" onChange={context.handleInput} /></td>
                                            </tr>
                                            <tr>
                                                <td><label htmlFor="confirmPassword">Confirm New Password:</label></td>
                                                <td><input type="password" className={context.confirmPasswordError ? "error" : ""} value={context.confirmPassword} name="confirmPassword" id="confirmPassword" onChange={context.handleInput} /></td>
                                            </tr>
                                        </tbody>
                                    </table>                                    
                                    <button className="green save-btn" onClick={context.handleSave}>Save</button>
                                    {context.errorMessage && (<div id="errorBox">
                                        <label style={{ color: 'red' }}> {context.errorMessage} </label>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="rightColumn">
                        <div id="rightColumnContent">
                            <img className="alert-info" src={alertImage} alt="" />
                            <p id="passwordPolicy">{context.passwordPolicy ? `Passwords must be ${context.passwordPolicy.description}` : ""}</p>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </Consumer>
);