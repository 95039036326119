import './HardwareAssignment.scss';

import React from 'react';
import axios from 'axios';
import { sortNaturalAlphaNumeric } from "../../GridHelper";
import { Router, Link } from 'react-router-dom';
import '../../styles/MobileClient/HardwareRegistrationPage.scss';
import { createBrowserHistory } from 'history';
import MainPage from '../../components/MainPage/MainPage';
import SwitchCommunity from '../Mobile/SwitchCommunity';
import HardwareAssignment from '../Mobile/HardwareAssignment';

export default class HardwareRegistrationPage extends React.Component {

    state = {
        selectedHardware: {},
        notes: '',
        assemblyStatuses: [],
        vehicles: [],
        hardware: [],
        hardwareVehicle: null,
        unassignedVehicleList: [],
        selectedVehicle: -1,
        unassignedVehicle: -1,
        hasCommunities: false,
        updatePage: false
    };
    hardware = null;
    hardwareVehicle = null;

    componentDidMount() {
        //Load data from APIs
        axios.get('/api/Hardware').then(response => {
            let _hardware = response.data;
            _hardware.filter(h => !h.vehicleName).forEach(h => { h.vehicleName = 'Unassigned (' + h.hardwareId + ')' });
             _hardware.sort((a, b) => sortNaturalAlphaNumeric(a, b, 'vehicleName'));
            let _vehicles = _hardware.filter(h => h.hardwareId > 0).map(h => ({ id: h.hardwareId, name: h.vehicleName }))
            this.setState({ vehicles: _vehicles, hardware: _hardware });
        }).catch(function (e) {
                if (e && e.response && e.response.status === 401)
                    window.location = window.location.origin;
            });

        axios.get('/api/HardwareVehicle').then(response => {
            let hardwareVehicles = response.data;
            hardwareVehicles.sort((a, b) => sortNaturalAlphaNumeric(a, b, 'vehicleName'));
            let _unassignedVehicleList = hardwareVehicles.map(h => ({ id: h.vehicleID, name: h.vehicleName }));
            this.setState({ unassignedVehicleList: _unassignedVehicleList});
        }).catch(function (e) {
                if (e && e.response && e.response.status === 401)
                    window.location = window.location.origin;
        });
             

        axios.get('/api/HardwarePara?para').then(response => {
            let hardwarePara = response.data;
            let assemblyStatuses = hardwarePara.hardwareAssemblyStatuses;

            let getChildren = (assemblyStatusId, allAssemblyStatuses) => {
                return allAssemblyStatuses.filter(a => a.parentAssemblyStatusID == assemblyStatusId).map(a => ({
                    id: a.hardwareAssemblyStatusID, name: a.description.trim(), children: getChildren(a.hardwareAssemblyStatusID, allAssemblyStatuses)
                }));
            }

            this.setState({ assemblyStatuses: getChildren(0, assemblyStatuses) });
        }).catch(function (e) {
                if (e && e.response && e.response.status === 401)
                    window.location = window.location.origin;
            });

        axios.get('/api/Menu').then(result => {
            const sitemap = result.data.menuTree.subMenu;
            const communityItem = sitemap.find(n => n.title == "COMMUNITY");
            if (communityItem) {
                this.setState({ hasCommunities: true });
            }
        }).catch(function (e) {
                if (e && e.response && e.response.status === 401)
                    window.location = window.location.origin;
            });
    }

    vehicleSelected(e) {
        let selectedHardware = this.state.hardware.find(h => h.hardwareId == e.target.value);
        this.setState({ selectedVehicle: e.target.value, selectedHardware: selectedHardware, notes: '', selectedAssemblyStatus: selectedHardware.hardwareAssemblyStatusID, unassignedVehicle: -1 });
    }

    assemblyStatusSelected(e) {
        this.setState({ selectedAssemblyStatus: e.target.value });
    }

    saveClicked() {
        if (this.state.isSaving) return;
        this.state.selectedHardware.hardwareAssemblyNotes = this.state.notes;
        this.state.selectedHardware.hardwareAssemblyStatusID = this.state.selectedAssemblyStatus;
        if (this.state.unassignedVehicle > 0) {
            this.state.selectedHardware.vehicleID = this.state.unassignedVehicle;
            //Update label of hardware in the hardware dropdown to reflect its new vehicle
            const unassignedVehicleIndex = this.state.unassignedVehicleList.findIndex(v => v.id == this.state.unassignedVehicle);
            const vehicleName = this.state.unassignedVehicleList[unassignedVehicleIndex].name;
            this.state.vehicles.find(v => v.id == this.state.selectedHardware.hardwareId).name = vehicleName + ' (' + this.state.selectedHardware.hardwareId + ')';
            //Remove vehicle hardware was assigned to from vehicles dropdown
            this.state.unassignedVehicleList.splice(unassignedVehicleIndex, 1);
            this.setState({ vehicles: this.state.vehicles.slice(0).sort((a, b) => sortNaturalAlphaNumeric(a, b, 'name')), unassignedVehicleList: this.state.unassignedVehicleList.slice(0), unassignedVehicle: -1 });
        }
        this.setState({ isSaving: true });
        axios.post('/api/HWRegister', [this.state.selectedHardware]).then((r) => {
            this.setState({ isSaving: false, notes: '' });
            alert('Hardware updated successfully');
        }).catch(function (e) {
                if (e && e.response && e.response.status === 401)
                    window.location = window.location.origin;
            });
    }

    GoToDispatchPortal(e) {
        e.preventDefault();
        window.open("../../components/MainPage/MainPage");
    }


    unassignedVehicleChnaged(e) {
        this.setState({ unassignedVehicle: e.target.value });
    }

    notesUpdated(e) {
        this.setState({ notes: e.target.value });
    }

    generateAssemblyOption(item) {
        if (!item.children || !item.children.length) return <option key={item.id} value={item.id}>{item.name}</option>
        else {
            let subOptions = item.children.map(i => this.generateAssemblyOption(i));
            return (
                <optgroup key={item.id} label={item.name}>
                    {subOptions}
                </optgroup>
            );
        }
    }
    baseUrl = "/"
    history = createBrowserHistory({ basename: this.baseUrl });

    updatePage() {
        setTimeout(() => window.location.reload());
    }




    render() {

        if (window.location.pathname == "/Maintain/Hardware/Registration") {
            return <MainPage />
        }
        else if (window.location.pathname == "/Mobile/SwitchCommunity") {
            return <SwitchCommunity />
        }
        else if (window.location.pathname == '/Mobile/HardwareAssignment')
            return <HardwareAssignment />

        let assemblyOptions = this.state.assemblyStatuses.map(d => this.generateAssemblyOption(d));

        let vehicleOptions = this.state.vehicles.map((v,i) => <option key={i} value={v.id}>{v.name}</option>);

        let communityName = window.location.hostname.split('.')[0];

        let hardwareVehicleOptions = this.state.unassignedVehicleList.map((v, i) => <option key={i} value={v.id}>{v.name}</option>);

        return (
            <div style={{ height: 'auto' }} id="mobileHardwareRegistration" className="hardware-registration-page">
                <div id="router">
                    <Router history={this.history}>
                        <Link onClick={this.updatePage} to="/Maintain/Hardware/Registration">Dispatch Portal</Link>
                        <div className="spacer" /><label> |</label> <div className="spacer" />
                        <Link onClick={this.updatePage} to="/Mobile/HardwareAssignment">Hardware Assign</Link>
                        <div className="spacer" /><label style={{ display: this.state.hasCommunities ? 'inline' : 'none' }}> | </label><div className="spacer" />
                        <Link onClick={this.updatePage} to="/Mobile/SwitchCommunity" style={{ display: this.state.hasCommunities ? 'inline' : 'none' }} >Chnage Community</Link>
                    </Router>
                </div>

                <h1>Hardware Registration ({communityName})</h1>
                <fieldset>
                    <legend>Hardware</legend>
                    <select value={this.state.selectedVehicle} onChange={(event) => this.vehicleSelected(event)}>
                        <option value="-1">Select Hardware</option>
                        {vehicleOptions}
                    </select>
                </fieldset>
                <div className="hardware-area" style={{ display: this.state.selectedHardware.hardwareId ? 'block' : 'none' }}>
                    <div style={{ display: this.state.selectedHardware.vehicleID === null ? 'block' : 'none' }}>
                        <fieldset>
                            <legend>Vehicle</legend>
                            <select value={this.state.unassignedVehicle} onChange={(event) => this.unassignedVehicleChnaged(event)}>
                                <option value="-1">Select Vehicle</option>
                                {hardwareVehicleOptions}
                            </select>
                        </fieldset>
                    </div>

                    <fieldset>
                        <legend>Assembly Status</legend>
                        <select value={this.state.selectedAssemblyStatus} onChange={(event) => this.assemblyStatusSelected(event)}>
                            {assemblyOptions}
                        </select>
                    </fieldset>
                    <fieldset>
                        <legend>Notes</legend>
                        <textarea value={this.state.notes} onChange={(event) => this.notesUpdated(event)}></textarea>
                    </fieldset>
                    <div className="save-area">
                        <a className={'go-button' + (this.state.isSaving ? ' clicked' : '')} onClick={(event) => this.saveClicked(event)}> Save</a>
                    </div>
                </div>
                <br />                
            </div>
        );
    }


}