import axios from 'axios';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useState } from 'react';
import { kendoAlert } from '../../Utils/DialogUtil';
import IncidentAlertPresentation from './IncidentAlert/IncidentAlertPresentation';
import NormalAlertPresentation from './NormalAlert/NormalAlertPresentation';
import Util from '../../Utils/Util';
import { dateToTimeOnlyISO } from '../../Utils/DateUtil';
import SegmentAlertPresentation from './SegmentAlert/SegmentAlertPresentation';
import LoadingMask from '../LoadingMask/LoadingMask';

const minDate = new Date(1970, 1, 1, 0, 0, 0);
const minTime = new Date(1970, 1, 1, 0, 0, 0);
const maxTime = new Date(1970, 1, 1, 23, 59, 0);

const genericPad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default (props) => {
    let mask = [];
    let segments = [];
    const subscriptionTypeId = props.subscriptionTypeId;

    if (props.alertConfigData.editingAlert && props.alertConfigData.editingAlert.alertDays)
        mask = genericPad(props.alertConfigData.editingAlert.alertDays.toString(2), 7);

    if (props.alertConfigData.segments && subscriptionTypeId === 12)
        segments = props.alertConfigData.segments.filter(s => !s.isPublic);
    else if (props.alertConfigData.segments)
        segments = props.alertConfigData.segments.filter(s => s.isPublic);

    const initialState = {
        subscriptionName: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.subscriptionName : '',
        selectedAlertType: props.alertConfigData.editingAlert && props.alertConfigData.alertLogTypes ? props.alertConfigData.alertLogTypes.find(a => a.alertLogTypeID == props.alertConfigData.editingAlert.alertLogType) : null,
        emailNotification: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.notificationMethods.includes('1') : true,
        portalNotification: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.notificationMethods.includes('3') : true,
        smsNotification: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.notificationMethods.includes('2') : false,
        vehicleOption: null,
        selectedRoutes: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.selectedRoutes : [],
        zoneTriggerType: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.zoneTriggerType : 3,
        driverLoggedIn: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.driverLoggedIn : 3,
        vehicleInService: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.vehicleInService : 3,
        clearThreshold: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.clearThreshold : 0,
        startThreshold: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.startThreshold : 0,
        offlineThreshold: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.offlineThreshold : 5,
        zoneType: props.alertConfigData.editingAlert && props.alertConfigData.editingAlert.zoneType ? props.alertConfigData.zoneTypes.find(z => z.zoneTypeID === props.alertConfigData.editingAlert.zoneType) : null,
        anyAllVehicles: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.anyAllVehicles : 'All',
        alertStart: props.alertConfigData.editingAlert && props.alertConfigData.editingAlert.alertStart ? new Date(1970, 1, 1, props.alertConfigData.editingAlert.alertStart.split(':')[0], props.alertConfigData.editingAlert.alertStart.split(':')[1], props.alertConfigData.editingAlert.alertStart.split(':')[2]) : minTime,
        alertEnd: props.alertConfigData.editingAlert && props.alertConfigData.editingAlert.alertEnd ? new Date(1970, 1, 1, props.alertConfigData.editingAlert.alertEnd.split(':')[0], props.alertConfigData.editingAlert.alertEnd.split(':')[1], props.alertConfigData.editingAlert.alertEnd.split(':')[2]) : maxTime,
        isActive: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.isActive === 'Active' : true,
        selectedHardwareType: props.alertConfigData.editingAlert && props.alertConfigData.editingAlert.hardwareTypeID ? props.alertConfigData.hardwareTypes.find(h => h.hardwareTypeID === props.alertConfigData.editingAlert.hardwareTypeID) : null,
        segmentIds: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.segmentIds : [],
        segments,
        rss: props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.rss : false,
        rssUrl: props.alertConfigData.editingAlert && props.alertConfigData.editingAlert.rssUrl ? props.alertConfigData.editingAlert.rssUrl : '',
        editMon: mask && mask.length > 0 ? mask[6] === '1' : true,
        editTues: mask && mask.length > 0 ? mask[5] === '1' : true,
        editWeds: mask && mask.length > 0 ? mask[4] === '1' : true,
        editThurs: mask && mask.length > 0 ? mask[3] === '1' : true,
        editFri: mask && mask.length > 0 ? mask[2] === '1' : true,
        editSat: mask && mask.length > 0 ? mask[1] === '1' : true,
        editSun: mask && mask.length > 0 ? mask[0] === '1' : true,
        errorMessage: null
    };

    const [state, setState] = useState(initialState);
    const [isSaving, setIsSavingFlag] = useState(false);

    const onAlertTypeChange = e => {
        setState({ ...state, selectedAlertType: e.value });
    }

    const notificationTypeChange = e => {
        setState({ ...state, [e.target.name]: e.target.checked });
    }
    const activeStatusChange = e => {
        setState({ ...state, isActive: !state.isActive });
    }
    const rssFeedOptionChange = e => {
        setState({ ...state, rss: !state.rss });
    }
    const vehicleOptionChange = e => {
        setState({ ...state, vehicleOption: e.target.value });
    }

    const inputChange = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const onAlertDayChange = e => {
        setState({ ...state, [e.target.name]: e.target.checked });
    }

    const onSave = e => {
        if (validateData() !== true) return;
        let notifArray = [];
        if (state.emailNotification) notifArray.push(1);
        if (state.smsNotification) notifArray.push(2);
        if (state.portalNotification) notifArray.push(3);
        let notificationMethods = notifArray.join();

        let data = Object.assign({}, state);
        data.subscriptionID = props.alertConfigData.editingAlert ? props.alertConfigData.editingAlert.subscriptionID : - 1;
        data.subscriptionType = subscriptionTypeId;
        data.alertLogType = state.selectedAlertType ? state.selectedAlertType.alertLogTypeID : 0;
        data.zoneType = state.zoneType ? state.zoneType.zoneTypeID : 0;
        data.alertStart = dateToTimeOnlyISO(state.alertStart);
        data.alertEnd = dateToTimeOnlyISO(state.alertEnd);
        data.isActive = state.isActive ? 'Active' : 'InActive';
        data.notificationMethods = notificationMethods;
        data.alertDays = (state.editMon ? Math.pow(2, 0) : 0)
            + (state.editTues ? Math.pow(2, 1) : 0)
            + (state.editWeds ? Math.pow(2, 2) : 0)
            + (state.editThurs ? Math.pow(2, 3) : 0)
            + (state.editFri ? Math.pow(2, 4) : 0)
            + (state.editSat ? Math.pow(2, 5) : 0)
            + (state.editSun ? Math.pow(2, 6) : 0);
        if (state.selectedHardwareType)
            data.hardwareTypeID = state.selectedHardwareType.hardwareTypeID;

        let promise = null;
        if (data.subscriptionID > 0)
            promise = axios.post('/api/MyAlerts', data);
        else
            promise = axios.put('/api/MyAlerts', data);
        setIsSavingFlag(true);
        promise.then(res => {
            props.onClose(null, true);
        })
            .catch(err => {
                let subscriptionType = props.getSubscriptionType();
                let _errorMessage = `Failed to ${props.alertConfigData.editingAlert ? 'update' : 'save'} ${subscriptionType.subscriptionTypeName}!`;
                setState({ ...state, errorMessage: _errorMessage });
                setTimeout(() => {
                    setState({ ...state, errorMessage: null });
                }, 3000);
                setIsSavingFlag(false);
            });
    }

    const validateData = () => {
        if (Util.isNullOrWhitespace(state.subscriptionName)) return kendoAlert('Alert name cannot be empty', 'Warning', null, null, true);
        if (subscriptionTypeId === 1 && (!state.selectedAlertType || state.selectedAlertType.alertLogTypeID) < 1) return kendoAlert('Please choose alert type', 'Warning', null, null, true);
        if (state.alertEnd < state.alertStart) return kendoAlert('Alert start time cannot be less than alert end time', 'Warning', null, null, true);
        if (!state.emailNotification && !state.portalNotification && !state.smsNotification)
            return kendoAlert('At least one notification method must be checked', 'Warning', null, null, true);
        if (subscriptionTypeId === 5 && (!state.zoneType || state.zoneType.zoneTypeID < 1))
            return kendoAlert('Select the zone type', 'Warning', null, null, true);
        if (props.alertConfigData.showSegmentAlert && state.segmentIds.length < 1)
            return kendoAlert('No segments selected', 'Warning', null, null, true);
        if (state.selectedAlertType && (state.selectedAlertType.alertLogTypeID === 38 || state.selectedAlertType.alertLogTypeID == 39)) {
            if (state.startThreshold < 1) return kendoAlert('Start threshold is required', 'Warning', null, null, true);
            if (state.clearThreshold < 1) return kendoAlert('Clear threshold is required', 'Warning', null, null, true);
            if (state.startThreshold <= state.clearThreshold) return kendoAlert('Clear threshold must be less than start threshold', 'Warning', null, null, true);
            if (!state.selectedRoutes || state.selectedRoutes.length < 1) return kendoAlert('At least one route must be selected', 'Warning', null, null, true);
            if (!state.anyAllVehicles) return kendoAlert('Must specify if alert applies to any or all vehicles', 'Warning', null, null, true);
        }

        return true;
    }

    const actions = {
        onAlertTypeChange,
        notificationTypeChange,
        activeStatusChange,
        rssFeedOptionChange,
        vehicleOptionChange,
        inputChange,
        onAlertDayChange
    };

    const getDialogTitle = () => {
        let subscriptionType = props.getSubscriptionType();
        if (!props.alertConfigData.editingAlert)
            return `Add New ${subscriptionType ? subscriptionType.subscriptionTypeName : ''}`;

        return `Edit ${subscriptionType ? subscriptionType.subscriptionTypeName : ''}`;
    }

    const getDialogWidth = () => {
        let subscriptionType = props.getSubscriptionType();
        return subscriptionType ? ((subscriptionType.subscriptionTypeID === 12 || subscriptionType.subscriptionTypeID === 13 || subscriptionType.subscriptionTypeID == 14) ? 1000 : 575) : 500;
    }

    const dialogTitle = getDialogTitle();
    const dialogWidth = getDialogWidth();

    return (
        <div className="my-alert-dialog">
            <Dialog
                title={dialogTitle}
                width={dialogWidth}
                onClose={props.onClose} >
                {
                    props.alertConfigData.showAlert &&
                    <NormalAlertPresentation
                        {...props}
                        data={state}
                        actions={actions} />
                }
                {
                    props.alertConfigData.showIncidentAlert &&
                    <IncidentAlertPresentation
                        {...props}
                        data={state}
                        actions={actions} />
                }
                {
                    props.alertConfigData.showSegmentAlert &&
                    <SegmentAlertPresentation
                        {...props}
                        data={state}
                        actions={actions} />
                }
                <DialogActionsBar>
                    {
                        state.errorMessage &&
                        <div className="error-message">
                            {state.errorMessage}
                        </div>
                    }
                    <div className="action-button">
                        <button
                            className="k-primary"
                            onClick={onSave}>
                            <span className="k-icon k-i-check"></span>
                            <label> {props.alertConfigData.editingAlert ? 'Update' : 'Save'} </label>
                        </button>

                        <button
                            className="cancel-button"
                            onClick={props.onClose}>
                            <span className="k-icon k-i-cancel"></span>
                            Cancel</button>
                    </div>
                </DialogActionsBar>
                {isSaving && <LoadingMask />}
            </Dialog>
        </div>
    );
}