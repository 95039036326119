import React from 'react';
import smiLogo from '../../images/smi_logo.png';
import './LoginHeader.scss';
import Util from '../../Utils/Util';

export default (props) => {    
    if (Util.isNullOrUndefined(props.customLogoUrl)) return null;
    return (
        <div className="login-header">
            <a className="company-logo" href="https://www.mapstrat.com/" target="_blank" rel="noopener noreferrer">
                <img src={smiLogo} alt="Strategic Mapping Logo" />
            </a>
            <img src={props.customLogoUrl} className="customer-logo" alt="Transit Agency Logo" />
        </div>
    )
}