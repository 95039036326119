import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import useForceUpdate from 'use-force-update';
import { popup } from 'leaflet';

export default ({ onItemSelected }) => {
    const [cannedMessages, setCannedMessages] = useState([]);
    const [popupShown, setPopupShown] = useState(false);
    const buttonRef = useRef(null);
    const forceUpdate = useForceUpdate();

    useEffect(() => { axios.get('/api/MSGCannedMessages').then(response => setCannedMessages(response.data)) }, []);

    const togglePopup = (e) => {
        e.preventDefault();
        setPopupShown(!popupShown);
    }

    return (
        <div className="select-canned-message" id="cannedMessage">
            <a id="messageCollectionToggle" className={popupShown ? "pressed" : ""} onClick={togglePopup} ref={buttonRef}><span className="down-arrow"></span></a>
            <Popup anchor={buttonRef.current} show={popupShown} style={{ zIndex:99999 }}>
                <div id="messageCollectionHolder">
                    <TreeView id="messageCollectionTreeview" data={cannedMessages}  expandIcons={true} onItemClick={(event) => onItemSelected(event) || setPopupShown(false)} onExpandChange={(event) => { event.item.expanded = !event.item.expanded; forceUpdate(); }} />                    
                </div>
            </Popup>
        </div>
    );
}