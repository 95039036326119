import ReduxActions from './ReduxAction';

export default function ReduxReducer(state = initialState, action) {

    var newState = Object.assign({}, state);
    switch (action.type) {
        case ReduxActions.GetVehicles:
            newState.Vehicles = action.vehicles;            
            break;
        case ReduxActions.GetRoutes:
            newState.Routes = action.routes;
            break;
        case ReduxActions.SetEvents:            
            let evnts = newState.Events.slice(0);
            let localTime = new Date().toLocaleTimeString(); 
            evnts.push(localTime+': '+ action.event);
            newState.Events = evnts;            
            break;
        case ReduxActions.ClearEvents:
            newState.Events = [];
            break;
        case ReduxActions.SelectDate:
            newState.SelectedDate = action.date;
            break;
        case ReduxActions.SelectVehicle:
            newState.SelectedVehicle = action.vehicle;
            break;
        case ReduxActions.SetTripInfos:
            newState.TripInfos = action.tripInfos;
            break;
        case ReduxActions.SelectTripInfo:
            newState.SelectedTrip = action.selectedTrip;
            break;
        case ReduxActions.SetSelectedLogFix:
            newState.SelectedLogFix = action.LogFix;
            break;
        case ReduxActions.SetVehicleLogFix:
            newState.VehicleAuditLogs = action.vehicleAuditLogs;
            break;
        case ReduxActions.SetSelectedLogType:
            newState.SelectedLogType = action.LogType;
            break;
        case ReduxActions.SetSelectedLogFile:
            newState.SelectedLogFile = action.LogFile;
            break;
        case ReduxActions.SetTimeRange:
            newState.FromDateTime = action.fromDateTime;
            newState.ToDateTime = action.toDateTime;
            break;      
        case ReduxActions.SetVehicleHistory:
            newState.VehicleHistory = action.vehicleHistory;
            break;       
        case ReduxActions.SetTripStopData:
            newState.TripStopData = action.tripStopData;
            break;
        case ReduxActions.SetSelectedRoute:
            newState.SelectedRoute = action.selectedRoute;
            break;
        case ReduxActions.SetRoutePatterns:
            newState.RoutePatterns = action.routePatterns;
            break;
        case ReduxActions.SetSelectedPattern:
            newState.SelectedPattern = action.selectedPattern;
            break;
        case ReduxActions.SetPatternStops:
            newState.PatternStops = action.patternStops;
            break;
        case ReduxActions.SetEventTrigerDistance:
            newState.IsEventTrigerDistance = action.isEventTrigerDistance;
            break;
        case ReduxActions.SetStopReached:
            newState.IsStopReached = action.isStopReached;
            break;
        case ReduxActions.SetZoomToRouteStart:
            newState.IsZoomToRouteStart = action.isZoomToRouteStart;
            break;
        case ReduxActions.SetShowPattern:
            newState.IsShowPattern = action.isShowPattern;
            break;
        case ReduxActions.SetCircleMarkerPoints:
            newState.CircleMarkerPoints = action.circleMarkerPoints;
            break;
        case ReduxActions.SetFilterItems:
            newState.FilterItems = action.filterItems;
            break;
        case ReduxActions.SetHighlightGreenItems:
            newState.HighlightGreenItems = action.highlightGreenItems;
            break;
        case ReduxActions.SetHighlightCyanItems:
            newState.HighlightCyanItems = action.highlightCyanItems;
            break;
        case ReduxActions.ClearMarkers:
            newState.CircleMarkerPoints = [];
            newState.SelectedLogFix = null;
            break;
        case ReduxActions.SetHighlightCyanItems:
            newState.HighlightCyanItems = action.highlightCyanItems;
            break;
        case ReduxActions.SetIsLoading:
            newState.isLoading = action.isLoading;
            break;
    }

    return newState;
}

let initialState = {
    Routes: [],
    Vehicles: [],
    Events: [],
    SelectedVehicle: null,
    SelectedRoute: null,
    RoutePatterns: [],
    SelectedPattern: null,
    PatternStops: [],
    SelectedDate: null,    
    SelectedTrip: null,
    TripInfos: [],
    TripStopData:[],
    VehicleHistory: [],
    SelectedLogFix: null,
    SelectedLogType: null,
    SelectedLogFile: null,
    VehicleAuditLogs:[],
    FromDateTime: null,
    ToDateTime: null,
    IsStopReached: true,
    IsEventTrigerDistance: false,
    IsZoomToRouteStart: true,
    IsShowPattern: true,
    CircleMarkerPoints: [],
    FilterItems: [],
    HighlightGreenItems: [],
    HighlightCyanItems: [],
    isLoading: false
};
