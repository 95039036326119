import $ from 'jquery';
import React from 'react';
import { formatDate } from '@telerik/kendo-intl';
import { playAudio } from '../../Utils/AudioUtil';
import Util from '../../Utils/Util';
import { shortFormatXM36hour, formatMinutes } from '../../Utils/DateUtil';

export function DateTime(shortDateFormat, shortTimeFormat) {
    let dateFormat = ''
    if (shortTimeFormat) {
        dateFormat = shortDateFormat + ' ' + shortTimeFormat.replace('tt', 'a');
    }
    
    return function (dataItem, field) {        
        const dataValue = dataItem[field];
        if (dataValue) return formatDate(dataValue, dateFormat);
        return '';
    }
}

export function Date(shortDateFormat) {
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (dataValue) return formatDate(dataValue, `${shortDateFormat}`);
        return '';
    }
}

export function Time(shortTimeFormat) {
    return function (dataItem, field) {
        const timeFormat = shortTimeFormat.indexOf("t") !== -1 ? 't' : shortTimeFormat;
        const dataValue = dataItem[field];
        if (dataValue) return formatDate(dataValue, `${timeFormat}`);
        return '';
    }
}

export function roundToPrecision(precision) {
    return function (dataItem, field) {
        const dataValue = dataItem[field];        
        if (dataValue) return Util.roundToPrecision(dataValue, precision);
        return '';
    }
}

export function Color() {
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (dataValue) return <span className="color-display" style={{ backgroundColor: dataValue }}>&nbsp;</span>
        return '';
    }
}
    //Used for fields where the value in the field is an ID but we need to display the associated string (e.g. the field value is the vehicle ID but we want to display the vehicle name)
export function Enum(data, dataValueField, dataTextField, inheritanceCallback) {
    return function (dataItem, field) {
        let dataValue = dataItem[field];
        let isInherited = false;
        if (!dataValue && inheritanceCallback) {
            dataValue = inheritanceCallback(dataItem, field, data);
            isInherited = true;
        }

        let listData = data;
        if (typeof data === 'function') listData = data(dataItem);

        if (!listData) return '';

        let searchedData = listData.filter((d) => d[dataValueField] === dataValue);
        if (searchedData.length > 0) return isInherited ? <span className="inherited">{searchedData[0][dataTextField]}</span> : searchedData[0][dataTextField];
        return '';
    }
}

//Used for fields where the user can select multiple values and they're stored in a comma-separated string
export function CSV(data, dataValueField, dataTextField) {
    return function (dataItem, field) {
        const dataValue = $.isArray(dataItem[field]) ? dataItem[field].join(',') : dataItem[field];
        if (!dataValue || dataValue.length < 1) return '';
        let splitStr = dataValue.split(',').filter((s) => !!s);
        if (data && dataValueField && dataTextField) {
            //Lookup values
            splitStr = splitStr.map((val) => {
                for (let i = 0; i < data.length; i++) {
                    if (val && data[i][dataValueField] === parseInt(val)) return data[i][dataTextField];
                }
                return '';
            });
        }
        return splitStr.join(', ');
    }
}

export function YesNoBinary(inheritanceCallback) {
    return function (dataItem, field, data) {
        const dataValue = dataItem[field];
        if (dataValue && (dataValue === 1 || dataValue === true)) return 'Yes';
        if (inheritanceCallback && (dataValue === null || dataValue < 0)) return <span className="inherited">{inheritanceCallback(dataItem, field, data) ? 'Yes' : 'No'}</span>
        return 'No';
    }
}

export function XM36HourTime(shortTimeFormat, baseDateField) {
    return function (dataItem, field) {
        return shortFormatXM36hour(dataItem[field], shortTimeFormat, dataItem[baseDateField]);
    }
}

export function FormatMinutes() {
    return function (dataItem, field) {
        return formatMinutes(dataItem[field]);
    }
}

export function AudioField(audioCallback, speechSynthesizerRate, smiMapService, inheritanceCallback) {
    return function (dataItem, field, data) {

        const triggerPlayAudio = function (event) {
            event.stopPropagation();
            const playButton = event.target;
            playButton.classList.add('loading');
            const audio = audioCallback(dataItem, field, data);
            const failsafeTimeout = setTimeout(() => playButton.classList.remove('loading'), 45000); //Always remove loading class after 45 seconds even if audio hasn't loaded
            playAudio(audio, speechSynthesizerRate, smiMapService, () => { playButton.classList.remove('loading'); clearTimeout(failsafeTimeout); });
        }

        let dataValue = dataItem[field];
        if (!dataValue && inheritanceCallback) {
            dataValue = inheritanceCallback(dataItem, field, data);
            if (dataValue) dataValue = <span className="inherited">{dataValue}</span>
        }

        if (dataValue) {
            return (
                <div className="audio-cell">
                    <div className="audio-text-area">{dataValue}</div>
                    <div className="audio-button-area">
                        <button className="play-audio" onClick={(event) => triggerPlayAudio(event)} />
                    </div>
                </div>
            )
        }
    }
}