import React, { useState, useMemo } from 'react';
import HomepageMenuPresentation from './HomepageMenuPresentation';
import SortHelper from '../../../Utils/SortHelper';
import axios from 'axios';

function getFlattenedMenu(flattenedMenuArr, parentPath, menuItem) {
    if (!menuItem || !menuItem.title) return;

    const parentName = menuItem.title.toUpperCase();
    parentPath = parentPath + '/' + menuItem.title.replace(/\s+/g, '');

    //Support menu items should not be added to the list of selectable homepages
    if (parentPath === '/System/Support') return;

    if (menuItem.subMenu && menuItem.subMenu.length) {
        menuItem.subMenu.forEach(s => {
            //The select editing schedule menu item should not be selectable as a homepage
            if (s.title === 'Select Editing Schedule') return;

            // Only menu items without submenus should be selectable as a homepage
            if (!s.subMenu || s.subMenu.length === 0) flattenedMenuArr.push({ label: parentName + '.' + s.title, path: parentPath + '/' + s.title.replace(/\s+/g, '') });
            else getFlattenedMenu(flattenedMenuArr, parentPath, s);
        })
    }
}

export default (props) => {

    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedHomepage, setSelectedHomepage] = useState(user.homepage);

    const flattenedMenu = useMemo(() => {
        const flattenedMenuArr = [];
        if (props.menuTree) props.menuTree.subMenu.forEach(s => getFlattenedMenu(flattenedMenuArr, '', s));
        SortHelper.sortArrayNaturalAlphaNumeric(flattenedMenuArr,'label');
        return flattenedMenuArr;
    }, [props.menuTree]);

    const updateHomepage = (newHomepage) => {
        axios.post('/api/Homepage', { homepage: newHomepage }).then(response => {
            if (response && response.data) {
                setSelectedHomepage(newHomepage);
                user.homepage = newHomepage;
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(user));
            }
        });
        
    }

    return (
        <HomepageMenuPresentation selectedHomepage={selectedHomepage} flattenedMenu={flattenedMenu} onItemClick={updateHomepage} />
    );
}