import React from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { MapComponentUnconnected } from '../../../pages/Shared/Map/MapComponent';
import LocationIcon from '../../../images/Location_Icon_18x27.png';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import MapHelper from '../../../Utils/MapHelper';
import './Emergencies.scss';


export default (props) => {
    if (!props.emergency) return null;
    let mapCenter = null;
    const markers = [];
    if (props.emergency.vehicle && props.emergency.vehicle.position) {
        mapCenter = [props.emergency.vehicle.position.lat, props.emergency.vehicle.position.lng];

        let vehicleMarer = (<Marker key={1} position={mapCenter} zIndexOffset={1000}
            icon={MapHelper.getVehicleIcon(props.emergency.vehicle.bearing, "FFFFFF")} />);
        markers.push(vehicleMarer);
    }
    else if (props.emergency.position) mapCenter = [props.emergency.position.lat, props.emergency.position.lng];

    if (props.emergency.position) markers.push(<Marker key="emergencyPositionMarker" position={[props.emergency.position.lat, props.emergency.position.lng]} icon={L.icon({ iconUrl: LocationIcon, iconAnchor: [9, 26] })} />);

    const markerGroup = { name: 'emergencyMarkers', shouldZoom: false, markers: markers };

    return (
        <div id="emergencyWindow">
            <Window title={"Emergency: " + props.emergency.alertText} width={800} height={465} style={{ animationName: 'hide-close' }}>
                <div className="emergency">
                    <div className="emergency-map">
                        <MapComponentUnconnected centerPosition={mapCenter} drawableRoutes={props.emergency.drawableRoute ? [props.emergency.drawableRoute] : null} markerGroups={[markerGroup]} />
                    </div>
                    <div className="emergency-buttons">
                        <button type="button" className={"green" + (props.isBusy ? ' clicked' : '')} onClick={props.onAcknowledge}>Acknowledge</button>
                        <button type="button" className={"green" + (props.isBusy ? ' clicked' : '')} onClick={() => { props.onRespond(props.emergency.vehicle); props.onAcknowledge(); }}>Acknowledge & Respond</button>
                    </div>
                    <div className="emergency-info">
                        Incident ID: {props.emergency.incidentId}<br />
                    Date: {props.emergency.date}<br />
                    Time: {props.emergency.time}<br />
                    Vehicle: {props.emergency.vehicle && props.emergency.vehicle.name}<br />
                    Driver: {props.emergency.driver}<br />
                    Block: {props.emergency.block}<br />
                    Route: {props.emergency.route}<br />
                    Last Stop Made: {props.emergency.lastStopMade}<br />
                        <span style={{ textDecoration: 'underline' }}>Approx.Activation Address</span><br />
                        {props.emergency.address}
                        <div className="location-message">
                            <img src={LocationIcon} alt="Location Icon" />
                            <p>Location where emergency reported</p>
                        </div>
                    </div>
                </div>
            </Window>
        </div>
    );
}