import React, { Component } from 'react';
import { Route } from 'react-router-dom';


const HardwareRegistrationPage = React.lazy(() => import('../../pages/Mobile/HardwareRegistrationPage'));
const HardwareAssignmentPage = React.lazy(() => import('../../pages/Mobile/HardwareRegistrationPage'));
const SwitchCommunity = React.lazy(() => import('../../pages/Mobile/SwitchCommunity'));

export default class MobileRoutes extends Component {
    render() {
        return (
            <React.Fragment>
                <Route path="/Mobile/HardwareRegistration" component={HardwareRegistrationPage} />
                <Route path="/Mobile/HardwareAssignment" component={HardwareAssignmentPage} />
                <Route path="/Mobile/SwitchCommunity" component={SwitchCommunity} />
            </React.Fragment>
        );
    }
}