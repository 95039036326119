import React, { useState, useEffect } from 'react';
import Login from './components/Login/Login';
import MainPage from './components/MainPage/MainPage';
import ChangePassword from '../src/pages/Settings/ChangePassword/ChangePassword';
import { SystemSettingContext } from './SystemSettingContext';
import * as DateUtils from "../src/Utils/DateUtil";
import axios from 'axios';
import MobileMainPage from './components/MobileRoutes/MobileMainPage';
import HardwareRegistrationPage from './pages/Mobile/HardwareRegistrationPage';
import UploadIncidentAttachment from './pages/View/ReduxIncidentListing/UploadIncidentAttachment';
import LoadingPanel from './components/SMIGrid/LoadingPanel';
import Media from 'react-media';
import Util from './Utils/Util';
import queryString from 'query-string';

export default () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isForceChangePassword, setForceChangePassword] = useState(false);
    const [SystemSettings, setSystemSettings] = useState(null);
    const [isMobileLogin, setIsMobileLogin] = useState(false);
    const [isIncidentAttachment, setIsIncidentAttachment] = useState(false);
    const [isAuthTokenVarifing, SetAuthTokenVarifiedFlag] = useState(true);
    const [isSystemSettigsLoaded, SetSystemSettingLoadFlag] = useState(false);

    useEffect(() => {
        let location = window.location;
        if (location && location.pathname && location.pathname.indexOf('View/Incidents/UploadIncidentAttachment') !== -1) {
            setIsIncidentAttachment(true);
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth > 600 && window.screen.width > 600) {
            setIsMobileLogin(false);
        }
        else {
            setIsMobileLogin(true);
        }
    }, [isMobileLogin]);


    useEffect(() => {
        if (sessionStorage.getItem('changePassword') && sessionStorage.getItem('changePassword') === 'true' && !isForceChangePassword) {
            setForceChangePassword(true);
        }
    }, [isForceChangePassword]);

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        if (parsed && parsed.ticket && parsed.username) {
            window.history.replaceState({}, document.title, "/");
            localStorage.clear();
            SetAuthTokenVarifiedFlag(true);
            axios.post('/api/SMILogin/LoginUserWithTicket', { loginTicket: parsed.ticket, userName: parsed.username }).then(response => {
                if (response.data.result == 1) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    loadSystemSettings();
                }
                else {
                    logOut();
                }

            }).catch((err) => {
                SetAuthTokenVarifiedFlag(false);
            });
        }
        else if (localStorage.getItem('user')) {
            SetAuthTokenVarifiedFlag(true);
            var user = JSON.parse(localStorage.getItem('user'));
            axios.post('/api/SmiLogin/LoginwithAuthToken', { UserName: user.username, Token: user.authToken })
                .then(response => {
                    if (response.data.result === 1) { // result 1 means login success
                        localStorage.setItem('user', JSON.stringify(response.data));
                        loadSystemSettings();
                    }
                    else {
                        logOut();
                    }
                }).catch((err) => {
                    console.log(err);
                    logOut();
                    SetAuthTokenVarifiedFlag(false);
                });
        }
        else {
            SetAuthTokenVarifiedFlag(false);
        }
    }, []);

    const loadSystemSettings = () => {
        axios.get('/api/SystemSettings').then((response) => {
            window.SystemSettings = response.data;
            window.SystemSettings.currentServerTime = new Date(window.SystemSettings.currentServerTime);
            window.SystemSettings.serverPageLoadTime = window.SystemSettings.currentServerTime;
            window.SystemSettings.clientPageLoadTime = new Date();
            window.SystemSettings.localServerTimeDiff = new Date().getTime() - window.SystemSettings.currentServerTime.getTime();
            window.SystemSettings.getCurrentServerTime = function () {
                var offset = window.SystemSettings.serverPageLoadTime.getTime() - window.SystemSettings.clientPageLoadTime.getTime();
                var currentTime = new Date(new Date().getTime() + offset);
                return currentTime;
            };

            setIsLoggedIn(true);
            localStorage.setItem('isLoggedIn', true);
            SetAuthTokenVarifiedFlag(false);
            SetSystemSettingLoadFlag(true);
            setSystemSettings(response.data);
        }).catch((err) => { });
    }

    const logOut = () => {
        localStorage.clear();
        window.location = "/";
    }

    if (isIncidentAttachment)
        return <UploadIncidentAttachment />;

    const onLoginSuccess = e => {
        localStorage.setItem('isLoggedIn', true);
        setIsLoggedIn(true);
        loadSystemSettings();
    }


    return (
        <div>
            <SystemSettingContext.Provider value={SystemSettings}>
                {!isLoggedIn && !isForceChangePassword && !isAuthTokenVarifing && <Login onLoginSuccess={onLoginSuccess} onChangePassword={() => { setForceChangePassword(true); sessionStorage.setItem('changePassword', 'true'); }} />}
                <div id="loadSystemSettings">
                    <Media query="(max-width: 599px)">
                        {matches =>
                            isMobileLogin ? (
                                (isLoggedIn && <MobileMainPage />)
                            ) : (
                                    (isLoggedIn && <MainPage />)
                                )
                        }
                    </Media>
                </div>
                {isAuthTokenVarifing && (<LoadingPanel identifier={"loadSystemSettings"} />)}
                {!isLoggedIn && isForceChangePassword && <ChangePassword />}
            </SystemSettingContext.Provider>
        </div>);
}