
import React from 'react';
import './AlertInbox.scss';
import AlertsInboxPresentation from './AlertsInboxPresentation';

//export default () => (
//    <iframe src="/HtmlClient/AlertInbox.html" style={{ width: '100%', height: '100%' }}></iframe>
//);

export default () => (
    <AlertsInboxPresentation / >
);