export function playAudio(audioSnippets, speechSynthesizerRate, smiMapService, onLoadedCallback) {
    if (!smiMapService) smiMapService = 'https://api.mapstrat.com';
    var rate = speechSynthesizerRate;
    if (!rate) rate = 0;
    var queryString = 'rate=' + rate + '&';
    for (var i = 0; i < audioSnippets.length; i++) {
        queryString += 'ttsRequests[' + i + '][text]=' + encodeURIComponent(audioSnippets[i].text) + '&ttsRequests[' + i + '][voice]=' + encodeURIComponent(audioSnippets[i].voice);
        if (i < (audioSnippets.length - 1)) queryString += '&';
    }
    var ttsUrl = smiMapService + '/api/TextToSpeech?' + queryString;
    let soundEmbedContainer = document.getElementById('soundEmbedContainer');
    if (!soundEmbedContainer) {
        soundEmbedContainer = document.createElement('div');
        soundEmbedContainer.id = 'soundEmbedContainer';
        soundEmbedContainer.style.visibility = 'hidden';
        soundEmbedContainer.style.position = 'absolute';
        soundEmbedContainer.style.width = 0;
        soundEmbedContainer.style.height = 0;
        document.body.append(soundEmbedContainer);
    }

    // Clear any previously-created audio elements from the container
    while (soundEmbedContainer.firstChild) soundEmbedContainer.removeChild(soundEmbedContainer.firstChild);

    //Use HTML5 audio to play audio from API
    const soundEmbed = document.createElement("audio");
    soundEmbedContainer.append(soundEmbed);
    soundEmbed.onplaying = function () {
        if (typeof onLoadedCallback === 'function') onLoadedCallback();
    }
    soundEmbed.pause();
    soundEmbed.src = ttsUrl;
    setTimeout(function () {
        //document.getElementById('audioPlayer').load();
        soundEmbed.play();
    }, 10);
    
}