import React from 'react';
import ReactPlayer from 'react-player';

export default ({ url }) => {
    const protocol = window.location.protocol.indexOf("https") > -1 ? "https:" : "http:";
    const videoUrl = url.indexOf("http") > -1 ? url : protocol + url;    
    return (
        <ReactPlayer
            url={videoUrl}
            className='react-player'
            controls={true}
            playing={false}
            width='100%'
            height='100%'
        />        
    );
};