const simulatorState = {
    LocationPoints: [],
    isPaused: false,
    isReset: false,
    SimulatorMode: "",
    isStart: false,
    Speed: 0,
    OBUList: [],
    SelectedOBU: null
}

const actionTypes = {
    SETLOCATIONPOINTS: 'SIMULATOR/SETLOCATIONPOINTS',
    SETPAUSE: 'SIMULATOR/SETPAUSE',
    SETRESET:'SIMULATOR/SETRESET',
    SETSTART: 'SIMULATOR/SETSTART',
    SETSIMULATORMODE: 'SIMULATOR/SETSIMULATORMODE',
    SETSPEED: 'SIMULATOR/SETSPEED',
    SETOBULIST: 'SIMULATOR/SETOBULIST',
    SETSELECTEDOBU: 'SIMULATOR/SETSELECTEDOBU',
    CLEAR:'SIMULATOR/CLEAR'
}

export default (state = simulatorState, action) => {
    switch (action.type) {
        case actionTypes.SETLOCATIONPOINTS:
            return { ...state, LocationPoints: action.locationPoints };
        case actionTypes.SETPAUSE:
            return { ...state, isPaused: action.isPaused };
        case actionTypes.SETRESET:
            return { ...state, isReset: action.isReset };
        case actionTypes.SETSTART:
            return { ...state, isStart: action.isStart };
        case actionTypes.SETSIMULATORMODE:
            return { ...state, SimulatorMode: action.simulatorMode };
        case actionTypes.SETSPEED:
            return { ...state, Speed: action.speed };
        case actionTypes.SETOBULIST:
            return { ...state, OBUList: action.obuList };
        case actionTypes.SETSELECTEDOBU:
            return { ...state, SelectedOBU: action.selectedObu };
        case actionTypes.CLEAR:
            console.log('clear');
            return simulatorState;
        default:
            return state
    }
}

export const actions = {
    setLocationPoints: (_locationPoints) => ({ type: actionTypes.SETLOCATIONPOINTS, locationPoints: _locationPoints }),
    setPause: isPaused => ({ type: actionTypes.SETPAUSE, isPaused }),
    setReset: isReset => ({ type: actionTypes.SETRESET, isReset }),
    setStart: isStart => ({ type: actionTypes.SETSTART, isStart }),
    setSimulatorMode: simulatorMode => ({ type: actionTypes.SETSIMULATORMODE, simulatorMode }),
    setSpeed: speed => ({ type: actionTypes.SETSPEED, speed }),
    setObuList: obuList => ({ type: actionTypes.SETOBULIST, obuList }),
    setSelectedObu: selectedObu => ({ type: actionTypes.SETSELECTEDOBU, selectedObu }),
    clear: () => ({ type: actionTypes.CLEAR })
}

