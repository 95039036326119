import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';

export default (props) => {    
    return (
        <>
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Hardware Type:</label>
                </div>
                <div className="k-edit-field">
                    <DropDownList
                        name="selectedHardwareType"
                        data={props.alertConfigData.hardwareTypes}
                        className="normal-alert-dropdown"
                        dataItemKey="hardwareTypeID"
                        value={props.data.selectedHardwareType}
                        onChange={props.actions.inputChange}
                        textField="name" />
                </div>
            </div>
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Offline Minutes:</label>
                </div>
                <div className="k-edit-field">
                    <NumericTextBox
                        type="number"
                        width={150}
                        min={0}
                        value={props.data.offlineThreshold}
                        onChange={props.actions.inputChange}
                        name="OfflineThreshold"
                        style={{ width: '150px' }} />
                </div>
            </div>
        </>);
}