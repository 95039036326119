import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware  } from 'redux';
import authReducer from './Auth';
import commentReducer from './Comment';
import mapReducer from './Map';
import simulatorReducer from './SimulatorReducer';
import diagnosticReducer from '../pages/System/Diagnostics/VehicleDiagnostics/ReduxReducer';

export default createStore(
    combineReducers({
        authReducer,        
        commentReducer,
        simulatorReducer,
        mapReducer,
        diagnosticReducer
    }),
    applyMiddleware(thunk)
);