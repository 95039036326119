import $ from 'jquery';
import React, { useMemo } from 'react';
import { Map } from 'react-leaflet';
import VehicleMarker from './VehicleMarker';
import DrawableRoute from './DrawableRoute';
import { setTimeout } from 'timers';

export default React.forwardRef(({ props, centerPosition, zoom, showArial, mapTiles, toggleAerial, showArialMapView }, ref) => {

    let drawableRouteElements = null;
    if (props.drawableRoutes) drawableRouteElements = useMemo(() => props.drawableRoutes.map((r, i) => <DrawableRoute key={i} {...r} />), [props.drawableRoutes]);

    let markersFromGroups = null;
    if (props.markerGroups) markersFromGroups = useMemo(() => props.markerGroups.reduce((arr, group) => { group.markers.forEach(m => arr.push(m)); return arr; }, []), [props.markerGroups]);

    if (ref && props.clusterMarkers)
        ref.current.leafletElement.addLayer(props.clusterMarkers);

    setTimeout(() => {
        $('a[href*="leafletjs.com"]').attr("target", "_blank");
    }, 1000);

    return (
        <div className="map-holder">
            {showArialMapView &&
            (<div className="aerial-toggle" onClick={() => toggleAerial()}>
                <span className="aerial-label">Aerial </span><span className={'slider' + (showArial ? ' on' : '')} />
                </div>)
            }

            <Map {...props} ref={ref}  center={centerPosition} zoom={zoom} maxZoom={20} >
                {mapTiles}
                {markersFromGroups}
                {props.historyRouteLines}
                {props.routeLines}
                {props.vehicleMarker}
                {props.stopMarkers}
                {props.circleMarkers}
                {props.polygons}
                {drawableRouteElements}
                <VehicleMarker showVehicleInfoBox={props.showVehicleInfoBox || false} />
            </Map>
        </div>
    )
})